var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import DateRangePicker from '@/components/DateRangePicker.vue';
import { date } from 'quasar';
let AuditsFilters = class AuditsFilters extends Vue {
    constructor() {
        super(...arguments);
        this.dateRangeLabel = 'Hoje';
        this.showDateRangePicker = false;
        this.originOptions = [
            { label: 'Todos', value: 'all' },
            { label: 'Admin', value: 'admin' },
            { label: 'Superlógica', value: 'superlogica' },
            { label: 'Reativação', value: 'reactivation_link' },
            { label: 'Stripe', value: 'stripe' },
            { label: 'Indicação', value: 'referral' },
            { label: 'Reativação', value: 'reactivation_link' },
            { label: 'Sistema', value: 'system' },
        ];
        this.operationOptions = [
            { label: 'Todos', value: 'all' },
            { label: 'Adição', value: 'add_credit' },
            { label: 'Remoção', value: 'remove_credit' },
        ];
        this.statusOptions = [
            { label: 'Todos', value: 'all' },
            { label: 'Sucesso', value: 'success' },
            { label: 'Erro', value: 'error' },
        ];
    }
    created() {
        this.initializeRangeLabel();
    }
    get isOperationFilterVisible() {
        return this.origin === 'admin' || this.origin === 'all';
    }
    get isStatusFilterVisible() {
        return this.origin === 'superlogica' || this.origin === 'all';
    }
    onChangeOriginOption(option) {
        this.$emit('changeOrigin', option);
    }
    onChangeOperationOption(operation) {
        this.$emit('changeOperation', operation);
    }
    onChangeStatusOption(status) {
        this.$emit('changeStatus', status);
    }
    changeDateRange(values, newDateRangeLabel) {
        this.setDateRangeLabel(values, newDateRangeLabel);
        this.closeDatePickerMenu();
        this.$emit('changePeriod', values);
    }
    cannotSelect(item) {
        switch (this.origin) {
            case 'admin':
                return item === 'Admin' ? true : false;
            case 'Superlógica':
                return item === 'Superlógica' ? true : false;
            default:
                return false;
        }
    }
    setDateRangeLabel(values, newDateRangeLabel) {
        if (!newDateRangeLabel) {
            const beginDateLabel = date.formatDate(values[0], 'DD-MM-YYYY');
            const endDateLabel = date.formatDate(values[1], 'DD-MM-YYYY');
            this.dateRangeLabel = `${beginDateLabel} - ${endDateLabel}`;
        }
        else {
            this.dateRangeLabel = newDateRangeLabel;
        }
    }
    initializeRangeLabel() {
        this.setDateRangeLabel(this.rangeDate, '');
    }
    closeDatePickerMenu() {
        // @ts-ignore
        this.$refs.dropdownMenu.hide();
    }
    get loadingData() {
        return this.dataStatus === 'receiving';
    }
};
__decorate([
    Prop({ default: 'all' })
], AuditsFilters.prototype, "origin", void 0);
__decorate([
    Prop({ default: 'all' })
], AuditsFilters.prototype, "operation", void 0);
__decorate([
    Prop({ default: 'success' })
], AuditsFilters.prototype, "status", void 0);
__decorate([
    Prop({ default: () => [new Date(), new Date()] })
], AuditsFilters.prototype, "rangeDate", void 0);
__decorate([
    Prop({ default: false })
], AuditsFilters.prototype, "dataStatus", void 0);
AuditsFilters = __decorate([
    Component({
        components: {
            DateRangePicker,
        },
    })
], AuditsFilters);
export default AuditsFilters;
