var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
let AppsOwnedTableTab = class AppsOwnedTableTab extends Vue {
    formatAppStatus(app_status) {
        const status = {
            waiting: 'Aguardando pagamento',
            active: 'Ativo',
            cancelled: 'Cancelado',
            expired: 'Expirado',
            selected: 'Selecionado',
            demo: 'Demo',
        };
        return status[app_status];
    }
};
__decorate([
    Prop({ default: () => ({ apps: [{}], code: 200 }) })
], AppsOwnedTableTab.prototype, "apps", void 0);
AppsOwnedTableTab = __decorate([
    Component({})
], AppsOwnedTableTab);
export default AppsOwnedTableTab;
