var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
let OnboardingUserStatus = class OnboardingUserStatus extends Vue {
    generateLabel(value) {
        switch (value) {
            case 'scheduled':
                return 'Agendado';
            case 'already_done':
                return 'Já fez';
            case 'refused':
                return 'Recusado';
            case 'no_onboarding':
                return 'Não tem onboarding';
            default:
                return;
        }
    }
};
__decorate([
    Prop({ default: 'pending' })
], OnboardingUserStatus.prototype, "OnboardinguserStatus", void 0);
OnboardingUserStatus = __decorate([
    Component({
        name: 'OnboardingUserStatus',
        components: {},
    })
], OnboardingUserStatus);
export default OnboardingUserStatus;
