import { render, staticRenderFns } from "./CreditsHistoryDetails.vue?vue&type=template&id=000b13be&scoped=true&"
import script from "./CreditsHistoryDetails.vue?vue&type=script&lang=ts&"
export * from "./CreditsHistoryDetails.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "000b13be",
  null
  
)

export default component.exports