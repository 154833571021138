var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AdminStatus } from '@/overmind/admin/state';
import CreditsHistoryRow from '@/modules/admin/components/AccountManagementModal/AccountManagementeCreditsConsumptionTab/CreditsHistoryRow.vue';
import CreditsHistoryEmpty from '@/modules/admin/components/AccountManagementModal/AccountManagementeCreditsConsumptionTab/CreditsHistoryEmpty.vue';
import CreditsHistorySkeleton from '@/modules/admin/components/AccountManagementModal/AccountManagementeCreditsConsumptionTab/CreditsHistorySkeleton.vue';
import { groupBy } from 'lodash';
import { json } from 'overmind';
let CreditsHistory = class CreditsHistory extends Vue {
    constructor() {
        super(...arguments);
        this.historyGrouped = json(groupBy(this.creditsHistory, 'period'));
    }
    // clients in this month received consumptions with inverted value
    // beforeUpdate() {
    //   const may_history = this.historyGrouped['2022-05']
    //   if (may_history && may_history[0].type === 'export') {
    //     may_history.reverse()
    //   }
    // }
    onChangeCreditsHistory(newHistory, _oldHistory) {
        this.historyGrouped = json(groupBy(newHistory, 'period'));
    }
    showDetails(payload) {
        this.$emit('showDetails', payload);
    }
};
__decorate([
    Prop({ default: AdminStatus.IDLE })
], CreditsHistory.prototype, "status", void 0);
__decorate([
    Prop({ default: () => [] })
], CreditsHistory.prototype, "creditsHistory", void 0);
__decorate([
    Watch('creditsHistory')
], CreditsHistory.prototype, "onChangeCreditsHistory", null);
CreditsHistory = __decorate([
    Component({
        components: {
            CreditsHistoryRow,
            CreditsHistoryEmpty,
            CreditsHistorySkeleton,
        },
    })
], CreditsHistory);
export default CreditsHistory;
