var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, PropSync, Watch, Emit, } from 'vue-property-decorator';
import { json } from 'overmind';
import DateRangePicker from '@/components/DateRangePicker.vue';
import SurveysListItem from '@/modules/admin/components/SurveysListItem.vue';
import InnerLoading from '@/components/InnerLoading.vue';
import Modal from 'components/Modal.vue';
import Input from '@/components/Input.vue';
import Pagination from '@/components/Pagination.vue';
import Tootltip from '@/components/Tooltip.vue';
import { date } from 'quasar';
import CircularProgress from '@/components/CircularProgress.vue';
import SurveyPercentage from './SurveyPercentage.vue';
import SurveyList from './SurveyList.vue';
import IconGraphy from '@/components/Icons/IconGraphy.vue';
import IconLeftArrow from '@/components/Icons/IconLeftArrow.vue';
import IconVoucher from '@/components/Icons/IconVoucher.vue';
import { ListUsersVoucherStatus, } from '@/overmind/admin/state';
let SurveyModal = class SurveyModal extends Vue {
    constructor() {
        super(...arguments);
        this.dateRange = this.handleInitialDateRange();
        this.informativeMessage = 'O número antes do "/" representa o valor avaliado, enquanto o que está após representa a quantidade de avaliações para este valor.';
        this.currentPage = 1;
        this.showFilters = false;
        this.showGraphyFilter = false;
        this.showVoucherFilter = false;
        this.showSurveyList = false;
        this.scheduleIsOpen = false;
        this.dateRangeLabel = 'Últimos 30 dias';
        this.questionSurveyType = 'Satisfaction';
        this.searchTerm = '';
        this.usersTotalCount = '';
        this.filteredByClassification = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
        this.surveyColumns = [
            { type: 'nome', label: 'Nome' },
            { type: 'email', label: 'E-mail' },
            { type: 'avaliação', label: 'Avaliação' },
            { type: 'comentário', label: 'Comentário' },
            { type: 'data da avaliação', label: 'Data' },
        ];
        this.userWithVoucherColumns = [
            { type: 'nome', label: 'Nome' },
            { type: 'email', label: 'E-mail' },
            { type: 'role', label: 'Tipo' },
            { type: 'avaliação', label: 'Avaliação' },
            { type: 'data da avaliação', label: 'Data' },
            { type: 'ação', label: 'Ação' },
        ];
        this.classifications = [
            { isSelected: true, label: 'Detratores (0-6)', value: 'detractors' },
            { isSelected: true, label: 'Neutros (7-8)', value: 'impartial' },
            { isSelected: true, label: 'Promotores (9-10)', value: 'promoters' },
        ];
    }
    onSurveyInformation(newVal, oldVal) {
        this.currentPage = newVal.page;
    }
    handleInitialDateRange() {
        let today = new Date();
        const begin = date.subtractFromDate(today, { days: 30 });
        begin.setHours(0, 0, 0, 0);
        const end = date.subtractFromDate(today, { days: 1 });
        end.setHours(23, 59, 59, 999);
        return [begin, end];
    }
    get detractors() {
        return json(this.surveyInformation.ratings).filter(rating => rating >= 0 && rating <= 6);
    }
    get impartial() {
        return json(this.surveyInformation.ratings).filter(rating => rating >= 7 && rating <= 8);
    }
    get promoters() {
        return json(this.surveyInformation.ratings).filter(rating => rating === 9 || rating === 10);
    }
    changeDateRange(values, newDateRangeLabel) {
        this.dateRange = values;
        this.dateRangeLabel = newDateRangeLabel;
        this.currentPage = 1;
        this.sendSurveyData(this.dateRange[0], this.dateRange[1]);
        this.closeDatePickerMenu();
    }
    closeDatePickerMenu() {
        this.scheduleIsOpen = false;
        // @ts-ignore
        this.$refs.dropdownMenu.hide();
    }
    canLoading() {
        if (!this.showVoucherFilter)
            return this.surveyStatus === 'receiving';
        return this.listUsersWithVoucherStatus === 'receiving';
    }
    filterByRating() {
        this.filteredByClassification = [];
        if (this.classifications[0].isSelected)
            this.filteredByClassification = [
                ...this.filteredByClassification,
                0,
                1,
                2,
                3,
                4,
                5,
                6,
            ];
        if (this.classifications[1].isSelected)
            this.filteredByClassification = [...this.filteredByClassification, 7, 8];
        if (this.classifications[2].isSelected)
            this.filteredByClassification = [...this.filteredByClassification, 9, 10];
        this.currentPage = 1;
        this.showFilters = false;
        this.sendSurveyData(this.dateRange[0], this.dateRange[1]);
    }
    onSearch() {
        setTimeout(() => {
            this.currentPage = 1;
            this.sendSurveyData(this.dateRange[0], this.dateRange[1]);
        }, 1200);
    }
    npsCalculation() {
        const ratingCount = this.surveyInformation.ratings.length;
        const subtr = this.promoters.length - this.detractors.length;
        const npsCalc = (subtr / ratingCount) * 100;
        if (!npsCalc || npsCalc <= 0)
            return 0;
        else if (npsCalc >= 100)
            return 100;
        return Number(npsCalc).toFixed(0);
    }
    setPageNumber(page) {
        this.currentPage = page;
        if (this.showVoucherFilter)
            this.listUserWithVoucher();
        else
            this.sendSurveyData(this.dateRange[0], this.dateRange[1]);
    }
    sendSurveyData(initDate, endDate) {
        this.$emit('getAllSurveys', {
            init_date: initDate,
            end_date: endDate,
            type: this.questionSurveyType,
            page: this.currentPage,
            ratings: JSON.stringify(this.filteredByClassification),
            name_or_email: this.searchTerm || '',
        });
    }
    listUserWithVoucher() {
        this.$emit('listUserWithVoucher', { page: this.currentPage });
        return;
    }
    hasSurveys() {
        return (this.surveyInformation.data.length > 0 && this.surveyStatus === 'received');
    }
    handleTotalRatingByValue(val) {
        return this.surveyInformation.ratings.filter(r => r === val).length;
    }
    mounted() {
        this.showSurveyList = true;
        this.listUserWithVoucher();
        const initDate = this.handleInitialDateRange()[0];
        const endDate = this.handleInitialDateRange()[1];
        this.sendSurveyData(initDate, endDate);
    }
    handleOptionClicked() {
        this.showVoucherFilter = !this.showVoucherFilter;
        this.showSurveyList = !this.showSurveyList;
        this.showGraphyFilter = false;
    }
    setGraphySection() {
        this.showVoucherFilter = false;
        this.showGraphyFilter = !this.showGraphyFilter;
    }
    handleColumns() {
        if (this.showSurveyList)
            return this.surveyColumns;
        else
            return this.userWithVoucherColumns;
    }
    removeVoucher({ allowedVoucher, action, surveyId, }) {
        return { allowedVoucher, action, surveyId };
    }
    handleTotalPage() {
        if (!this.showVoucherFilter)
            return this.surveyInformation.total_page;
        return this.listUsersWithVoucher.total_page;
    }
};
__decorate([
    Prop({ default: 'all' })
], SurveyModal.prototype, "origin", void 0);
__decorate([
    PropSync('open', { default: false })
], SurveyModal.prototype, "showModal", void 0);
__decorate([
    Prop({ default: () => { } })
], SurveyModal.prototype, "surveyInformation", void 0);
__decorate([
    Prop({ default: '' })
], SurveyModal.prototype, "surveyStatus", void 0);
__decorate([
    Prop({ default: () => { } })
], SurveyModal.prototype, "listUsersWithVoucher", void 0);
__decorate([
    Prop({ default: ListUsersVoucherStatus.IDLE })
], SurveyModal.prototype, "listUsersWithVoucherStatus", void 0);
__decorate([
    Watch('surveyInformation', { immediate: true, deep: true })
], SurveyModal.prototype, "onSurveyInformation", null);
__decorate([
    Emit('removeVoucher')
], SurveyModal.prototype, "removeVoucher", null);
SurveyModal = __decorate([
    Component({
        name: 'NewSurveyModal',
        components: {
            Modal,
            SurveysListItem,
            InnerLoading,
            Input,
            DateRangePicker,
            Pagination,
            Tootltip,
            CircularProgress,
            SurveyPercentage,
            SurveyList,
            IconGraphy,
            IconLeftArrow,
            IconVoucher,
        },
    })
], SurveyModal);
export default SurveyModal;
