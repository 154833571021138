import { render, staticRenderFns } from "./CreditsConsumptionHistoryFilters.vue?vue&type=template&id=90fdf3a0&scoped=true&"
import script from "./CreditsConsumptionHistoryFilters.vue?vue&type=script&lang=ts&"
export * from "./CreditsConsumptionHistoryFilters.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90fdf3a0",
  null
  
)

export default component.exports