var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import UserRevokeModal from '@/modules/admin/components/UserRevokeModal.vue';
let SubUsersTableRow = class SubUsersTableRow extends Vue {
    constructor() {
        super(...arguments);
        this.showUserRevokeModal = false;
        this.subUserEmail = '';
        this.subUserName = '';
        this.subUserRole = '';
        this.openFormEdit = false;
        this.subUserId = '';
        this.openForm = false;
        this.typeUsersForRole = ['Manager', 'Coordenador', 'Prospector'];
    }
    onStatusUpdatedSubUserChange(currVal, oldVal) {
        this.openFormEdit = false;
    }
    openFormEditUser(id, name, email, role) {
        this.openFormEdit = true;
        this.subUserId = id;
        this.subUserName = name;
        this.subUserRole = role;
        this.subUserEmail = email;
    }
    closeFormEdit() {
        this.openFormEdit = false;
    }
    confirmEditUser() {
        this.subUserRole === 'Coordenador'
            ? (this.subUserRole = 'coordinator')
            : (this.subUserRole = this.subUserRole.toLowerCase());
        this.$emit('confirm-edit-user', this.subUserId, this.subUserName, this.subUserEmail, this.subUserRole);
        this.closeFormEdit();
    }
};
__decorate([
    Prop({ default: () => { } })
], SubUsersTableRow.prototype, "subUser", void 0);
__decorate([
    Prop({ default: false })
], SubUsersTableRow.prototype, "statusUpdatedSubUser", void 0);
__decorate([
    Watch('statusUpdatedSubUser')
], SubUsersTableRow.prototype, "onStatusUpdatedSubUserChange", null);
SubUsersTableRow = __decorate([
    Component({
        name: 'SubUsersTableRow',
        components: {
            UserRevokeModal,
        },
    })
], SubUsersTableRow);
export default SubUsersTableRow;
