var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import AuditsCreditsFiltersContainer from '@/modules/admin/containers/AccountManagementModal/AccountManagementTabs/AccountManagementAuditsTab/CreditsTab/AuditsCreditsFilters.container.vue';
import AuditsCreditsTabContainer from '@/modules/admin/containers/AccountManagementModal/AccountManagementTabs/AccountManagementAuditsTab/CreditsTab/AuditsCreditsTab.container.vue';
import AuditsList from '@/modules/admin/components/AccountManagementModal/AccountManagementAuditsTab/CreditsTab/AuditsList.vue';
let AccountManagementAuditsTab = class AccountManagementAuditsTab extends Vue {
    constructor() {
        super(...arguments);
        this.tab = 'credits';
    }
};
AccountManagementAuditsTab = __decorate([
    Component({
        components: {
            AuditsCreditsFiltersContainer,
            AuditsList,
            AuditsCreditsTabContainer,
        },
    })
], AccountManagementAuditsTab);
export default AccountManagementAuditsTab;
