var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Input from '@/components/Input.vue';
import { CreditOperation } from '@/interfaces/admin.interfaces';
import { AdminStatus } from '@/overmind/admin/state';
import { formatDateToBR } from '@/shared/utils/helpers';
import { isEmpty } from 'lodash';
import { json } from 'overmind';
import { date } from 'quasar';
let AccountManagementCreditsForm = class AccountManagementCreditsForm extends Vue {
    constructor() {
        super(...arguments);
        this.creditSelected = 'export';
        this.creditOptions = [
            { label: 'Cliques', value: 'click' },
            { label: 'Exportações', value: 'export' },
            { label: 'Cliques Ilimitados', value: 'unlimitedClick' },
        ];
        this.expiresDate = '';
        this.amount = json(this.getCreditsAmount());
        this.description = '';
        this.disableSubmit = true;
    }
    mounted() {
        // @ts-ignore
        this.$refs.inputAmount.$refs.qinput.$refs.input.focus();
        this.defaultExpiresDate();
        this.validateMinAmount('');
    }
    cannotSelect(item) {
        if (this.handleCannotSelectWhenRemoveOperation(item))
            return true;
        if (this.handleCannotSelectWhenAddOperation(item))
            return true;
        return false;
    }
    isCreditsValid() {
        return (this.operation === CreditOperation.ADD ||
            (this.creditSelected === 'unlimitedClick' &&
                this.currentCredits.unlimitedClick) ||
            (this.accountHasBalanceFor(this.creditSelected) && this.isAmountValid()));
    }
    accountHasBalanceFor(creditSelected) {
        return !!this.currentCredits[creditSelected];
    }
    isAmountValid() {
        return (Number(this.amount) > 0 &&
            this.isAmountEqualsOrLowerThan(this.creditSelected));
    }
    isAmountEqualsOrLowerThan(creditSelected) {
        return Number(this.amount) <= this.currentCredits[creditSelected];
    }
    handleCannotSelectWhenRemoveOperation(selectedItem) {
        if (this.operation === CreditOperation.REMOVE) {
            if (!this.currentCredits.unlimitedClick) {
                if (selectedItem.value === 'unlimitedClick')
                    return true;
                return false;
            }
            if (selectedItem.value === 'click')
                return true;
            return false;
        }
        return false;
    }
    handleCannotSelectWhenAddOperation(selectedItem) {
        if (this.operation === CreditOperation.ADD) {
            if (this.currentCredits.unlimitedClick) {
                if (selectedItem.value !== 'export')
                    return true;
            }
            return false;
        }
        return false;
    }
    handleShowTooltipHelper(scope) {
        if (this.operation === CreditOperation.ADD) {
            return ((this.currentCredits.unlimitedClick && scope.opt.value === 'click') ||
                scope.opt.value === 'unlimitedClick');
        }
        if (this.operation === CreditOperation.REMOVE) {
            return this.currentCredits.unlimitedClick && scope.opt.value === 'click';
        }
        return false;
    }
    validateMinAmount(val) {
        if (this.creditSelected === 'unlimitedClick' &&
            !this.currentCredits.unlimitedClick) {
            this.disableSubmit = false;
            return true;
        }
        if (val >= 0 && this.amount !== null && Number(this.amount) > 0) {
            this.disableSubmit = false;
            return true;
        }
        this.disableSubmit = val !== null || val !== '' || true;
        return val === null || val === '' || 'O valor precisa ser maior que zero';
    }
    dateExpiresDisabledBeforeToday(date) {
        const today = new Date();
        return date < today;
    }
    onOperationChange(newOperation) {
        if (newOperation === CreditOperation.REMOVE) {
            this.creditSelected = 'export';
        }
        if (newOperation === CreditOperation.ADD &&
            this.currentCredits.unlimitedClick) {
            if (this.creditSelected === 'unlimitedClick')
                this.creditSelected = 'export';
        }
        // @ts-ignore
        this.$refs.inputAmount.$refs.qinput.validate();
    }
    onChangeCreditSelected(creditChoice) {
        // @ts-ignore
        //this.$refs.inputAmount.$refs.qinput.validate()
        if (creditChoice !== 'unlimitedClick') {
            // @ts-ignore
            this.$refs.inputAmount.$refs.qinput.$refs.input.focus();
        }
        else {
            this.disableSubmit = false;
        }
    }
    getCreditsAmount() {
        if (!isEmpty(this.currentCredits)) {
            if (this.creditSelected === 'click') {
                return this.currentCredits.click.toString();
            }
            if (this.creditSelected === 'export') {
                return this.currentCredits.export.toString();
            }
        }
        return '';
    }
    defaultExpiresDate() {
        const dateDefault = new Date();
        const day = dateDefault.getDate();
        dateDefault.setDate(day + 33);
        dateDefault.setHours(23, 59, 59, 999);
        this.expiresDate = formatDateToBR(date.formatDate(dateDefault.toString(), 'YYYY-MM-DD'));
    }
    confirmChanges() {
        this.$emit('confirmChanges', {
            credit: {
                type: this.creditSelected,
                amount: this.amount,
                expiresDate: this.expiresDate,
                description: this.description,
            },
        });
        if (this.creditSelected === 'unlimitedClick') {
            this.initializeFields();
        }
        this.description = '';
        this.amount = '';
    }
    initializeFields() {
        this.creditSelected = 'export';
        this.description = '';
        this.amount = '';
    }
};
__decorate([
    Prop({ default: CreditOperation.ADD })
], AccountManagementCreditsForm.prototype, "operation", void 0);
__decorate([
    Prop({
        default: () => ({
            click: 0,
            export: 0,
            unlimitedClick: false,
        }),
    })
], AccountManagementCreditsForm.prototype, "currentCredits", void 0);
__decorate([
    Prop({ default: AdminStatus.IDLE })
], AccountManagementCreditsForm.prototype, "status", void 0);
__decorate([
    Watch('operation')
], AccountManagementCreditsForm.prototype, "onOperationChange", null);
AccountManagementCreditsForm = __decorate([
    Component({
        components: {
            Input,
        },
    })
], AccountManagementCreditsForm);
export default AccountManagementCreditsForm;
