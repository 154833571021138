var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import DateRangePicker from '@/components/DateRangePicker.vue';
import { AdminStatus } from '@/overmind/admin/state';
import { formatDateToBR, } from '@/shared/utils/helpers';
let CreditsConsumptionHistoryFilters = class CreditsConsumptionHistoryFilters extends Vue {
    constructor() {
        super(...arguments);
        this.dateRangeLabel = 'Hoje';
    }
    created() {
        this.initializeRangeLabel();
    }
    changeDateRange(values, newDateRangeLabel) {
        this.setDateRangeLabel(values, newDateRangeLabel);
        this.closeDatePickerMenu();
        this.$emit('changeDate', values);
    }
    setDateRangeLabel(values, newDateRangeLabel) {
        if (!newDateRangeLabel) {
            const beginDateLabel = formatDateToBR(new Date(values[0]).toISOString(), '-');
            const endDateLabel = formatDateToBR(new Date(values[1]).toISOString(), '-');
            this.dateRangeLabel = `${beginDateLabel} - ${endDateLabel}`;
        }
        else {
            this.dateRangeLabel = newDateRangeLabel;
        }
    }
    closeDatePickerMenu() {
        // @ts-ignore
        this.$refs.dropdownMenu.hide();
    }
    initializeRangeLabel() {
        const initDate = new Date(this.dateRange[0]);
        let endDate = new Date(this.dateRange[1]);
        endDate = new Date(endDate.valueOf() - endDate.getTimezoneOffset() * 60000);
        this.setDateRangeLabel([initDate, endDate], '');
    }
};
__decorate([
    PropSync('groupBy', { default: 'month' })
], CreditsConsumptionHistoryFilters.prototype, "groupByOption", void 0);
__decorate([
    PropSync('rangeDate', {
        default: () => [new Date().toISOString(), new Date().toISOString()],
    })
], CreditsConsumptionHistoryFilters.prototype, "dateRange", void 0);
__decorate([
    Prop({ default: AdminStatus.IDLE })
], CreditsConsumptionHistoryFilters.prototype, "status", void 0);
CreditsConsumptionHistoryFilters = __decorate([
    Component({
        components: {
            DateRangePicker,
        },
    })
], CreditsConsumptionHistoryFilters);
export default CreditsConsumptionHistoryFilters;
