var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, PropSync, Watch, } from 'vue-property-decorator';
import { AllCountries, AllStates } from '@/shared/utils/helpers';
import { deburr, isEmpty } from 'lodash';
import Modal from 'components/Modal.vue';
import Input from 'components/Input.vue';
const allStates = AllStates();
const allCountries = AllCountries();
let TestAccountModal = class TestAccountModal extends Vue {
    constructor() {
        super(...arguments);
        this.data = {
            name: '',
            email: '',
            password: '',
            password_confirmation: '',
            phone_number: '',
            street_address: '',
            complemental_address: '',
            neighborhood: '',
            city: '',
            state: '',
            country: 'Brasil',
            zipcode: '',
            role: 2,
        };
        this.stateOptions = allStates;
        this.countryOptions = allCountries;
        this.rolesOptions = [
            {
                label: 'Owner',
                value: 2,
            },
            {
                label: 'Customer Success',
                value: 6,
            },
        ];
    }
    onInfoCepChange(currVal, oldVal) {
        if (!isEmpty(currVal)) {
            this.data.city = currVal.city;
            this.data.neighborhood = currVal.neighborhood;
            this.data.state = this.searchState(currVal.state);
            this.data.street_address = currVal.street;
        }
    }
    onShowModalChange(currVal, oldVal) {
        if (currVal)
            this.resetAll();
    }
    registerAccount() {
        const validation = this.validForm();
        validation.then(res => {
            if (res) {
                this.$emit('registerAccount', this.data);
            }
            else {
                this.$q.notify({
                    message: 'Preencha todos os campos obrigatórios',
                    type: 'warning',
                });
            }
        });
    }
    validForm() {
        // @ts-ignore TS2349: property inexistent
        return this.$refs.formAccountTest.validate().then(success => success);
    }
    resetValidationForm() {
        setTimeout(() => {
            // @ts-ignore TS2349: property inexistent
            this.$refs.formAccountTest.resetValidation();
        }, 300);
    }
    stateFilter(val, update) {
        if (val === '') {
            update(() => {
                this.stateOptions = allStates;
            });
            return;
        }
        update(() => {
            const needle = deburr(val.toLowerCase());
            this.stateOptions = allStates.filter(v => deburr(v.label.toLowerCase()).indexOf(needle) > -1);
        });
    }
    countryFilter(val, update) {
        if (val === '') {
            update(() => {
                this.countryOptions = allCountries;
            });
            return;
        }
        update(() => {
            const needle = deburr(val.toLowerCase());
            this.countryOptions = allCountries.filter(v => deburr(v.toLowerCase()).indexOf(needle) > -1);
        });
    }
    checkingEmail(value) {
        const start = value.indexOf('@') > -1;
        const end = value.indexOf('.com') > -1;
        if (start && end) {
            return true;
        }
        else {
            return false;
        }
    }
    searchZip() {
        this.$emit('searchZip', this.data.zipcode);
    }
    resetValues() {
        this.data = {
            name: '',
            email: '',
            password: '',
            password_confirmation: '',
            phone_number: '',
            street_address: '',
            complemental_address: '',
            neighborhood: '',
            city: '',
            state: '',
            country: 'Brasil',
            zipcode: '',
            role: 2,
        };
    }
    resetAll() {
        this.resetValues();
        this.resetValidationForm();
    }
    searchState(value) {
        if (!isEmpty(value)) {
            const stateFound = allStates.find(option => option.value === value);
            if (stateFound !== undefined) {
                return stateFound.label;
            }
            else {
                return '';
            }
        }
    }
};
__decorate([
    PropSync('open', { default: false })
], TestAccountModal.prototype, "showModal", void 0);
__decorate([
    Prop({ default: () => { } })
], TestAccountModal.prototype, "infoCep", void 0);
__decorate([
    Watch('infoCep')
], TestAccountModal.prototype, "onInfoCepChange", null);
__decorate([
    Watch('showModal')
], TestAccountModal.prototype, "onShowModalChange", null);
TestAccountModal = __decorate([
    Component({
        name: 'TestAccountModal',
        components: { Modal, Input },
    })
], TestAccountModal);
export default TestAccountModal;
