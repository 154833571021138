var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getDateByFormat } from '@/shared/utils/helpers';
let CreditsHistoryRow = class CreditsHistoryRow extends Vue {
    constructor() {
        super(...arguments);
        this.type = {
            click: 'Cliques',
            unlimited_click: 'Cliques Ilimitados',
            export: 'Exportações',
        };
        this.dateByFormat = {
            day: getDateByFormat(this.history[0].period, 'dddd, D [de] MMMM/YYYY'),
            month: getDateByFormat(this.history[0].period, 'MMMM/YYYY'),
            year: getDateByFormat(this.history[0].period, 'YYYY'),
        };
    }
    get periodLabel() {
        return this.dateByFormat[this.history[0].groupBy || 'month'];
    }
    historyBalance(creditType) {
        if (this.history.length >= 0) {
            if (creditType === 'unlimited_click') {
                return this.history[0].balance.unlimited_click
                    ? 'Ilimitado'
                    : 'Sem saldo';
            }
            const history = this.history.find(h => h.balance[creditType]);
            if (history) {
                return history.balance[creditType] || 'Sem saldo';
            }
            return '';
        }
        return 'Sem saldo';
    }
    showDetails(creditType) {
        // let period = ''
        // if (this.history[0].groupBy === 'day') {
        //   period = this.history[0].period
        // } else {
        //   period = this.dateByFormat[this.history[0].groupBy || 'month']
        // }
        this.$emit('click', {
            creditType,
            period: this.history[0].period,
        });
    }
    creditsConsumption(creditType) {
        const credits = this.history.find(h => h.type === creditType) || {
            spent: 0,
        };
        return credits.spent;
    }
};
__decorate([
    Prop({ default: () => [] })
], CreditsHistoryRow.prototype, "history", void 0);
__decorate([
    Prop({ default: () => [] })
], CreditsHistoryRow.prototype, "periods", void 0);
CreditsHistoryRow = __decorate([
    Component
], CreditsHistoryRow);
export default CreditsHistoryRow;
