var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, PropSync, Emit } from 'vue-property-decorator';
import UsersCreditsValidTableRow from './UsersCreditsValidTableRow.vue';
import AddCreditsTabContainer from '@/modules/admin/containers/AddCreditsTab.container.vue';
let UserContentTabs = class UserContentTabs extends Vue {
    constructor() {
        super(...arguments);
        this.noFooter = true;
    }
    removeFooter(value) {
        return value;
    }
};
__decorate([
    PropSync('currentTab', { default: 'adicionarCreditos' })
], UserContentTabs.prototype, "tab", void 0);
__decorate([
    Emit('changeTab')
], UserContentTabs.prototype, "removeFooter", null);
UserContentTabs = __decorate([
    Component({
        components: {
            AddCreditsTabContainer,
            UsersCreditsValidTableRow,
        },
    })
], UserContentTabs);
export default UserContentTabs;
