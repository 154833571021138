var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, PropSync } from 'vue-property-decorator';
import AccessTokenModal from '@/modules/admin/components/AccessToken/Modal.vue';
import { connect } from '@/overmind';
let AccessTokenModalContainer = class AccessTokenModalContainer extends Vue {
    async tryToLogin(token) {
        // @ts-ignore
        let login_token = await this.actions.useAccessToken(token);
        login_token = login_token.data.token;
        localStorage.setItem('auth_token_default', login_token);
        window.location.reload();
    }
};
__decorate([
    PropSync('open', { default: false })
], AccessTokenModalContainer.prototype, "showAccessTokenModal", void 0);
AccessTokenModalContainer = __decorate([
    Component(connect(({ actions: { admin: actions } }) => ({
        actions: actions,
    }), {
        name: 'AccessTokenModalContainer',
        components: { AccessTokenModal },
    }))
], AccessTokenModalContainer);
export default AccessTokenModalContainer;
