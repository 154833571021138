var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
let FiltersUsedOnSearchTableTab = class FiltersUsedOnSearchTableTab extends Vue {
    formattedValue(value) {
        if (value.length == 0)
            return 'Não usou';
        return value;
    }
    convertBooleanToString(value) {
        return value ? 'Sim' : 'Não';
    }
    searchUrl(search_id) {
        return window.location.origin + '/search/' + search_id;
    }
};
__decorate([
    Prop({ default: () => ({ searches: [{}], code: 200 }) })
], FiltersUsedOnSearchTableTab.prototype, "clientSearches", void 0);
FiltersUsedOnSearchTableTab = __decorate([
    Component({})
], FiltersUsedOnSearchTableTab);
export default FiltersUsedOnSearchTableTab;
