var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
import IconChecked from '@/components/Icons/IconChecked.vue';
let OnboardingAdminStatus = class OnboardingAdminStatus extends Vue {
};
__decorate([
    Prop({ default: 'new' })
], OnboardingAdminStatus.prototype, "onboardCsValidation", void 0);
OnboardingAdminStatus = __decorate([
    Component({
        name: 'OnboardingAdminStatus',
        components: { IconChecked },
    })
], OnboardingAdminStatus);
export default OnboardingAdminStatus;
