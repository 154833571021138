var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, PropSync, Emit, Prop, Watch, } from 'vue-property-decorator';
import InnerLoading from '@/components/InnerLoading.vue';
import Modal from '@/components/Modal.vue';
import Input from '@/components/Input.vue';
import { isEmail } from '@/shared/utils/helpers';
let AccountPreferencesModal = class AccountPreferencesModal extends Vue {
    constructor() {
        super(...arguments);
        this.userForm = {
            id: '',
            email: '',
            accountPreferences: { email: '', id: '', accountId: '' },
            accountOwner: undefined,
            accountId: '',
            usersCount: 0,
            createdAt: '',
            name: '',
            role: '',
            accountType: '',
            planName: '',
            userStatus: '',
            internalExportCount: 0,
            accountStatus: '',
            cs: { name: '', email: '', id: '' },
        };
    }
    changeUser(user) {
        this.userForm = { ...user };
    }
    checkingEmail(email) {
        return isEmail(email);
    }
    async validForm() {
        // @ts-ignore TS2349: property inexistent
        const ref = await this.$refs.formAccount.validate();
        if (ref) {
            this.handleConfirm();
        }
    }
    handleConfirm() {
        return this.userForm;
    }
    closeModal() {
        return true;
    }
};
__decorate([
    PropSync('open', { default: false })
], AccountPreferencesModal.prototype, "showModal", void 0);
__decorate([
    Prop({ default: () => { } })
], AccountPreferencesModal.prototype, "user", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], AccountPreferencesModal.prototype, "loading", void 0);
__decorate([
    Watch('user', { deep: true, immediate: true })
], AccountPreferencesModal.prototype, "changeUser", null);
__decorate([
    Emit('confirm')
], AccountPreferencesModal.prototype, "handleConfirm", null);
__decorate([
    Emit('closeModal')
], AccountPreferencesModal.prototype, "closeModal", null);
AccountPreferencesModal = __decorate([
    Component({
        components: {
            Modal,
            Input,
            InnerLoading,
        },
    })
], AccountPreferencesModal);
export default AccountPreferencesModal;
