var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"open":_vm.showRecentEventsModal,"showBorderHeader":true,"noHeader":false,"noFooter":true,"height":720,"width":1220,"title":"Notificações enviadas (atualização automática a cada 2 minutos)"},on:{"update:open":[function($event){_vm.showRecentEventsModal=$event},function($event){_vm.showRecentEventsModal = false}],"onClose":function($event){_vm.showRecentEventsModal = false}}},[(_vm.notifications.lenght == 0)?_c('LoadingDots',{staticClass:"loading"}):_c('div',{staticClass:"notification-table"},[_c('table',{staticClass:"notification-tab-table"},[_c('thead',[_c('th',[_vm._v("E-mail")]),_c('th',[_vm._v("Notificação")]),_c('th',{staticStyle:{"white-space":"nowrap","padding-right":"6px"}},[_vm._v("\n          Mensagem"),_c('br'),_vm._v("ao Usuário\n        ")]),_c('th',{staticStyle:{"white-space":"nowrap"}},[_vm._v("Mensagem"),_c('br'),_vm._v("ao CS")]),_c('th',{staticStyle:{"white-space":"nowrap"}},[_vm._v("Notificado"),_c('br'),_vm._v("no Whatsapp")]),_c('th',{staticStyle:{"white-space":"nowrap"}},[_vm._v("Notificado"),_c('br'),_vm._v("no Atlas")]),_c('th',{staticClass:"tooltip-handler",staticStyle:{"white-space":"nowrap"}},[_vm._v("\n          Data de"),_c('br'),_vm._v("envio\n          "),_c('Tooltip',{attrs:{"message":"Quando a notificação foi enviada ao usuário","anchor":"top middle","self":"bottom middle"}})],1),_c('th',{staticClass:"tooltip-handler",staticStyle:{"white-space":"nowrap"}},[_vm._v("\n          Data de"),_c('br'),_vm._v("criação\n          "),_c('Tooltip',{attrs:{"message":"Quando o usuário teve o comportamento para criar a notificação","anchor":"top middle","self":"bottom middle"}})],1)]),_c('tbody',_vm._l((_vm.notifications),function(notification){return _c('tr',{key:notification.id},[_c('td',[_vm._v(_vm._s(notification.user_email))]),_c('td',[_vm._v(_vm._s(notification.rule))]),_c('td',[(
                _vm.has_log(notification, 'user') &&
                !_vm.has_error(notification, 'user') &&
                !_vm.has_exceed_quota(notification, 'user')
              )?_c('div',[_c('span',{staticClass:"tooltip-handler"},[_vm._v("Visualizar")]),_c('Tooltip',{attrs:{"message":_vm.get_message_sent(notification, 'user'),"anchor":"top middle","self":"bottom middle"}})],1):_c('span',{class:{
                'tooltip-handler':
                  _vm.has_error(notification, 'user') ||
                  _vm.has_exceed_quota(notification, 'user'),
              },staticStyle:{"white-space":"nowrap"}},[_vm._v("\n              Não notificado\n              "),(
                  _vm.has_error(notification, 'user') ||
                  _vm.has_exceed_quota(notification, 'user')
                )?_c('Tooltip',{attrs:{"message":'Motivo: ' + notification.log['user'].whatsapp_log,"anchor":"top middle","self":"bottom middle"}}):_vm._e()],1)]),_c('td',[(
                _vm.has_log(notification, 'cs') &&
                !_vm.has_error(notification, 'cs') &&
                !_vm.has_exceed_quota(notification, 'cs')
              )?_c('div',[_c('span',{staticClass:"tooltip-handler"},[_vm._v("Visualizar")]),_c('Tooltip',{attrs:{"message":_vm.get_message_sent(notification, 'cs'),"anchor":"top middle","self":"bottom middle"}})],1):_c('span',{class:{
                'tooltip-handler':
                  _vm.has_error(notification, 'cs') ||
                  _vm.has_exceed_quota(notification, 'cs'),
              },staticStyle:{"white-space":"nowrap"}},[_vm._v("\n              Não notificado\n              "),(
                  _vm.has_error(notification, 'cs') ||
                  _vm.has_exceed_quota(notification, 'cs')
                )?_c('Tooltip',{attrs:{"message":'Motivo: ' + notification.log['cs'].whatsapp_log,"anchor":"top middle","self":"bottom middle"}}):_vm._e()],1)]),_c('td',[_vm._v("\n            "+_vm._s(_vm.notificationProcessed(
                notification,
                'user',
                'whatsapp_processed'
              )
                ? 'Sim'
                : 'Não')+"\n          ")]),_c('td',[_vm._v("\n            "+_vm._s(_vm.notificationProcessed(notification, 'user', 'atlas_processed')
                ? 'Sim'
                : 'Não')+"\n          ")]),_c('td',[_vm._v("\n            "+_vm._s(_vm.has_log(notification, 'user')
                ? _vm.formatDate(notification.log.user.log_datetime)
                : 'Não notificado')+"\n          ")]),_c('td',[_vm._v("\n            "+_vm._s(notification.created_at)+"\n          ")])])}),0)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }