var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, PropSync, Watch } from 'vue-property-decorator';
import SentNotificationModal from '@/modules/admin/components/SentNotificationModal/Modal.vue';
import { connect } from '@/overmind';
let SentNotificationModalContainer = class SentNotificationModalContainer extends Vue {
    constructor() {
        super(...arguments);
        this.notifications = {
            data: {
                value: {
                    notifications: [],
                },
            },
        };
    }
    async getSentNotification() {
        // @ts-ignore
        return await this.actions.allNotifications();
    }
    async onOpenModalChanged(newVal, oldVal) {
        if (newVal) {
            this.notifications = await this.getSentNotification();
            this.intervalId = setInterval(async () => {
                // @ts-ignore
                this.notifications.data.notifications = undefined;
                // @ts-ignore
                this.notifications = await this.getSentNotification();
            }, 1000 * 60 * 2);
        }
        else {
            clearInterval(this.intervalId);
        }
    }
};
__decorate([
    PropSync('open', { default: false })
], SentNotificationModalContainer.prototype, "showSentNotificationModal", void 0);
__decorate([
    Watch('open')
], SentNotificationModalContainer.prototype, "onOpenModalChanged", null);
SentNotificationModalContainer = __decorate([
    Component(connect(({ actions: { admin: actions } }) => ({
        actions: actions,
    }), {
        name: 'SentNotificationModalContainer',
        components: { SentNotificationModal },
    }))
], SentNotificationModalContainer);
export default SentNotificationModalContainer;
