var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop } from 'vue-property-decorator';
import UsersCreditsHistoryTabRow from './UsersCreditsHistoryTableRow.vue';
let UsersCreditsHistoryTab = class UsersCreditsHistoryTab extends Vue {
    getTotalExportations() {
        let total = 0;
        this.creditHistory.forEach(item => {
            if (item.type === 'export') {
                if (total === 0) {
                    total = item.spent;
                }
                else {
                    total += item.spent;
                }
            }
        });
        return total;
    }
    getTotalClicks() {
        let total = 0;
        this.creditHistory.forEach(item => {
            if (item.type === 'click') {
                if (total === 0) {
                    total = item.spent;
                }
                else {
                    total += item.spent;
                }
            }
        });
        return total;
    }
};
__decorate([
    Prop({ default: () => [] })
], UsersCreditsHistoryTab.prototype, "creditHistory", void 0);
UsersCreditsHistoryTab = __decorate([
    Component({
        components: {
            UsersCreditsHistoryTabRow,
        },
    })
], UsersCreditsHistoryTab);
export default UsersCreditsHistoryTab;
