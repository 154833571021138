var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, PropSync, Watch, Emit, } from 'vue-property-decorator';
import SubUsersTableRow from '@/modules/admin/components/SubUsersTableRow.vue';
import Modal from 'components/Modal.vue';
import Input from 'components/Input.vue';
let SubUsersModal = class SubUsersModal extends Vue {
    constructor() {
        super(...arguments);
        this.valueAllowedUsers = 0;
        this.height = 480;
        this.width = 720;
    }
    onstatusAllowedUsersChange(currVal, oldVal) {
        if (currVal === 'received') {
            this.$q.notify({
                message: 'Quantidade de sub-usuários atualizado com sucesso',
                type: 'success-user',
            });
        }
    }
    confirmEditUser(id, name, email, role) {
        this.$emit('confirm-edit-user', id, name, email, role);
    }
    updateAllowedUsers() {
        return this.valueAllowedUsers;
    }
};
__decorate([
    PropSync('open', { default: false })
], SubUsersModal.prototype, "showModal", void 0);
__decorate([
    Prop({ default: () => [] })
], SubUsersModal.prototype, "subUsers", void 0);
__decorate([
    Prop({ default: () => '' })
], SubUsersModal.prototype, "statusUpdatedSubUser", void 0);
__decorate([
    Prop({ default: () => '' })
], SubUsersModal.prototype, "statusAllowedUsers", void 0);
__decorate([
    Prop({ default: 0 })
], SubUsersModal.prototype, "allowedUsers", void 0);
__decorate([
    Watch('statusAllowedUsers')
], SubUsersModal.prototype, "onstatusAllowedUsersChange", null);
__decorate([
    Emit('updateAllowedUsers')
], SubUsersModal.prototype, "updateAllowedUsers", null);
SubUsersModal = __decorate([
    Component({
        name: 'SubUsersModal',
        components: { Modal, Input, SubUsersTableRow },
    })
], SubUsersModal);
export default SubUsersModal;
