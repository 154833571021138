var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import { personNameInitials, removeHashRevokeUserEmail, } from '@/shared/utils/helpers';
let UsersListItem = class UsersListItem extends Vue {
    constructor() {
        super(...arguments);
        this.operation = {
            add_credit: 'Adição',
            remove_credit: 'Remoção',
            invalidate_unlimited_credit: 'Remoção',
        };
        this.status = {
            active: 'Ativo',
            inactive: 'Inativo',
        };
    }
    userNameInitials(name) {
        return personNameInitials(name);
    }
    get userEmail() {
        if (this.user.deletedAt)
            return removeHashRevokeUserEmail(this.user.email);
        return this.user.email;
    }
    get userLevelName() {
        switch (this.user.role) {
            case 'admin':
                return 'Administrador';
            case 'owner':
                return 'Proprietário';
            case 'manager':
                return 'Gerente';
            case 'coordinator':
                return 'Coordenador';
            case 'prospector':
                return 'Prospector';
            case 'customer_success':
                return 'CS';
            default:
                return 'Desconhecido';
        }
    }
    get userStatus() {
        return this.user.deletedAt ? this.status.inactive : this.status.active;
    }
    openDetails() {
        this.$emit('click', this.user);
    }
};
__decorate([
    Prop({ default: () => { } })
], UsersListItem.prototype, "user", void 0);
__decorate([
    Prop({ default: () => [] })
], UsersListItem.prototype, "columns", void 0);
UsersListItem = __decorate([
    Component
], UsersListItem);
export default UsersListItem;
