var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, PropSync } from 'vue-property-decorator';
import Modal from 'components/Modal.vue';
import Input from 'components/Input.vue';
let PlanListModal = class PlanListModal extends Vue {
    constructor() {
        super(...arguments);
        this.plans = [];
        this.waiting = true;
    }
    async mounted() {
        try {
            const result = await Vue.axios.get('/account_plan/list_available_plans?group_by=none');
            this.waiting = false;
            this.plans = result.data.sort((a, b) => (a.name > b.name ? 1 : -1));
        }
        catch (e) {
            console.log(e);
        }
    }
};
__decorate([
    PropSync('open', { default: false })
], PlanListModal.prototype, "showModal", void 0);
PlanListModal = __decorate([
    Component({
        name: 'PlanListModal',
        components: { Modal, Input },
    })
], PlanListModal);
export default PlanListModal;
