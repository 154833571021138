var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import Input from '@/components/Input.vue';
import { overmind } from '@/overmind';
let AccountManagementAccountInfoForm = class AccountManagementAccountInfoForm extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.newPlanName = this.planName;
        this.newEmail = this.email;
        this.newPhoneNumber = this.phoneNumber;
        this.newCountry = this.country;
        this.newState = this.state;
        this.newCity = this.city;
        this.newNeighborhood = this.neighborhood;
        this.newStreet = this.street;
        this.newComplementalAddress = this.complementalAddress;
        this.newSuperlogicaAccountId = this.superlogicaAccountId || '';
        this.newStripeCustomerId = this.stripeCustomerId || '';
        this.newAgileCustomerId = this.agileCustomerId || '';
        this.newAccountType = this.accountType || '';
        this.newSkipOriginCheck = this.skipOriginCheck;
        this.onlyInDatabase = false;
        this.enableAccountID = false;
        this.confirm = false;
        this.alertRefButton = '';
    }
    confirmEditButton() {
        this.enableAccountID = !this.enableAccountID;
        const ref = this.alertRefButton;
        setTimeout(() => {
            // @ts-ignore TS2349: property inexistent
            this.$nextTick(() => this.$refs[ref].focus());
        }, 100);
    }
    handleAlert(ref) {
        this.alertRefButton = ref;
        if (this.enableAccountID) {
            this.enableAccountID = false;
        }
        else {
            this.confirm = true;
        }
    }
    get textAlert() {
        if (this.alertRefButton == 'superlogicaAccID') {
            return `
        Essa informação é extremamente delicada e alterá-la pode prejudicar o 
        usuário, deseja continuar?
      `;
        }
        else {
            return `
        Adicionar um id de customer do stripe vai fazer com que todas as operações
        de assinatura e pagamento do usuário passem para o stripe, deseja continuar?
      `;
        }
    }
    get ownerName() {
        return this.account.owner ? this.account.owner.name : 'Sem proprietário';
    }
    get planName() {
        return this.account.planName;
    }
    get email() {
        return this.account.preferences ? this.account.preferences.email : '';
    }
    get country() {
        return this.account.preferences ? this.account.preferences.country : '';
    }
    get state() {
        return this.account.preferences ? this.account.preferences.state : '';
    }
    get city() {
        return this.account.preferences ? this.account.preferences.city : '';
    }
    get neighborhood() {
        return this.account.preferences ? this.account.preferences.neighborhood : '';
    }
    get street() {
        return this.account.preferences
            ? this.account.preferences.streetAddress
            : '';
    }
    get zipcode() {
        return this.account.preferences ? this.account.preferences.zipcode : '';
    }
    get phoneNumber() {
        return this.account.preferences ? this.account.preferences.phoneNumber : '';
    }
    get complementalAddress() {
        return this.account.preferences
            ? this.account.preferences.complementalAddress
            : '';
    }
    get superlogicaAccountId() {
        return this.account.preferences
            ? this.account.preferences.superlogicaAccountId
            : '';
    }
    get stripeCustomerId() {
        return this.account.preferences ? this.account.preferences.customerId : '';
    }
    get agileCustomerId() {
        return this.account.preferences
            ? this.account.preferences.agileCustomerId
            : '';
    }
    get accountType() {
        return this.account.preferences ? this.account.preferences.accountType : '';
    }
    get skipOriginCheck() {
        return this.account.preferences
            ? this.account.preferences.skipOriginCheck
            : false;
    }
    async confirmChanges() {
        this.loading = true;
        const payload = {
            plan_name: this.newPlanName,
            email: this.newEmail,
            phone_number: this.newPhoneNumber,
            country: this.newCountry,
            state: this.newState,
            city: this.newCity,
            street_address: this.newStreet,
            neighborhood: this.newNeighborhood,
            superlogica_account_id: this.newSuperlogicaAccountId,
            customer_id: this.newStripeCustomerId,
            agile_customer_id: this.newAgileCustomerId,
            account_type: this.newAccountType,
            skip_origin_check: this.newSkipOriginCheck,
            only_in_database: this.onlyInDatabase,
        };
        this.loading = true;
        await overmind.actions.admin.updateUserAccount({
            accountId: this.account.id,
            preferences: payload,
        });
        this.loading = false;
    }
};
__decorate([
    Prop({ default: () => { } })
], AccountManagementAccountInfoForm.prototype, "account", void 0);
AccountManagementAccountInfoForm = __decorate([
    Component({
        components: {
            Input,
        },
    })
], AccountManagementAccountInfoForm);
export default AccountManagementAccountInfoForm;
