var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import AuditsDetail from '@/modules/admin/containers/AccountManagementModal/AccountManagementTabs/AccountManagementAuditsTab/CreditsTab/AuditsDetail.container.vue';
import ConsumptionsDetail from '@/modules/admin/containers/AccountManagementModal/AccountManagementTabs/AccountManagementCreditsConsumptionTab/CreditsHistoryDetails.container.vue';
import UsersDetail from '@/modules/admin/containers/AccountManagementModal/AccountManagementTabs/AccountManagementUsersTab/UsersDetail.container.vue';
let AccountManagementRightDrawer = class AccountManagementRightDrawer extends Vue {
};
__decorate([
    Prop({ default: '' })
], AccountManagementRightDrawer.prototype, "component", void 0);
AccountManagementRightDrawer = __decorate([
    Component({
        components: {
            AuditsDetail,
            UsersDetail,
            ConsumptionsDetail,
        },
    })
], AccountManagementRightDrawer);
export default AccountManagementRightDrawer;
