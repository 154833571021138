var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, PropSync } from 'vue-property-decorator';
import ReactivationTokenModal from '@/modules/admin/components/ReactivationToken/Modal.vue';
import { connect } from '@/overmind';
let AddReactivationTokenModalContainer = class AddReactivationTokenModalContainer extends Vue {
    async createReactivationToken(token, credits) {
        // @ts-ignore
        return await this.actions.createReactivationToken({
            token: token,
            credits: credits,
        });
    }
};
__decorate([
    PropSync('open', { default: false })
], AddReactivationTokenModalContainer.prototype, "showAddReactivationTokenModal", void 0);
AddReactivationTokenModalContainer = __decorate([
    Component(connect(({ actions: { admin: actions } }) => ({
        actions: actions,
    }), {
        name: 'AddReactivationTokenModalContainer',
        components: { ReactivationTokenModal },
    }))
], AddReactivationTokenModalContainer);
export default AddReactivationTokenModalContainer;
