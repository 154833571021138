var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import { personNameInitials, abbreviateName } from '@/shared/utils/helpers';
import { removeHashRevokeUserEmail } from '@/shared/utils/helpers';
import { copyToClipboard } from 'quasar';
import Tooltip from '@/components/Tooltip.vue';
import IconChecked from '@/components/Icons/IconChecked.vue';
import IconMessage from '@/components/Icons/IconMessage.vue';
import IconEmail from '@/components/Icons/IconEmail.vue';
import IconBlueSuccess from '@/components/Icons/icon-blue-success.vue';
import IconCancel from '@/components/Icons/IconCancel.vue';
import IconFilledWarning from '@/components/Icons/IconFilledWarning.vue';
let SurveyListItem = class SurveyListItem extends Vue {
    constructor() {
        super(...arguments);
        this.confirmRemoveVoucher = false;
        this.clickCopy = false;
    }
    openDetails() {
        return { id: this.survey._id, open: true };
    }
    userNameInitials(name) {
        return personNameInitials(name);
    }
    handleDate() {
        const formatDate = !this.allowedVoucher
            ? this.survey.created_at.slice(0, 10).split('-')
            : this.userWithVoucher.createdAt.slice(0, 10).split('-');
        return `${formatDate[2]}/${formatDate[1]}/${formatDate[0]}`;
    }
    handleEmail(email) {
        return removeHashRevokeUserEmail(email);
    }
    handleComment() {
        return this.survey.comment ? this.survey.comment : 'sem comentários';
    }
    confirmRemove(value) {
        return (this.confirmRemoveVoucher = value);
    }
    removeVoucher() {
        this.confirmRemoveVoucher = false;
        return {
            allowedVoucher: false,
            action: 'remove',
            surveyId: this.userWithVoucher._id,
        };
    }
    handleRole(role) {
        switch (role) {
            case 2:
                return 'owner';
            case 3:
                return 'manager';
            case 4:
                return 'coordinator';
            default:
                return 'prospector';
        }
    }
    copyTextItem(value) {
        this.clickCopy = true;
        copyToClipboard(value)
            .then(() => {
            setTimeout(() => {
                this.clickCopy = false;
            }, 1000);
        })
            .catch(() => {
            setTimeout(() => {
                this.clickCopy = false;
            }, 1000);
        });
    }
    handleAbbrName(name) {
        return abbreviateName(name);
    }
};
__decorate([
    Prop({ default: () => { } })
], SurveyListItem.prototype, "survey", void 0);
__decorate([
    Prop({ default: () => { } })
], SurveyListItem.prototype, "userWithVoucher", void 0);
__decorate([
    Prop({ default: true })
], SurveyListItem.prototype, "clickable", void 0);
__decorate([
    Prop({ default: false })
], SurveyListItem.prototype, "isSelect", void 0);
__decorate([
    Prop({ default: false })
], SurveyListItem.prototype, "allowedVoucher", void 0);
__decorate([
    Emit('openDetails')
], SurveyListItem.prototype, "openDetails", null);
__decorate([
    Emit('removeVoucher')
], SurveyListItem.prototype, "removeVoucher", null);
SurveyListItem = __decorate([
    Component({
        name: 'SurveyListItem',
        components: {
            Tooltip,
            IconChecked,
            IconMessage,
            IconEmail,
            IconCancel,
            IconFilledWarning,
            IconBlueSuccess,
        },
    })
], SurveyListItem);
export default SurveyListItem;
