var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import InnerLoading from '@/components/InnerLoading.vue';
import Input from '@/components/Input.vue';
import Modal from '@/components/Modal.vue';
import AccountManagementNavbarContainer from '@/modules/admin/containers/AccountManagementModal/AccountManagementNavbar.container.vue';
import AccountManagementMainContainer from '@/modules/admin/containers/AccountManagementModal/AccountManagementMain.container.vue';
import AccountManagementRightDrawer from '@/modules/admin/components/AccountManagementModal/AccountManagementRightDrawer.vue';
let AccountManagementModal = class AccountManagementModal extends Vue {
    get getWidthModal() {
        return this.$q.screen.width === 1920
            ? this.$q.screen.width / 1.35
            : this.$q.screen.xl
                ? this.$q.screen.width / 1.5
                : this.$q.screen.lg
                    ? this.$q.screen.width / 1.25
                    : this.$q.screen.md
                        ? this.$q.screen.width / 1.0
                        : this.$q.screen.lt.md
                            ? this.$q.screen.width / 1.1
                            : this.$q.screen.width;
    }
    get getHeightModal() {
        return this.$q.screen.height >= 800
            ? this.$q.screen.height / 1.2
            : this.$q.screen.height / 1.15;
    }
    closeModal() {
        this.showModal = false;
        this.$emit('closeModal');
    }
    closeRightDrawer() {
        this.$emit('closeRightDrawer', true);
    }
};
__decorate([
    Prop({ default: '' })
], AccountManagementModal.prototype, "rightDrawerContent", void 0);
__decorate([
    PropSync('open', { default: false })
], AccountManagementModal.prototype, "showModal", void 0);
AccountManagementModal = __decorate([
    Component({
        name: 'AccountManagementModal',
        components: {
            Modal,
            Input,
            InnerLoading,
            AccountManagementNavbarContainer,
            AccountManagementMainContainer,
            AccountManagementRightDrawer,
        },
    })
], AccountManagementModal);
export default AccountManagementModal;
