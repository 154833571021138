var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import AdminHeader from '@/modules/admin/components/AdminHeader.vue';
import UsersTable from '@/modules/admin/components/UsersTable.vue';
import AdminEmpty from '@/modules/admin/components/AdminEmpty.vue';
import UsersTableSkeleton from '@/modules/admin/components/UsersTableSkeleton.vue';
import Pagination from 'components/Pagination.vue';
import { connect } from '@/overmind';
import { isEmpty } from 'lodash';
import { disableJivo } from '@/shared/utils/helpers';
let AdminContainer = class AdminContainer extends Vue {
    constructor() {
        super(...arguments);
        this.searchTerm = '';
        this.userRoles = [];
        this.usersStatus = [];
        this.userTypeAccount = [];
        this.usersCategory = [];
        this.usersCs = [];
        this.firstCreditAge = [];
        this.csAndAdmins = [];
        this.pageNumber = 1;
    }
    async created() {
        // @ts-ignore
        await this.initData();
    }
    async mounted() {
        // @ts-ignore
        disableJivo(undefined, true);
        await this.setCsAndAdmins();
    }
    showAccountCredits(user) {
        // @ts-ignore
        this.actions.selectUserDataTable(user);
        // @ts-ignore
        this.actions.setToAccounts(user);
        // @ts-ignore
        this.actions.setCurrentAccountId(user.accountId);
        // @ts-ignore
        this.actions.setAccountModalTab('credits');
    }
    showAccountUsers(user) {
        // @ts-ignore
        this.actions.selectUserDataTable(user);
        // @ts-ignore
        this.actions.setToAccounts(user);
        // @ts-ignore
        this.actions.setCurrentAccountId(user.accountId);
        // @ts-ignore
        this.actions.setAccountModalTab('users');
    }
    showAccountInfo(user) {
        // @ts-ignore
        this.actions.selectUserDataTable(user);
        // @ts-ignore
        this.actions.setToAccounts(user);
        // @ts-ignore
        this.actions.setCurrentAccountId(user.accountId);
        // @ts-ignore
        this.actions.setAccountModalTab('account-info');
    }
    setPageNumber(pageNumber) {
        if (pageNumber !== this.pageNumber) {
            this.pageNumber = pageNumber;
            // @ts-ignore
            this.actions.getUsers({
                page: pageNumber,
                name_or_email: this.searchTerm,
                roles: this.userRoles,
                type_account: this.userTypeAccount,
                status: this.usersStatus,
                category: this.usersCategory,
                cs: this.usersCs,
                first_credit_age: this.firstCreditAge,
            });
        }
    }
    get emptyUsers() {
        // @ts-ignore
        return isEmpty(this.filteredUsers);
    }
    getCsAndAdmins() {
        return this.csAndAdmins;
    }
    confirmRevoke(user) {
        // @ts-ignore
        this.actions.revokeUser({ userId: user.id });
    }
    confirmActive(user) {
        // @ts-ignore
        this.actions.enableUser({ userId: user.id });
    }
    async searchUsersByNameOrEmail(term) {
        this.searchTerm = term;
        // @ts-ignore TS2349: property inexistent
        this.actions.getUsers({
            page: 1,
            name_or_email: this.searchTerm,
            roles: this.userRoles,
            type_account: this.userTypeAccount,
            status: this.usersStatus,
            category: this.usersCategory,
            cs: this.usersCs,
            first_credit_age: this.firstCreditAge,
        });
    }
    async searchUsersbyCheckbox(event) {
        await this.buildFilters(event);
        // @ts-ignore TS2349: property inexistent
        this.actions.getUsers({
            page: 1,
            name_or_email: this.searchTerm,
            roles: this.userRoles,
            type_account: this.userTypeAccount,
            status: this.usersStatus,
            category: this.usersCategory,
            cs: this.usersCs,
            first_credit_age: this.firstCreditAge,
        });
    }
    async buildFilters(filters) {
        filters.forEach(element => {
            if (element.label === 'Tipo da Conta') {
                this.userTypeAccount = [];
                element.items.forEach(item => {
                    if (item.isSelected) {
                        this.userTypeAccount.push(item.value);
                    }
                });
            }
            if (element.label === 'Tipo do Usuário') {
                this.userRoles = [];
                element.items.forEach(item => {
                    if (item.isSelected) {
                        this.userRoles.push(item.value);
                    }
                });
            }
            if (element.label === 'Status do Usuário') {
                this.usersStatus = [];
                element.items.forEach(item => {
                    if (item.isSelected) {
                        this.usersStatus.push(item.value);
                    }
                });
            }
            if (element.label == 'Categoria do Usuário') {
                this.usersCategory = [];
                element.items.forEach(item => {
                    if (item.isSelected) {
                        this.usersCategory.push(item.value);
                    }
                });
            }
            if (element.label == 'CS do Usuário') {
                this.usersCs = [];
                element.items.forEach(item => {
                    if (item.isSelected) {
                        this.usersCs.push(item.value);
                    }
                });
            }
            if (element.label == 'Tempo de assinatura') {
                this.firstCreditAge = [];
                element.items.forEach(item => {
                    if (item.isSelected) {
                        this.firstCreditAge.push(item.value);
                    }
                });
            }
        });
    }
    async setCsAndAdmins() {
        // @ts-ignore
        const csAndAdmins = await this.actions.getCsAndAdmins();
        this.csAndAdmins = csAndAdmins.data.map(item => {
            return { value: item._id.$oid, label: `${item.name} (${item.email})` };
        });
    }
    async assignCsToAccount(params) {
        // @ts-ignore
        await this.actions.assignCsToAccount(params);
    }
    async removeCsFromAccount(params) {
        // @ts-ignore
        await this.actions.removeCsFromAccount(params);
    }
    async initData() {
        // @ts-ignore TS2349: property inexistent
        await this.actions.getUsers({});
        await this.setCsAndAdmins();
    }
    calculatePercentage(total, value) {
        return Math.round((value * 100) / total);
    }
};
AdminContainer = __decorate([
    Component(connect(({ state: { admin: state }, actions: { admin: actions } }) => ({
        state: state,
        users: state.users,
        totalUsers: state.totalUsers,
        filteredUsers: state.filteredUsers,
        status: state.status,
        totalPages: state.totalPages,
        totalClients: state.totalClients,
        totalPerCategory: state.totalPerCategory,
        percentage: state.percentage,
        page: state.page,
        actions: actions,
    }), {
        name: 'AdminContainer',
        components: {
            AdminHeader,
            UsersTable,
            AdminEmpty,
            Pagination,
            UsersTableSkeleton,
        },
    }))
], AdminContainer);
export default AdminContainer;
