var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { getDateTimeByFormat, personNameInitials, removeHashRevokeUserEmail, } from '@/shared/utils/helpers';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Input from '@/components/Input.vue';
let UsersDetail = class UsersDetail extends Vue {
    constructor() {
        super(...arguments);
        this.edittingName = false;
        this.edittingEmail = false;
        this.edittingStatus = false;
        this.edittingRole = false;
        this.openInputFile = false;
        this.imgFile = null;
        this.tes = null;
        this.roleOptions = [
            { label: 'Gerente', value: 'manager' },
            { label: 'Coordenador', value: 'coordinator' },
            { label: 'Prospector', value: 'prospector' },
            { label: 'CS', value: 'customer_success' },
        ];
        this.statusOptions = [
            { label: 'Ativo', value: 'active' },
            { label: 'Inativo', value: 'inactive' },
        ];
    }
    get authorNameInitials() {
        return (name) => personNameInitials(name);
    }
    userEmail() {
        if (this.user.deletedAt)
            return removeHashRevokeUserEmail(this.user.email);
        return this.user.email;
    }
    updateImg() {
        const file = this.imgFile[0];
        let formData = new FormData();
        let fileReader = new FileReader();
        fileReader.onload = e => {
            formData.append('image', file, file.name);
            formData.get('image');
        };
        fileReader.readAsDataURL(this.imgFile[0]);
        fileReader.onload = () => {
            this.$emit('updateImg', fileReader.result);
        };
        this.openInputFile = false;
        this.imgFile = null;
    }
    get userLevelName() {
        switch (this.user.role) {
            case 'admin':
                return 'Administrador';
            case 'owner':
                return 'Proprietário';
            case 'manager':
                return 'Gerente';
            case 'coordinator':
                return 'Coordenador';
            case 'prospector':
                return 'Prospector';
            case 'customer_success':
                return 'CS';
            default:
                return 'Desconhecido';
        }
    }
    userStatus() {
        return this.user.deletedAt ? 'Inativo' : 'Ativo';
    }
    get userCreatedAt() {
        if (this.user.createdAt) {
            return getDateTimeByFormat(new Date(this.user.createdAt), 'dddd, D [de] MMMM [de] YYYY');
        }
        return '';
    }
    get userUpdatedAt() {
        if (this.user.updatedAt) {
            return getDateTimeByFormat(new Date(this.user.updatedAt), 'dddd, D [de] MMMM [de] YYYY');
        }
        return '';
    }
    hasClicks(consumption) {
        return consumption && consumption.type === 'click';
    }
    hasUnlimitedClicks(consumption) {
        return consumption && consumption.type === 'unlimited_click';
    }
    hasExports(consumption) {
        return consumption && consumption.type === 'export';
    }
    changeName(evt) {
        this.edittingName = false;
        this.$emit('changeName', evt.target.value.trim());
    }
    changeEmail(evt) {
        this.edittingEmail = false;
        this.$emit('changeEmail', evt.target.value.trim());
    }
    changeRole(item) {
        this.edittingRole = false;
        this.$emit('changeRole', item);
    }
    changeStatus(item) {
        this.edittingStatus = false;
        this.$emit('changeStatus', item);
    }
};
__decorate([
    Prop({ default: () => { } })
], UsersDetail.prototype, "user", void 0);
__decorate([
    Prop({ default: () => { } })
], UsersDetail.prototype, "consumptions", void 0);
__decorate([
    Prop({ default: false })
], UsersDetail.prototype, "updatingName", void 0);
__decorate([
    Prop({ default: false })
], UsersDetail.prototype, "updatingEmail", void 0);
__decorate([
    Prop({ default: false })
], UsersDetail.prototype, "updatingRole", void 0);
__decorate([
    Prop({ default: false })
], UsersDetail.prototype, "updatingStatus", void 0);
__decorate([
    Prop({ default: false })
], UsersDetail.prototype, "updatingImg", void 0);
UsersDetail = __decorate([
    Component({
        components: {
            Input,
        },
    })
], UsersDetail);
export default UsersDetail;
