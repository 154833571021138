var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import UsersTableRow from '@/modules/admin/components/UsersTableRow.vue';
import Tooltip from 'src/components/Tooltip.vue';
let UsersTable = class UsersTable extends Vue {
    assignCsToAccount(params) {
        return params;
    }
    removeCsFromAccount(params) {
        return params;
    }
    confirmRevoke(user) {
        return user;
    }
    confirmActive(user) {
        return user;
    }
};
__decorate([
    Prop({ default: () => [] })
], UsersTable.prototype, "users", void 0);
__decorate([
    Prop({ default: () => [] })
], UsersTable.prototype, "csAndAdmins", void 0);
__decorate([
    Emit('assignCsToAccount')
], UsersTable.prototype, "assignCsToAccount", null);
__decorate([
    Emit('removeCsFromAccount')
], UsersTable.prototype, "removeCsFromAccount", null);
__decorate([
    Emit('confirmRevoke')
], UsersTable.prototype, "confirmRevoke", null);
__decorate([
    Emit('confirmActive')
], UsersTable.prototype, "confirmActive", null);
UsersTable = __decorate([
    Component({
        name: 'UsersTable',
        components: { UsersTableRow, Tooltip },
    })
], UsersTable);
export default UsersTable;
