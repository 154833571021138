var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import AccountManagementSubscriptions from '@/modules/admin/components/AccountManagementModal/AccountManagementSubscriptionsTab/AccountManagementSubscriptionsTab.vue';
import { connect } from '@/overmind';
import { Component, Vue } from 'vue-property-decorator';
let AccountManagementSubscriptionsTabContainer = class AccountManagementSubscriptionsTabContainer extends Vue {
    constructor() {
        super(...arguments);
        this.additionals = [];
        this.availableAdditionals = [];
    }
    async mounted() {
        // @ts-ignore
        this.additionals = await this.getAccountAdditionals();
    }
    async getAccountAdditionals() {
        // @ts-ignore
        return await this.actions.accountAdditionals(
        // @ts-ignore
        this.selectedUserTable.accountId);
    }
    async editAdditional(additional) {
        // @ts-ignore
        if (additional.status != 'demo')
            additional.expiration_date = null;
        // @ts-ignore
        await this.actions.updateAdditionalSubscription(additional);
        // @ts-ignore
        this.additionals = await this.getAccountAdditionals();
    }
    async extendDemo(name, date) {
        // @ts-ignore
        await this.actions.extendDemoSubscription({
            // @ts-ignore
            email: this.selectedUserTable.accountOwner.email,
            name: name,
            expiration_date: date,
        });
    }
    async deleteAdditional(additional_id) {
        // @ts-ignore
        await this.actions.deleteAdditionalSubscription(additional_id);
        // @ts-ignore
        this.additionals = await this.getAccountAdditionals();
    }
    async listAvailableAdditionals() {
        // @ts-ignore
        this.availableAdditionals = await this.actions.listAvailableAdditionals();
    }
    async addAdditional(item_id) {
        // @ts-ignore
        await this.actions.createCustomAdditionalSubscription({
            item_id: item_id,
            // @ts-ignore
            account_id: this.selectedUserTable.accountId,
            status: 'demo',
        });
        // @ts-ignore
        this.additionals = await this.getAccountAdditionals();
    }
};
AccountManagementSubscriptionsTabContainer = __decorate([
    Component(connect(({ state: { admin: state }, actions: { shop: actions } }) => ({
        selectedUserTable: state.selectedUserTable,
        actions: actions,
    }), {
        components: {
            AccountManagementSubscriptions,
        },
    }))
], AccountManagementSubscriptionsTabContainer);
export default AccountManagementSubscriptionsTabContainer;
