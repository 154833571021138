var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import UserCreditsModalContainer from '@/modules/admin/containers/UserCreditsModal.container.vue';
import AccountPreferencesModal from '@/modules/admin/containers/AccountPreferencesModal.container.vue';
import UserRevokeModal from '@/modules/admin/components/UserRevokeModal.vue';
import UserEnableModal from '@/modules/admin/components/UserEnableModal.vue';
import Alert from '@/components/Alert.vue';
import SubUsersModalContainer from '@/modules/admin/containers/SubUsersModal.container.vue';
import AccountModalContainer from '@/modules/admin/containers/AccountManagementModal/AccountManagementModal.container.vue';
import { removeHashRevokeUserEmail } from '@/shared/utils/helpers';
let UsersTableRow = class UsersTableRow extends Vue {
    constructor() {
        super(...arguments);
        this.showCreditsModal = false;
        this.showSubUsersModal = false;
        this.showUserRevokeModal = false;
        this.showEnableUserModal = false;
        this.showAccountPreferencesModal = false;
        this.showAlert = false;
        this.showAccountModal = false;
        this.csUser = this.user.cs;
        this.filteredCsAndAdmins = this.csAndAdmins;
        this.warningMessage = 'O usuário selecionado é do tipo <b>Owner</b>, ao desativá-lo todos os usuários da conta também serão desabilitados automaticamente! Tem certeza que deseja continuar?';
    }
    openAccountModal(emitMessage) {
        if (this.$parent) {
            this.$parent.$emit(emitMessage, this.user);
            this.showAccountModal = true;
        }
    }
    get removeHashRevokeUserEmail() {
        //Utilizar gm no regex flag
        return removeHashRevokeUserEmail(this.user.email);
    }
    handleAccountLabel() {
        const account_types = {
            free: 'Teste',
            undefined: 'Indefinido',
        };
        let account_type = account_types[this.user.accountType]
            ? account_types[this.user.accountType]
            : 'Cliente';
        return account_type;
    }
    handleStatusLabel() {
        return this.user.userStatus === 'active' ? 'Habilitado' : 'Desabilitado';
    }
    handleAccountStatusLabel() {
        return ['active', 'suspended', 'migrated'].includes(this.user.accountType)
            ? 'Ativo'
            : 'Inativo';
    }
    confirmRevoke() {
        return this.user;
    }
    confirmActive() {
        return this.user;
    }
    handleDisableUser() {
        if (this.user.role === 'owner') {
            this.showAlert = true;
        }
        this.showUserRevokeModal = true;
    }
    handleCancelDisableUser() {
        this.showAlert = false;
        this.showUserRevokeModal = false;
    }
    searchForCsName(val, update, abort) {
        update(() => {
            if (val === '') {
                this.filteredCsAndAdmins = this.csAndAdmins;
            }
            else {
                const needle = val.toLowerCase();
                this.filteredCsAndAdmins = this.csAndAdmins.filter(
                // @ts-ignore
                v => v.label.toLowerCase().indexOf(needle) > -1);
            }
        });
    }
    assignCsToAccount(params) {
        return params;
    }
    removeCsFromAccount(params) {
        return params;
    }
    onCsUserChanged(val, oldVal) {
        if (val)
            this.$emit('assignCsToAccount', {
                account_id: this.user.accountId,
                cs_user_id: val.value,
            });
        else
            this.$emit('removeCsFromAccount', this.user.accountId);
    }
};
__decorate([
    Prop({ default: () => { } })
], UsersTableRow.prototype, "user", void 0);
__decorate([
    Prop({ default: () => [] })
], UsersTableRow.prototype, "csAndAdmins", void 0);
__decorate([
    Emit('confirmRevoke')
], UsersTableRow.prototype, "confirmRevoke", null);
__decorate([
    Emit('confirmActive')
], UsersTableRow.prototype, "confirmActive", null);
__decorate([
    Emit('assignCsToAccount')
], UsersTableRow.prototype, "assignCsToAccount", null);
__decorate([
    Emit('removeCsFromAccount')
], UsersTableRow.prototype, "removeCsFromAccount", null);
__decorate([
    Watch('csUser')
], UsersTableRow.prototype, "onCsUserChanged", null);
UsersTableRow = __decorate([
    Component({
        name: 'UsersTableRow',
        components: {
            UserRevokeModal,
            SubUsersModalContainer,
            UserCreditsModalContainer,
            UserEnableModal,
            AccountPreferencesModal,
            AccountModalContainer,
            Alert,
        },
    })
], UsersTableRow);
export default UsersTableRow;
