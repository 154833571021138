var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, PropSync } from 'vue-property-decorator';
import ChurnDataModal from '@/modules/admin/components/ChurnDataModal.vue';
import { connect } from '@/overmind';
let ChurnDataContainer = class ChurnDataContainer extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
    }
    async sendEmails({ emails, rangeDate }) {
        this.loading = true;
        try {
            //@ts-ignore
            const result = await this.actions.generateSpreadsheet({
                emails,
                rangeDate,
            });
            const download = await Vue.axios(`user/generate_churn_data_sheet/download/${result}`, {
                method: 'get',
            });
            const blob = new Blob([download.data], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = result;
            document.body.appendChild(link);
            link.click();
            this.loading = false;
            window.URL.revokeObjectURL(url);
            await Vue.axios(`user/generate_churn_data_sheet/delete_file/${result}`, {
                method: 'get',
            });
        }
        catch (err) {
            this.loading = false;
        }
    }
};
__decorate([
    PropSync('open', { default: false })
], ChurnDataContainer.prototype, "showChurnData", void 0);
ChurnDataContainer = __decorate([
    Component(connect(({ actions: { admin: actions } }) => ({
        actions: actions,
    }), {
        name: 'ChurnDataContainer',
        components: { ChurnDataModal },
    }))
], ChurnDataContainer);
export default ChurnDataContainer;
