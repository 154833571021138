var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, PropSync, Emit } from 'vue-property-decorator';
import Modal from 'components/Modal.vue';
import Input from 'components/Input.vue';
let UserRevokeModal = class UserRevokeModal extends Vue {
    confirmRevoke() {
        this.showModal = false;
        return this.user;
    }
};
__decorate([
    PropSync('open', { default: false })
], UserRevokeModal.prototype, "showModal", void 0);
__decorate([
    Prop({ default: () => { } })
], UserRevokeModal.prototype, "user", void 0);
__decorate([
    Prop({ default: '' })
], UserRevokeModal.prototype, "emailUser", void 0);
__decorate([
    Emit('confirmRevoke')
], UserRevokeModal.prototype, "confirmRevoke", null);
UserRevokeModal = __decorate([
    Component({
        name: 'UserRevokeModal',
        components: { Modal, Input },
    })
], UserRevokeModal);
export default UserRevokeModal;
