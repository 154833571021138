var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, PropSync } from 'vue-property-decorator';
import OnboardingAdminModal from '@/modules/admin/components/Onboarding/OnboardingModal.vue';
import { connect } from '@/overmind';
import { date } from 'quasar';
let OnboardingAdminModalContainer = class OnboardingAdminModalContainer extends Vue {
    async mounted() {
        // @ts-ignore TS2349: property inexistent
        await this.actionsUsers.getUserMe();
    }
    async getAllOnboardings({ init_date, end_date, onboardingStatus, page = 1, name_or_email, }) {
        // @ts-ignore TS2349: property inexistent
        await this.actionsAdmin.getAllOnBoardings({
            init_date,
            end_date,
            onboardingStatus,
            page,
            name_or_email,
        });
    }
    async setAllListOnboardingResponsible() {
        // @ts-ignore TS2349: property inexistent
        await this.actionsAdmin.setAllListOnboardingResponsible();
    }
    async saveConfirmation({ emailUser, optionSelected, responsibleSelectedCsMember, onboardingId, createOnboarding, }) {
        // @ts-ignore TS2349: property inexistent
        const result = await this.actionsAdmin.saveOnboardingConfirmation({
            optionSelected,
            responsibleSelectedCsMember,
            onboardingId,
        });
        const { begin, end } = this.handleDate();
        if (result) {
            // @ts-ignore TS2349: property inexistent
            await this.actionsAdmin.getAllOnBoardings({
                init_date: begin,
                end_date: end,
            });
        }
    }
    handleDate() {
        const today = new Date();
        const begin = date.subtractFromDate(today, { days: 30 });
        const end = date.subtractFromDate(today, { days: 1 });
        begin.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 999);
        return { begin, end };
    }
    async listAllsersWithoutOnboardings({ listUsersWithoutOnboarding, nameOrEmail, page, }) {
        // @ts-ignore TS2349: property inexistent
        await this.actionsAdmin.listUsersWithoutOnboardings({
            listUsersWithoutOnboarding,
            nameOrEmail,
            page,
        });
    }
    async createOnboardingToUserWithoutOnboarding({ selectedOption, emailUser, responsibleSelectedCsMember, creationOfOnboardingByAdmin, }) {
        // @ts-ignore TS2349: property inexistent
        await this.actionsUsers.onboardingRegister({
            comment: '',
            selectedOption,
            emailUser,
            responsibleSelectedCsMember,
            creationOfOnboardingByAdmin,
        });
        // @ts-ignore TS2349: property inexistent
        await this.actionsAdmin.listUsersWithoutOnboardings({
            listUsersWithoutOnboarding: true,
            nameOrEmail: '',
            page: 1,
        });
    }
};
__decorate([
    PropSync('open', { default: false })
], OnboardingAdminModalContainer.prototype, "showOnboardModal", void 0);
OnboardingAdminModalContainer = __decorate([
    Component(connect(({ state: { admin: state }, actions }) => ({
        actionsAdmin: actions.admin,
        actionsUsers: actions.users,
        onboardingInformation: state.onboardingInformation,
        csUserList: state.csUserList,
        getAllOnBoardingsStatus: state.getAllOnBoardingsStatus,
        listUsersWithoutOnboardings: state.listUsersWithoutOnboardings,
        listUsersWithoutOnboardingsStatus: state.listUsersWithoutOnboardingsStatus,
        listUsersWithoutOnboardingsInfo: state.listUsersWithoutOnboardingsInfo,
    }), {
        name: 'OnboardingModalContainer',
        components: { OnboardingAdminModal },
    }))
], OnboardingAdminModalContainer);
export default OnboardingAdminModalContainer;
