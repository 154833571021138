var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Modal from '@/components/Modal.vue';
import Input from '@/components/Input.vue';
import OnboardingUserList from '@/modules/admin/components/Onboarding/OnboardUserList.vue';
import OnboardingUserDetails from '@/modules/admin/components/Onboarding/OnboardUserDetails.vue';
import DateRangePicker from '@/components/DateRangePicker.vue';
import Pagination from '@/components/Pagination.vue';
import InnerLoading from '@/components/InnerLoading.vue';
import { date } from 'quasar';
import { json } from 'overmind';
import { Component, Vue, PropSync, Prop, Emit } from 'vue-property-decorator';
import { GetAllOnBoardingsStatus, ListUsersWithoutOnboardingsStatus, } from '@/overmind/admin/state';
import { abbreviateName } from '@/shared/utils/helpers';
let OnboardingAdminModal = class OnboardingAdminModal extends Vue {
    constructor() {
        super(...arguments);
        this.searchTerm = '';
        this.toggleFilter = [
            { value: 'with_onboarding', slot: 'with_onboarding' },
            { value: 'no_onboarding', slot: 'no_onboarding' },
            { value: 'no_confirmation', slot: 'no_confirmation' },
        ];
        this.dateRangeLabel = 'Últimos 30 dias';
        this.dateRange = this.handleInitialDateRange();
        this.scheduleIsOpen = false;
        this.showUserDetails = false;
        this.userSelected = '';
        this.onboardingInformations = {};
        this.userWithoutOnboardingInformations = {};
        this.showFilters = false;
        this.currentPage = 1;
        this.usersNoOnboarding = true;
        this.usersWithOnboarding = true;
        this.filterClientBy = 'with_onboarding';
        this.usersWithoutOnboardingActive = false;
        this.usersNoOnboardingConfirmation = true;
        this.onboardingsStatusSelected = ['already_done', 'scheduled', 'refused'];
        this.onboardingStatus = [
            { isSelected: true, label: 'Já fez', value: 'already_done' },
            { isSelected: true, label: 'Agendado', value: 'scheduled' },
            { isSelected: true, label: 'Recusou', value: 'refused' },
        ];
        this.thumbStyleScrool = {
            right: '2px',
            borderRadius: '5px',
            backgroundColor: '#CFD8DC',
            width: '5px',
            opacity: 0.75,
        };
    }
    changeFilterClientBy() {
        this.currentPage = 1;
        // this.filterClientBy = !this.filterClientBy
        this.clearUserDetails();
        // this.handleClearData()
        if (this.filterClientBy != 'no_onboarding') {
            this.removeNoConfirmationStatus();
            if (this.filterClientBy == 'no_confirmation')
                this.onboardingsStatusSelected.push('no_confirmation');
            this.usersWithoutOnboardingActive = false;
            this.sendOnboardingData(this.dateRange[0], this.dateRange[1]);
            return;
        }
        this.usersWithoutOnboardingActive = true;
        this.listAllsersWithoutOnboardings({
            listUsersWithoutOnboarding: true,
            nameOrEmail: this.searchTerm || '',
            page: this.currentPage,
        });
    }
    removeNoConfirmationStatus() {
        this.onboardingsStatusSelected = this.onboardingsStatusSelected.filter(status => {
            if (status != 'no_confirmation')
                return status;
        });
    }
    handleClearData() {
        this.currentPage = 1;
        this.searchTerm = '';
        this.dateRange[0] = this.handleInitialDateRange()[0];
        this.dateRange[1] = this.handleInitialDateRange()[1];
        this.onboardingsStatusSelected = ['already_done', 'scheduled', 'refused'];
        this.onboardingStatus.forEach(o => {
            if (o.value !== 'no_confirmation')
                o.isSelected = true;
            else
                o.isSelected = false;
        });
    }
    isAbbreviateName(name) {
        if (name && name.length > 0)
            return abbreviateName(name);
        return name;
    }
    get isLoadingOnboardings() {
        return (this.getAllOnBoardingsStatus === GetAllOnBoardingsStatus.FETCHING ||
            this.listUsersWithoutOnboardingsStatus ===
                ListUsersWithoutOnboardingsStatus.RECEIVING);
    }
    totalPageListAllUsersWithoutOnboarding() {
        return this.listUsersWithoutOnboardingsInfo.total > 0 &&
            this.listUsersWithoutOnboardingsInfo.page > 0
            ? Math.ceil(this.listUsersWithoutOnboardingsInfo.total / 10)
            : 0;
    }
    hasNotOnboardings() {
        if (this.usersWithoutOnboardingActive) {
            return (this.listUsersWithoutOnboardingsStatus ===
                ListUsersWithoutOnboardingsStatus.RECEIVED &&
                this.listUsersWithoutOnboardings.length === 0);
        }
        else
            return (this.getAllOnBoardingsStatus === GetAllOnBoardingsStatus.RECEIVED &&
                this.onboardingInformation.data.length === 0);
    }
    listAllsersWithoutOnboardings({ listUsersWithoutOnboarding, nameOrEmail, page, }) {
        return { listUsersWithoutOnboarding, nameOrEmail, page };
    }
    setPageNumber(page) {
        this.currentPage = page;
        if (this.usersWithoutOnboardingActive) {
            const nameOrEmail = this.searchTerm || '';
            this.listAllsersWithoutOnboardings({
                listUsersWithoutOnboarding: true,
                nameOrEmail,
                page: this.currentPage,
            });
        }
        else
            this.sendOnboardingData(this.dateRange[0], this.dateRange[1]);
    }
    getOnboardings() {
        return this.usersWithoutOnboardingActive
            ? this.listUsersWithoutOnboardings
            : this.onboardingInformation.data;
    }
    getTotalOnboardings() {
        return this.usersWithoutOnboardingActive
            ? this.listUsersWithoutOnboardingsInfo.total
            : this.onboardingInformation.total_onboardings;
    }
    handleInitialDateRange() {
        let today = new Date();
        const begin = date.subtractFromDate(today, { days: 30 });
        begin.setHours(0, 0, 0, 0);
        const end = date.subtractFromDate(today, { days: 1 });
        end.setHours(23, 59, 59, 999);
        return [begin, end];
    }
    changeDateRange(values, newDateRangeLabel) {
        this.dateRange = values;
        this.dateRangeLabel = newDateRangeLabel;
        this.currentPage = 1;
        this.sendOnboardingData(this.dateRange[0], this.dateRange[1]);
        this.closeDatePickerMenu();
    }
    closeDatePickerMenu() {
        this.scheduleIsOpen = false;
        // @ts-ignore
        this.$refs.dropdownMenu.hide();
    }
    searchTermFilter() {
        this.currentPage = 1;
        if (this.usersWithoutOnboardingActive) {
            const nameOrEmail = this.searchTerm || '';
            this.listAllsersWithoutOnboardings({
                listUsersWithoutOnboarding: true,
                nameOrEmail,
                page: this.currentPage,
            });
        }
        setTimeout(() => {
            this.currentPage = 1;
            this.sendOnboardingData(this.dateRange[0], this.dateRange[1]);
        }, 1200);
    }
    sendOnboardingData(initDate, endDate) {
        this.$emit('getAllOnboardings', {
            init_date: initDate,
            end_date: endDate,
            onboardingStatus: JSON.stringify(this.onboardingsStatusSelected),
            page: this.currentPage,
            name_or_email: this.searchTerm || '',
        });
    }
    createOnboardingToUserWithoutOnboarding({ selectedOption, emailUser, responsibleSelectedCsMember, creationOfOnboardingByAdmin, }) {
        return {
            emailUser,
            selectedOption: selectedOption,
            responsibleSelectedCsMember,
            creationOfOnboardingByAdmin,
        };
    }
    openUserDetails(id) {
        if (this.userSelected === id) {
            this.showUserDetails = false;
            return (this.userSelected = '');
        }
        else {
            if (this.usersWithoutOnboardingActive) {
                const onb = json(this.listUsersWithoutOnboardings).filter(l => l._id.$oid.includes(id));
                this.userWithoutOnboardingInformations = onb[0];
                this.userSelected = id;
            }
            else {
                const onb = json(this.onboardingInformation.data).filter(user => {
                    return user._id.$oid.includes(id);
                });
                this.onboardingInformations = onb[0];
                this.userSelected = id;
            }
            this.showUserDetails = true;
        }
    }
    saveConfirmation({ emailUser, optionSelected, responsibleSelectedCsMember, onboardingId, createOnboarding, }) {
        return {
            emailUser,
            optionSelected,
            responsibleSelectedCsMember,
            onboardingId,
            createOnboarding,
        };
    }
    clearUserDetails() {
        this.showUserDetails = false;
        this.userSelected = '';
        this.onboardingInformations = {};
    }
    onboardingStatusFilter(status) {
        const arr = this.onboardingsStatusSelected;
        const alreadyExistsInArray = this.onboardingsStatusSelected.find(o => o === status);
        if (alreadyExistsInArray) {
            const removeItem = arr.filter(item => item !== status);
            this.onboardingStatus.filter(o => {
                if (o.value === status)
                    o.isSelected = false;
            });
            this.onboardingsStatusSelected = removeItem;
        }
        else {
            this.onboardingsStatusSelected.push(status);
        }
        this.currentPage = 1;
        this.showFilters = false;
        this.sendOnboardingData(this.dateRange[0], this.dateRange[1]);
    }
    hasStatusConfirmation(item) {
        return item.confirmation && item.confirmation.status_confirmation
            ? item.confirmation.status_confirmation
            : 'new';
    }
    setAllListOnboardingResponsible() {
        return true;
    }
    mounted() {
        const initDate = this.handleInitialDateRange()[0];
        const endDate = this.handleInitialDateRange()[1];
        this.sendOnboardingData(initDate, endDate);
        this.setAllListOnboardingResponsible();
    }
};
__decorate([
    Prop({ default: () => [] })
], OnboardingAdminModal.prototype, "listUsersWithoutOnboardings", void 0);
__decorate([
    Prop({ default: () => [] })
], OnboardingAdminModal.prototype, "csUserList", void 0);
__decorate([
    Prop({ default: ListUsersWithoutOnboardingsStatus.IDLE })
], OnboardingAdminModal.prototype, "listUsersWithoutOnboardingsStatus", void 0);
__decorate([
    Prop({ default: GetAllOnBoardingsStatus.IDLE })
], OnboardingAdminModal.prototype, "getAllOnBoardingsStatus", void 0);
__decorate([
    PropSync('open', { default: false })
], OnboardingAdminModal.prototype, "showOnboardModal", void 0);
__decorate([
    Prop({ default: () => { } })
], OnboardingAdminModal.prototype, "onboardingInformation", void 0);
__decorate([
    Prop({ default: () => { } })
], OnboardingAdminModal.prototype, "listUsersWithoutOnboardingsInfo", void 0);
__decorate([
    Emit('listAllsersWithoutOnboardings')
], OnboardingAdminModal.prototype, "listAllsersWithoutOnboardings", null);
__decorate([
    Emit('createOnboardingToUserWithoutOnboarding')
], OnboardingAdminModal.prototype, "createOnboardingToUserWithoutOnboarding", null);
__decorate([
    Emit('saveConfirmation')
], OnboardingAdminModal.prototype, "saveConfirmation", null);
__decorate([
    Emit('setAllListOnboardingResponsible')
], OnboardingAdminModal.prototype, "setAllListOnboardingResponsible", null);
OnboardingAdminModal = __decorate([
    Component({
        name: 'OnboardingAdminModal',
        components: {
            Modal,
            Input,
            DateRangePicker,
            Pagination,
            OnboardingUserList,
            OnboardingUserDetails,
            InnerLoading,
        },
    })
], OnboardingAdminModal);
export default OnboardingAdminModal;
