var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Modal from '@/components/Modal.vue';
import LoadingDots from '@/components/LoadingDots.vue';
import DateRangePicker from '@/components/DateRangePicker.vue';
import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';
import { date } from 'quasar';
let RecentEventsModal = class RecentEventsModal extends Vue {
    constructor() {
        super(...arguments);
        this.scheduleIsOpen = false;
        this.noDataFound = false;
        this.dateRangeLabel = 'Últimos 30 dias';
        this.dateRange = this.handleInitialDateRange();
    }
    handleInitialDateRange() {
        let today = new Date();
        const begin = date.subtractFromDate(today, { days: 30 });
        begin.setHours(0, 0, 0, 0);
        const end = date.subtractFromDate(today, { days: 1 });
        end.setHours(23, 59, 59, 999);
        return [begin, end];
    }
    changeDateRange(values, newDateRangeLabel) {
        this.dateRange = values;
        this.dateRangeLabel = newDateRangeLabel;
        this.$emit('updateDataRange', this.dateRange);
        this.effectivenessNotifications = {
            data: {
                value: {
                    effective_notifications: [],
                },
            },
        };
        this.noDataFound = false;
        this.closeDatePickerMenu();
    }
    handleClearData() {
        this.dateRange[0] = this.handleInitialDateRange()[0];
        this.dateRange[1] = this.handleInitialDateRange()[1];
        this.$emit('updateDataRange', this.dateRange);
    }
    closeDatePickerMenu() {
        this.scheduleIsOpen = false;
        // @ts-ignore
        this.$refs.dropdownMenu.hide();
    }
    get notifications() {
        if (this.effectivenessNotifications &&
            this.effectivenessNotifications.data &&
            this.effectivenessNotifications.data.value) {
            const effectiveNotifications = this.effectivenessNotifications.data.value.effective_notifications;
            this.noDataFound = effectiveNotifications.length == 0;
            return effectiveNotifications;
        }
        return [];
    }
};
__decorate([
    PropSync('open', { default: false })
], RecentEventsModal.prototype, "showEffectivenessNotificationModal", void 0);
__decorate([
    Prop({ default: () => [] })
], RecentEventsModal.prototype, "effectivenessNotifications", void 0);
RecentEventsModal = __decorate([
    Component({
        name: 'RecentEventsModal',
        components: {
            Modal,
            LoadingDots,
            DateRangePicker,
        },
    })
], RecentEventsModal);
export default RecentEventsModal;
