var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, PropSync } from 'vue-property-decorator';
import SurveyModal from '@/modules/admin/components/Survey/SurveyModal.vue';
import { connect } from '@/overmind';
import { ListUsersVoucherStatus, SurveyStatus } from '@/overmind/admin/state';
let SurveyModalContainer = class SurveyModalContainer extends Vue {
    async getAllSurveys({ init_date, end_date, type, page = 1, ratings, name_or_email, }) {
        //@ts-ignore
        this.actionsAdmin.setSurveyStatus(SurveyStatus.RECEIVING);
        //@ts-ignore
        await this.actionsAdmin.getAllSurvey({
            init_date,
            end_date,
            type,
            page,
            ratings,
            name_or_email,
        });
    }
    async listUserWithVoucher({ page }) {
        //@ts-ignore
        this.actionsAdmin.setListUserWithVoucherStatus(ListUsersVoucherStatus.RECEIVING);
        //@ts-ignore
        await this.actionsAdmin.listUserWithVoucher(page);
    }
    async removeVoucher({ allowedVoucher, action, surveyId, }) {
        //@ts-ignore
        await this.actionsAdmin.removeUserVoucher({
            allowedVoucher,
            action,
            surveyId,
        });
        //@ts-ignore
        this.actionsAdmin.setListUserWithVoucherStatus(ListUsersVoucherStatus.RECEIVING);
        //@ts-ignore
        await this.actionsAdmin.listUserWithVoucher(1);
    }
};
__decorate([
    PropSync('open', { default: false })
], SurveyModalContainer.prototype, "showModal", void 0);
SurveyModalContainer = __decorate([
    Component(connect(({ state: { admin: state }, actions }) => ({
        actionsAdmin: actions.admin,
        actionsUsers: actions.users,
        surveyStatus: state.surveyStatus,
        surveyInformation: state.surveyInformation,
        listUsersWithVoucher: state.listUsersWithVoucher,
        listUsersWithVoucherStatus: state.listUsersWithVoucherStatus,
    }), {
        name: 'SurveyModalContainer',
        components: { SurveyModal },
    }))
], SurveyModalContainer);
export default SurveyModalContainer;
