var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop } from 'vue-property-decorator';
import UsersUpdateCreditsTab from '@/modules/admin/components/credits/UsersUpdateCreditsTab.vue';
import { connect } from '@/overmind';
import { AdminStatus, } from '@/overmind/admin/state';
let UsersUpdateCreditsTabContainer = class UsersUpdateCreditsTabContainer extends Vue {
    constructor() {
        super(...arguments);
        this.updateExpireDate = false;
    }
    updateCredits(credits) {
        // @ts-ignore
        this.actions.updateCreditsToUser({
            creditId: credits.id,
            credit: credits,
        });
        this.initData();
    }
    async initData() {
        // @ts-ignore TS2349: property inexistent
        this.actions.setStatusModalCredits(AdminStatus.RECEIVING);
        // @ts-ignore TS2349: property inexistent
        await this.actions.getAccountCredits(this.user.accountId);
        // @ts-ignore TS2349: property inexistent
        this.actions.setStatusModalCredits(AdminStatus.RECEIVED);
        setTimeout(() => {
            // @ts-ignore TS2349: property inexistent
            this.actions.setStatusModalCredits(AdminStatus.IDLE);
        }, 2000);
    }
    async created() {
        // @ts-ignore
        await this.initData();
    }
};
__decorate([
    Prop({ default: () => { } })
], UsersUpdateCreditsTabContainer.prototype, "user", void 0);
UsersUpdateCreditsTabContainer = __decorate([
    Component(connect(({ state: { admin: state }, actions: { admin: actions } }) => ({
        listCredits: state.listValidCredits,
        actions: actions,
    }), {
        components: { UsersUpdateCreditsTab },
    }))
], UsersUpdateCreditsTabContainer);
export default UsersUpdateCreditsTabContainer;
