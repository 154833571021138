var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import Filters from '@/modules/admin/components/AccountManagementModal/AccountManagementUsersTab/Filters.vue';
import { connect } from '@/overmind';
let FiltersContainer = class FiltersContainer extends Vue {
    handleShowMoreFilters(value) {
        this.$emit('showMoreFilters', value);
    }
    onSearch(term) {
        // @ts-ignore
        this.actions.setUsersSearchTerm(term);
    }
    selectStatus(status) {
        // @ts-ignore
        this.actions.setUsersStatus(status);
    }
    selectLevel(level) {
        // @ts-ignore
        this.actions.setUsersLevel(level);
    }
};
FiltersContainer = __decorate([
    Component(connect(({ actions: { admin: actions } }) => ({
        actions: actions,
    }), {
        components: {
            Filters,
        },
    }))
], FiltersContainer);
export default FiltersContainer;
