var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';
import SubUsersModal from '@/modules/admin/components/SubUsersModal.vue';
import { connect } from '@/overmind';
let SubUsersModalContainer = class SubUsersModalContainer extends Vue {
    async initData() {
        // @ts-ignore TS2349: property inexistent
        await this.actions.getAccountInformation(this.user.accountId);
    }
    async updateAllowedUsers(value) {
        const body = {
            subscription: {
                sub_users: value,
            },
        };
        // @ts-ignore TS2349: property inexistent
        await this.actions.updateAllowedUsers({
            accountId: this.user.accountId,
            value: body,
        });
    }
    async confirmEditUser(id, name, email, role) {
        // @ts-ignore TS2349: property inexistent
        await this.actions.updatedSubUser({
            id: id,
            accountId: this.user.accountId,
            name: name,
            email: email,
            role: role,
        });
    }
    async created() {
        // @ts-ignore
        await this.initData();
    }
};
__decorate([
    PropSync('open', { default: false })
], SubUsersModalContainer.prototype, "showModal", void 0);
__decorate([
    Prop({ default: () => { } })
], SubUsersModalContainer.prototype, "user", void 0);
SubUsersModalContainer = __decorate([
    Component(connect(({ state: { admin: state }, actions: { admin: actions } }) => ({
        actions: actions,
        subUsers: state.subUsers,
        allowedUsers: state.allowedUsers,
        statusAllowedUsers: state.statusAllowedUsers,
        statusUpdatedSubUser: state.statusUpdatedSubUser,
    }), {
        name: 'SubUsersModalContainer',
        components: {
            SubUsersModal,
        },
    }))
], SubUsersModalContainer);
export default SubUsersModalContainer;
