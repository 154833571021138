var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import CreditsConsumptionHistoryFilters from '@/modules/admin/components/AccountManagementModal/AccountManagementeCreditsConsumptionTab/CreditsConsumptionHistoryFilters.vue';
import { connect } from '@/overmind';
import { beginningOfTheDay, endOfTheDay, getFirstDayOfYear, getLastDayOfYear, } from '@/shared/utils/helpers';
let CreditsConsumptionHistoryFiltersContainer = class CreditsConsumptionHistoryFiltersContainer extends Vue {
    constructor() {
        super(...arguments);
        this.groupBy = 'month';
        this.rangeDate = [
            beginningOfTheDay(getFirstDayOfYear()).toISOString(),
            endOfTheDay(getLastDayOfYear()).toISOString(),
        ];
    }
    async getHistory(initDate, endDate) {
        this.setRangeDateFilter(initDate, endDate);
        // @ts-ignore TS2349: property inexistent
        await this.actions.getCreditsHistory({
            // @ts-ignore
            accountId: this.selectedUserTable.accountId,
            initDate: initDate.toString(),
            endDate: endDate.toString(),
            groupByFormat: this.groupByFormat,
        });
    }
    setRangeDateFilter(initDate, endDate) {
        // @ts-ignore
        this.actions.setCreditConsumptionsFilters({
            initDate: initDate.toString(),
            endDate: endDate.toString(),
        });
    }
    // eslint-disable-next-line getter-return
    get groupByFormat() {
        if (this.groupBy === 'day')
            return '%Y-%m-%d';
        if (this.groupBy === 'month')
            return '%Y-%m';
        if (this.groupBy === 'year')
            return '%Y';
    }
    async changeGroupBy(value) {
        // @ts-ignore
        this.actions.setAccountModalRightDrawer('');
        // @ts-ignore
        this.actions.setCreditsConsumptionsGroupByFormat(value);
        // @ts-ignore TS2349: property inexistent
        this.actions.setCreditsConsumptionsListPage(1);
        // @ts-ignore
        this.actions.resetCreditsHistory();
        // @ts-ignore
        await this.getHistory(this.rangeDate[0], this.rangeDate[1]);
    }
    async changeDate(values) {
        // @ts-ignore
        this.actions.setCreditsConsumptionsResetScroll(true);
        // @ts-ignore TS2349: property inexistent
        this.actions.setCreditsConsumptionsListPage(1);
        // @ts-ignore
        this.actions.resetCreditsHistory();
        // @ts-ignore
        await this.getHistory(values[0], values[1]);
    }
    firstDayCurrentMonth() {
        return new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    }
    lastDayCurrentMonth() {
        return new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
    }
};
CreditsConsumptionHistoryFiltersContainer = __decorate([
    Component(connect(({ state: { admin: state }, actions: { admin: actions } }) => ({
        selectedUserTable: state.selectedUserTable,
        actions: actions,
        status: state.creditConsumptions.status,
    }), {
        components: {
            CreditsConsumptionHistoryFilters,
        },
    }))
], CreditsConsumptionHistoryFiltersContainer);
export default CreditsConsumptionHistoryFiltersContainer;
