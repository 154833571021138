var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import AdminFiltersHeaderMenu from '@/modules/admin/components/AdminFiltersHeaderMenu.vue';
import { Component, Vue, Emit, Prop } from 'vue-property-decorator';
import Input from 'components/Input.vue';
import TestAccountFormContainer from '../containers/TestAccountForm.container.vue';
import SurveyModalContainer from '@/modules/admin/containers/SurveyModal.container.vue';
import OnboardingAdminModalContainer from '@/modules/admin/containers/OnboardingModal.container.vue';
import RecentEventsModalContainer from '@/modules/admin/containers/RecentEventsModal.container.vue';
import SentNotificationsModal from '@/modules/admin/containers/SentNotificationsModal.container.vue';
import EffectivenessNotificationModal from '@/modules/admin/containers/EffectivenessNotificationModal.container.vue';
import AddReactivationTokenModal from '@/modules/admin/containers/AddReactivationTokenModal.container.vue';
import AccessTokenModal from '@/modules/admin/containers/AccessTokenModal.container.vue';
import ChurnData from '@/modules/admin/containers/ChurnData.container.vue';
import { isEmpty } from 'lodash';
import PlanListModal from './PlanListModal.vue';
let AdminHeader = class AdminHeader extends Vue {
    constructor() {
        super(...arguments);
        this.searchTerm = '';
        this.filterActive = true;
        this.showAccountTestModal = false;
        this.showSurveyModal = false;
        this.showOnboardModal = false;
        this.showRecentEventsModal = false;
        this.showSentNotificationsModal = false;
        this.showEffectivenessNotificationModal = false;
        this.showAddReactivationTokenModal = false;
        this.showAccessTokenModal = false;
        this.showChurnDataModal = false;
        this.showPlanListModal = false;
    }
    toggleFilter() {
        this.filterActive = !this.filterActive;
    }
    searchUsers(term) {
        return !isEmpty(term) ? term.trim() : term;
    }
    searchUsersCheckboxFilters(event) {
        return event;
    }
    resetFilters(event) {
        return event;
    }
};
__decorate([
    Prop({ default: () => [] })
], AdminHeader.prototype, "csAndAdmins", void 0);
__decorate([
    Emit('searchUsers')
], AdminHeader.prototype, "searchUsers", null);
__decorate([
    Emit('searchUsersCheckboxFilters')
], AdminHeader.prototype, "searchUsersCheckboxFilters", null);
__decorate([
    Emit('resetFilters')
], AdminHeader.prototype, "resetFilters", null);
AdminHeader = __decorate([
    Component({
        name: 'AdminHeader',
        components: {
            Input,
            AdminFiltersHeaderMenu,
            TestAccountFormContainer,
            SurveyModalContainer,
            OnboardingAdminModalContainer,
            RecentEventsModalContainer,
            SentNotificationsModal,
            EffectivenessNotificationModal,
            AddReactivationTokenModal,
            AccessTokenModal,
            ChurnData,
            PlanListModal,
        },
    })
], AdminHeader);
export default AdminHeader;
