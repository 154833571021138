var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Modal from '@/components/Modal.vue';
import { Component, Emit, Vue, PropSync } from 'vue-property-decorator';
let RecentEventsModal = class RecentEventsModal extends Vue {
    constructor() {
        super(...arguments);
        this.token = '';
        this.credits = 0;
    }
    mounted() {
        this.token = '';
        this.credits = 0;
    }
    onCreatedReactivationToken() {
        this.createReactivationToken(this.token, this.credits);
    }
    createReactivationToken(token, credits) { }
};
__decorate([
    PropSync('open', { default: false })
], RecentEventsModal.prototype, "showAddReactivationTokenModal", void 0);
__decorate([
    Emit('createReactivationToken')
], RecentEventsModal.prototype, "createReactivationToken", null);
RecentEventsModal = __decorate([
    Component({
        name: 'ReactivationTokenModal',
        components: {
            Modal,
        },
    })
], RecentEventsModal);
export default RecentEventsModal;
