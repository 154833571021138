var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { getDateTimeByFormat, removeHashRevokeUserEmail, } from '@/shared/utils/helpers';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Pagination from 'components/Pagination.vue';
let CreditsHistoryDetails = class CreditsHistoryDetails extends Vue {
    constructor() {
        super(...arguments);
        this.currentPage = 1;
    }
    setPageNumber(page) {
        this.currentPage = page;
        this.$emit('changePage', page);
    }
    get creditTypeLabel() {
        if (this.creditType === 'click') {
            return 'Uso de Cliques';
        }
        if (this.creditType === 'export') {
            return 'Uso de Exportações';
        }
        if (this.creditType === 'unlimited_click') {
            return 'Uso de Cliques Ilimitados';
        }
        return '';
    }
    getKey(history) {
        if (this.groupBy === 'day') {
            return history.consumptions.period + history.id;
        }
        return history.id;
    }
    occuriencyDate(period) {
        return getDateTimeByFormat(new Date(period), 'dddd, D [de] MMMM [de] YYYY [às] HH:mm');
    }
    showEmail(email) {
        return removeHashRevokeUserEmail(email);
    }
};
__decorate([
    Prop({ default: () => [] })
], CreditsHistoryDetails.prototype, "histories", void 0);
__decorate([
    Prop({ default: false })
], CreditsHistoryDetails.prototype, "loading", void 0);
__decorate([
    Prop({ default: '' })
], CreditsHistoryDetails.prototype, "periodLabel", void 0);
__decorate([
    Prop({ default: '' })
], CreditsHistoryDetails.prototype, "creditType", void 0);
__decorate([
    Prop({ default: 'month' })
], CreditsHistoryDetails.prototype, "groupBy", void 0);
__decorate([
    Prop({ default: 1 })
], CreditsHistoryDetails.prototype, "page", void 0);
__decorate([
    Prop({ default: 1 })
], CreditsHistoryDetails.prototype, "totalPages", void 0);
CreditsHistoryDetails = __decorate([
    Component({
        components: {
            Pagination,
        },
    })
], CreditsHistoryDetails);
export default CreditsHistoryDetails;
