var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Modal from '@/components/Modal.vue';
import LoadingDots from '@/components/LoadingDots.vue';
import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';
let RecentEventsModal = class RecentEventsModal extends Vue {
};
__decorate([
    PropSync('open', { default: false })
], RecentEventsModal.prototype, "showRecentEventsModal", void 0);
__decorate([
    Prop({ default: () => [{}] })
], RecentEventsModal.prototype, "recentEvents", void 0);
RecentEventsModal = __decorate([
    Component({
        name: 'RecentEventsModal',
        components: {
            Modal,
            LoadingDots,
        },
    })
], RecentEventsModal);
export default RecentEventsModal;
