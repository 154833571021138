var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator';
import AccountPreferencesModal from '@/modules/admin/components/AccountPreferencesModal.vue';
import { connect } from '@/overmind';
import { json } from 'overmind';
let AccountPreferencesModalContainer = class AccountPreferencesModalContainer extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.userForm = {
            id: '',
            email: '',
            accountPreferences: { email: '', id: '', accountId: '' },
            accountOwner: undefined,
            accountId: '',
            usersCount: 0,
            createdAt: '',
            name: '',
            role: '',
            accountType: '',
            planName: '',
            userStatus: '',
            internalExportCount: 0,
            accountStatus: '',
            cs: { name: '', email: '', id: '' },
        };
    }
    changeUser(user) {
        const accountPreferences = user.accountPreferences || {
            email: '',
            id: '',
            accountId: '',
        };
        this.userForm = json({
            ...user,
            accountPreferences,
        });
    }
    async confirmEditUser(user) {
        this.loading = true;
        // @ts-ignore TS2349: property inexistent
        const res = await this.actions.updateUserAccount(user);
        this.loading = false;
        if (res) {
            this.showModal = false;
        }
    }
};
__decorate([
    PropSync('open', { default: false })
], AccountPreferencesModalContainer.prototype, "showModal", void 0);
__decorate([
    Prop({ default: () => { } })
], AccountPreferencesModalContainer.prototype, "user", void 0);
__decorate([
    Watch('user', { immediate: true, deep: true })
], AccountPreferencesModalContainer.prototype, "changeUser", null);
AccountPreferencesModalContainer = __decorate([
    Component(connect(({ state: { admin: state }, actions: { admin: actions } }) => ({
        actions: actions,
        subUsers: state.subUsers,
        allowedUsers: state.allowedUsers,
        statusAllowedUsers: state.statusAllowedUsers,
        statusUpdatedSubUser: state.statusUpdatedSubUser,
    }), {
        components: {
            AccountPreferencesModal,
        },
    }))
], AccountPreferencesModalContainer);
export default AccountPreferencesModalContainer;
