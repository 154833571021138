var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import AccountManagementFilters from '@/modules/admin/components/AccountManagementModal/AccountManagementFiltersTab/AccountManagementFiltersTab.vue';
import { connect } from '@/overmind';
import { Component, Vue } from 'vue-property-decorator';
let AccountManagementFiltersTabContainer = class AccountManagementFiltersTabContainer extends Vue {
    constructor() {
        super(...arguments);
        this.more_used_filters = [];
        this.included_cnpjs_used_on_searches = [];
    }
    async mounted() {
        this.more_used_filters = await this.getMoreUsedFilters();
        this.included_cnpjs_used_on_searches =
            await this.getIncludedCnpjsUsedOnSearches();
    }
    async getMoreUsedFilters() {
        // @ts-ignore
        return this.actions.moreUsedFilters(
        // @ts-ignore
        this.selectedUserTable.id);
    }
    async getIncludedCnpjsUsedOnSearches() {
        // @ts-ignore
        return this.actions.includedCnpjsUsedOnSearches(
        // @ts-ignore
        this.selectedUserTable.id);
    }
};
AccountManagementFiltersTabContainer = __decorate([
    Component(connect(({ state: { admin: state }, actions: { admin: actions } }) => ({
        selectedUserTable: state.selectedUserTable,
        actions: actions,
    }), {
        components: {
            AccountManagementFilters,
        },
    }))
], AccountManagementFiltersTabContainer);
export default AccountManagementFiltersTabContainer;
