var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, PropSync } from 'vue-property-decorator';
import TestAccountModal from '../components/TestAccountModal.vue';
import { connect } from '@/overmind';
let TestAccountFormContainer = class TestAccountFormContainer extends Vue {
    constructor() {
        super(...arguments);
        this.infoCep = {
            cep: '',
            city: '',
            neighborhood: '',
            state: '',
            street: '',
        };
    }
    registerAccount(data) {
        // @ts-ignore TS2349: property inexistent
        const result = this.effects.admin.api.createAccountTest(data);
        result
            .then(res => {
            this.showModal = false;
            this.$q.notify({
                message: 'Usuário cadastrado com sucesso',
                type: 'success-user',
            });
            // @ts-ignore TS2349: property inexistent
            // this.actions.getUsers()
        })
            .catch(errors => {
            this.$q.notify({
                message: 'E-mail já cadastrado',
                type: 'error-email',
            });
        });
    }
    searchZip(zipCode) {
        if (zipCode.length === 8) {
            // @ts-ignore TS2349: property inexistent
            const result = this.effects.filters.api.searchCEP(zipCode);
            result.then(res => {
                this.infoCep = res;
                if (!res.city) {
                    this.$q.notify({
                        message: 'CEP não encontrado',
                        type: 'error',
                    });
                }
            });
        }
        else {
            this.infoCep = {
                cep: '',
                city: '',
                neighborhood: '',
                state: '',
                street: '',
            };
        }
    }
};
__decorate([
    PropSync('open', { default: false })
], TestAccountFormContainer.prototype, "showModal", void 0);
TestAccountFormContainer = __decorate([
    Component(connect(({ state: { admin: state }, actions: { admin: actions }, effects }) => ({
        actions: actions,
        effects: effects,
    }), {
        name: 'TestAccountFormContainer',
        components: { TestAccountModal },
    }))
], TestAccountFormContainer);
export default TestAccountFormContainer;
