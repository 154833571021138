import { render, staticRenderFns } from "./AccountManagementHealthscoreTab.container.vue?vue&type=template&id=12ad7bd8&scoped=true&"
import script from "./AccountManagementHealthscoreTab.container.vue?vue&type=script&lang=ts&"
export * from "./AccountManagementHealthscoreTab.container.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12ad7bd8",
  null
  
)

export default component.exports