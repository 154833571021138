var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import AccountManagementModalTabs from '@/modules/admin/components/AccountManagementModal/AccountManagementModalTabs.vue';
import AccountManagementNavbarHeaderContainer from '@/modules/admin/containers/AccountManagementModal/AccountManagementNavbarHeader.container.vue';
let AccountManagementNavbar = class AccountManagementNavbar extends Vue {
    changeTab(currentTab) {
        this.$emit('changeTab', currentTab);
    }
};
__decorate([
    Prop({ default: 'credits' })
], AccountManagementNavbar.prototype, "tab", void 0);
AccountManagementNavbar = __decorate([
    Component({
        name: 'AccountManagementNavbar',
        components: {
            AccountManagementModalTabs,
            AccountManagementNavbarHeaderContainer,
        },
    })
], AccountManagementNavbar);
export default AccountManagementNavbar;
