var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import SurveyListItem from '@/modules/admin/components/Survey/SurveyListItem.vue';
import SurveyDetails from './SurveyDetails.vue';
import { json } from 'overmind';
import { personNameInitials } from '@/shared/utils/helpers';
import SurveyRatingItem from './SurveyRatingItem.vue';
let SurveyList = class SurveyList extends Vue {
    constructor() {
        super(...arguments);
        this.openSurveyDetails = false;
        this.selectedSurvey = '';
        this.surveyInfoDetail = {};
        this.initialsName = '';
        this.depRating = [];
    }
    watchGraphyFilter() {
        this.resetSurveyDetails();
        return this.setRating(this.ratings);
    }
    toggleDetail(data) {
        if (data.open) {
            this.selectedSurvey = data.id.$oid;
            this.surveyDetails();
            this.openSurveyDetails = data.open;
        }
        else {
            this.resetSurveyDetails();
        }
    }
    surveyDetails() {
        if (this.surveyInformation) {
            const survey = this.surveyInformation
                .filter(item => item._id.$oid === this.selectedSurvey)
                .map(survey => {
                return {
                    ...survey,
                    initialUserName: personNameInitials(survey.user.name),
                };
            });
            const InfoSurvey = json(survey);
            return this.setSurveyDetail(InfoSurvey);
        }
    }
    setSurveyDetail(survey) {
        return (this.surveyInfoDetail = survey);
    }
    resetSurveyDetails() {
        this.selectedSurvey = '';
        return (this.openSurveyDetails = false);
    }
    setRating(value) {
        return (this.depRating = [...value]);
    }
    handleTotalRatingByValue(val) {
        return this.depRating.filter(r => r === val).length;
    }
    removeVoucher({ allowedVoucher, action, surveyId, }) {
        return { allowedVoucher, action, surveyId };
    }
};
__decorate([
    Prop({ default: null })
], SurveyList.prototype, "headColumns", void 0);
__decorate([
    Prop({ default: () => [] })
], SurveyList.prototype, "surveyInformation", void 0);
__decorate([
    Prop({ default: () => { } })
], SurveyList.prototype, "ratings", void 0);
__decorate([
    Prop({ default: false })
], SurveyList.prototype, "graphyFilter", void 0);
__decorate([
    Prop({ default: () => [] })
], SurveyList.prototype, "userWithVoucher", void 0);
__decorate([
    Prop({ default: false })
], SurveyList.prototype, "allowedVoucher", void 0);
__decorate([
    Watch('graphyFilter')
], SurveyList.prototype, "watchGraphyFilter", null);
__decorate([
    Emit('removeVoucher')
], SurveyList.prototype, "removeVoucher", null);
SurveyList = __decorate([
    Component({
        name: 'SurveyList',
        components: { SurveyListItem, SurveyDetails, SurveyRatingItem },
    })
], SurveyList);
export default SurveyList;
