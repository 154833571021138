var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, PropSync } from 'vue-property-decorator';
import EffectivenessNotificationModal from '@/modules/admin/components/EffectivenessNotification/Modal.vue';
import { connect } from '@/overmind';
let EffectivenessNotificationModalContainer = class EffectivenessNotificationModalContainer extends Vue {
    constructor() {
        super(...arguments);
        this.effectivenessNotifications = null;
    }
    async mounted() {
        this.effectivenessNotifications = await this.getEffectivenessNotification();
    }
    async getEffectivenessNotification() {
        // @ts-ignore
        return await this.actions.effectivenessReport();
    }
    async updateDataRange(data_range) {
        // @ts-ignore
        this.effectivenessNotifications = await this.actions.effectivenessReport(data_range);
    }
};
__decorate([
    PropSync('open', { default: false })
], EffectivenessNotificationModalContainer.prototype, "showEffectivenessNotificationModal", void 0);
EffectivenessNotificationModalContainer = __decorate([
    Component(connect(({ actions: { admin: actions } }) => ({
        actions: actions,
    }), {
        name: 'EffectivenessNotificationModalContainer',
        components: { EffectivenessNotificationModal },
    }))
], EffectivenessNotificationModalContainer);
export default EffectivenessNotificationModalContainer;
