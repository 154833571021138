var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import AccountManagementUserBehavior from '@/modules/admin/components/AccountManagementModal/AccountManagementUserBehaviorTab/AccountManagementUserBehaviorTab.vue';
import { connect } from '@/overmind';
import { Component, Vue } from 'vue-property-decorator';
let AccountManagementUserBehaviorTabContainer = class AccountManagementUserBehaviorTabContainer extends Vue {
    constructor() {
        super(...arguments);
        this.infosets = [];
        this.basicMetricInfos = [];
        this.mixpanelMetrics = [];
        this.onlineDays = [];
        this.clientSearches = [];
        this.clientNpsScores = [];
        this.appsOwned = [];
    }
    async mounted() {
        this.infosets = await this.getAccountInfosetsLast30Days();
        this.basicMetricInfos = await this.getBasicMetricInfos();
        this.mixpanelMetrics = await this.getMixpanelMetrics();
        this.onlineDays = await this.getOnlineDays();
        this.clientSearches = await this.getClientSearches();
        this.clientNpsScores = await this.getClientNpsScores();
        this.appsOwned = await this.getAppsOwned();
    }
    async getAccountInfosetsLast30Days() {
        // @ts-ignore
        return await this.actions.accountInfosetsLast30Days(
        // @ts-ignore
        this.selectedUserTable.accountId);
    }
    async getBasicMetricInfos() {
        // @ts-ignore
        return await this.actions.basicMetricInfos(this.selectedUserTable.accountId);
    }
    async getMixpanelMetrics() {
        // @ts-ignore
        return await this.actions.mixpanelEvents(this.selectedUserTable.id);
    }
    async getOnlineDays() {
        // @ts-ignore
        return await this.actions.onlineDays(this.selectedUserTable.id);
    }
    async getClientSearches() {
        // @ts-ignore
        return await this.actions.clientSearches(this.selectedUserTable.id);
    }
    async getClientNpsScores() {
        // @ts-ignore
        return await this.actions.getNpsScores(this.selectedUserTable.id);
    }
    async getAppsOwned() {
        // @ts-ignore
        return await this.actions.getAppsOwned(this.selectedUserTable.accountId);
    }
};
AccountManagementUserBehaviorTabContainer = __decorate([
    Component(connect(({ state: { admin: state }, actions: { admin: actions } }) => ({
        selectedUserTable: state.selectedUserTable,
        actions: actions,
    }), {
        components: {
            AccountManagementUserBehavior,
        },
    }))
], AccountManagementUserBehaviorTabContainer);
export default AccountManagementUserBehaviorTabContainer;
