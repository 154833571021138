var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import AuditsCreditsFiltersContainer from '@/modules/admin/containers/AccountManagementModal/AccountManagementTabs/AccountManagementAuditsTab/CreditsTab/AuditsCreditsFilters.container.vue';
import AuditsList from '@/modules/admin/components/AccountManagementModal/AccountManagementAuditsTab/CreditsTab/AuditsList.vue';
import Pagination from 'components/Pagination.vue';
import { connect } from '@/overmind';
let AuditsCreditsTabContainer = class AuditsCreditsTabContainer extends Vue {
    constructor() {
        super(...arguments);
        this.currentPage = 1;
    }
    async created() {
        await this.getCreditsAudits();
    }
    destroyed() {
        // @ts-ignore
        this.actions.resetCreditsAudits();
    }
    async setPageNumber(page) {
        this.currentPage = page;
        await this.getCreditsAudits(true, this.currentPage);
        const list = this.$refs.auditListContainer;
        if (list) {
            ;
            list.scrollTop = 0;
        }
    }
    async getCreditsAudits(withLoading = true, page = 1) {
        // @ts-ignore
        await this.actions.getCreditsAudits({
            // @ts-ignore
            accountId: this.selectedUserTable.accountId,
            origin: 'admin',
            // @ts-ignore
            page: page,
            withLoading,
        });
    }
    async handleAuditsListScroll(e) {
        const list = e.target;
        if (list) {
            // @ts-ignore
            let isBottom = list.scrollTop + list.offsetHeight >= list.scrollHeight;
            if (isBottom) {
                // @ts-ignore
                this.actions.setCreditsAuditsListPage(this.page + 1);
                // @ts-ignore TS2349: property inexistent
                await this.getCreditsAudits(false, ++this.page);
            }
        }
    }
    showAudit(audit) {
        // @ts-ignore
        this.actions.selectCreditAudit(audit);
        // @ts-ignore
        this.actions.setAccountModalRightDrawer('AuditsDetail');
    }
};
AuditsCreditsTabContainer = __decorate([
    Component(connect(({ state: { admin: state }, actions: { admin: actions } }) => ({
        audits: state.audits.credits,
        actions: actions,
        selectedUserTable: state.selectedUserTable,
    }), {
        components: {
            AuditsCreditsFiltersContainer,
            AuditsList,
            Pagination,
        },
    }))
], AuditsCreditsTabContainer);
export default AuditsCreditsTabContainer;
