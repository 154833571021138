var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
let AccountManagementHealthscoreTab = class AccountManagementHealthscoreTab extends Vue {
    constructor() {
        super(...arguments);
        this.healthscore = 0;
        this.credits = [];
        this.category = 'Sem Score';
    }
    async mounted() {
        this.healthscore = this.credits_and_healthscore.total_healthscore;
        this.credits = this.credits_and_healthscore.credits;
        this.category = this.credits_and_healthscore.category;
    }
    onCreditsAndHealthscoreChanged(oldval, newval) {
        this.category = this.credits_and_healthscore.category;
        this.healthscore = this.credits_and_healthscore.total_healthscore;
        this.credits = this.credits_and_healthscore.credits;
    }
};
__decorate([
    Prop({ default: () => { } })
], AccountManagementHealthscoreTab.prototype, "credits_and_healthscore", void 0);
__decorate([
    Watch('credits_and_healthscore')
], AccountManagementHealthscoreTab.prototype, "onCreditsAndHealthscoreChanged", null);
AccountManagementHealthscoreTab = __decorate([
    Component({})
], AccountManagementHealthscoreTab);
export default AccountManagementHealthscoreTab;
