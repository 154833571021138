var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { AdminStatus } from '@/overmind/admin/state';
import { Component, Prop, Vue } from 'vue-property-decorator';
import CreditsHistoryContainer from '@/modules/admin/containers/AccountManagementModal/AccountManagementTabs/AccountManagementCreditsConsumptionTab/CreditsHistory.container.vue';
import CreditsConsumptionHistoryFiltersContainer from '@/modules/admin/containers/AccountManagementModal/AccountManagementTabs/AccountManagementCreditsConsumptionTab/CreditsConsumptionHistoryFilters.container.vue';
let AccountManagementCreditsConsumptionTab = class AccountManagementCreditsConsumptionTab extends Vue {
    getTotalExportations() {
        let total = 0;
        this.creditsHistory.forEach(item => {
            if (item.type === 'export') {
                if (total === 0) {
                    total = item.spent;
                }
                else {
                    total += item.spent;
                }
            }
        });
        return total;
    }
    getTotalClicks() {
        let total = 0;
        this.creditsHistory.forEach(item => {
            if (item.type === 'click') {
                if (total === 0) {
                    total = item.spent;
                }
                else {
                    total += item.spent;
                }
            }
        });
        return total;
    }
};
__decorate([
    Prop({ default: AdminStatus.IDLE })
], AccountManagementCreditsConsumptionTab.prototype, "status", void 0);
__decorate([
    Prop({ default: () => [] })
], AccountManagementCreditsConsumptionTab.prototype, "creditsHistory", void 0);
AccountManagementCreditsConsumptionTab = __decorate([
    Component({
        components: {
            CreditsConsumptionHistoryFiltersContainer,
            CreditsHistoryContainer,
        },
    })
], AccountManagementCreditsConsumptionTab);
export default AccountManagementCreditsConsumptionTab;
