var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import AccountManagementAccountInfoTab from '@/modules/admin/components/AccountManagementModal/AccountManagementAccountInfoTab/AccountManagementAccountInfoTab.vue';
import { connect } from '@/overmind';
let AccountManagementAccountInfoTabContainer = class AccountManagementAccountInfoTabContainer extends Vue {
    async updateAccountInfo(payload) {
        // @ts-ignore
        await this.actions.updateUserAccount({
            // @ts-ignore
            accountId: this.currentAccount.id,
            preferences: payload,
        });
    }
};
AccountManagementAccountInfoTabContainer = __decorate([
    Component(connect(({ state: { admin: state }, actions: { admin: actions } }) => ({
        currentAccount: state.currentAccount,
        actions: actions,
    }), {
        components: {
            AccountManagementAccountInfoTab,
        },
    }))
], AccountManagementAccountInfoTabContainer);
export default AccountManagementAccountInfoTabContainer;
