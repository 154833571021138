import { render, staticRenderFns } from "./AccountManagementAccountInfoTab.container.vue?vue&type=template&id=ef734eb2&scoped=true&"
import script from "./AccountManagementAccountInfoTab.container.vue?vue&type=script&lang=ts&"
export * from "./AccountManagementAccountInfoTab.container.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef734eb2",
  null
  
)

export default component.exports