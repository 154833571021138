var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import { removeHashRevokeUserEmail } from '@/shared/utils/helpers';
let AccountManagementNavbarHeaderSummary = class AccountManagementNavbarHeaderSummary extends Vue {
    getOwnerName() {
        if (this.account.owner) {
            return this.account.owner.name;
        }
        return 'Sem Proprietário';
    }
    clickCredits() {
        if (this.credits.unlimitedClick) {
            return 'Ilimitado';
        }
        return this.credits.click;
    }
    accountPreferenceEmail() {
        if (this.account &&
            this.account.preferences &&
            this.account.preferences.email) {
            return removeHashRevokeUserEmail(this.account.preferences.email);
        }
        return 'E-mail da conta não configurado';
    }
    hasEmail() {
        return this.accountPreferenceEmail().includes('@');
    }
    // eslint-disable-next-line getter-return
    get headerStyle() {
        if (this.$q.screen.sm)
            return { 'max-width': '180px' };
        if (this.$q.screen.md)
            return { 'max-width': '200px' };
        if (this.$q.screen.lg)
            return { 'max-width': '220px' };
        if (this.$q.screen.xl)
            return { 'max-width': '225px' };
    }
};
__decorate([
    Prop({ default: () => { } })
], AccountManagementNavbarHeaderSummary.prototype, "account", void 0);
__decorate([
    Prop({ default: () => { } })
], AccountManagementNavbarHeaderSummary.prototype, "credits", void 0);
AccountManagementNavbarHeaderSummary = __decorate([
    Component
], AccountManagementNavbarHeaderSummary);
export default AccountManagementNavbarHeaderSummary;
