var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import Filters from '@/modules/admin/components/AccountManagementModal/AccountManagementAuditsTab/CreditsTab/AuditsFilters.vue';
import { connect } from '@/overmind';
let AuditsCreditsFiltersContainer = class AuditsCreditsFiltersContainer extends Vue {
    async changeOrigin(origin) {
        // @ts-ignore
        this.actions.resetCreditsAudits();
        if (origin === 'admin' || origin === 'system') {
            // @ts-ignore
            this.actions.setCreditsAuditsFilters({ origin, status: 'success' });
        }
        else {
            if (origin === 'superlogica') {
                // @ts-ignore
                this.actions.setCreditsAuditsFilters({ origin, type: 'all' });
            }
            else {
                // @ts-ignore
                this.actions.setCreditsAuditsFilters({ origin });
            }
        }
        // @ts-ignore
        await this.actions.getCreditsAudits({
            // @ts-ignore
            accountId: this.selectedUserTable.accountId,
            origin: origin,
        });
    }
    async changeStatus(status) {
        // @ts-ignore
        this.actions.resetCreditsAudits();
        // @ts-ignore
        this.actions.setCreditsAuditsFilters({ status });
        // @ts-ignore
        await this.actions.getCreditsAudits({
            // @ts-ignore
            accountId: this.selectedUserTable.accountId,
        });
    }
    async changeOperation(operation) {
        // @ts-ignore
        this.actions.resetCreditsAudits();
        // @ts-ignore
        this.actions.setCreditsAuditsFilters({ type: operation });
        // @ts-ignore
        await this.actions.getCreditsAudits({
            // @ts-ignore
            accountId: this.selectedUserTable.accountId,
        });
    }
    async changePeriod(values) {
        // @ts-ignore
        this.actions.resetCreditsAudits();
        const initDate = values[0];
        const endDate = values[1];
        // @ts-ignore
        this.actions.setCreditsAuditsFilters({
            period: {
                initDate: new Date(initDate).toString(),
                endDate: new Date(endDate).toString(),
            },
        });
        // @ts-ignore
        await this.actions.getCreditsAudits({
            // @ts-ignore
            accountId: this.selectedUserTable.accountId,
        });
    }
    getRangeDate({ initDate, endDate }) {
        return [new Date(initDate), new Date(endDate)];
    }
    firstDayCurrentMonth() {
        return new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    }
    lastDayCurrentMonth() {
        return new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
    }
};
AuditsCreditsFiltersContainer = __decorate([
    Component(connect(({ state: { admin: state }, actions: { admin: actions } }) => ({
        accountModalTab: state.accountModalTab,
        selectedUserTable: state.selectedUserTable,
        filters: state.audits.credits.filters,
        status: state.audits.credits.status,
        actions: actions,
    }), {
        components: {
            Filters,
        },
    }))
], AuditsCreditsFiltersContainer);
export default AuditsCreditsFiltersContainer;
