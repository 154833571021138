var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import Input from '@/components/Input.vue';
let Filters = class Filters extends Vue {
    constructor() {
        super(...arguments);
        this.showFilters = false;
        this.userSearchTerm = '';
        this.statuses = [
            { isSelected: false, label: 'Ativo', value: 'active' },
            { isSelected: false, label: 'Inativo', value: 'inactive' },
        ];
        this.levels = [
            { isSelected: false, label: 'Administrador', value: 'admin' },
            { isSelected: false, label: 'Proprietário', value: 'owner' },
            { isSelected: false, label: 'Gerente', value: 'manager' },
            { isSelected: false, label: 'Coordenador', value: 'coordinator' },
            { isSelected: false, label: 'Prospector', value: 'prospector' },
        ];
    }
    hideFilters() {
        this.showFilters = false;
        // @ts-ignore
        this.$refs.userSearchInput.$refs.input.focus();
    }
    selectStatus(status) {
        this.$emit('selectStatus', status);
    }
    selectLevel(level) {
        this.$emit('selectLevel', level);
    }
    onSearch(term) {
        this.$emit('onSearch', term);
    }
};
Filters = __decorate([
    Component({
        components: {
            Input,
        },
    })
], Filters);
export default Filters;
