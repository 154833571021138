var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getDateTimeByFormat, personNameInitials, showNotify, } from '@/shared/utils/helpers';
import Input from '@/components/Input.vue';
let AuditsDetail = class AuditsDetail extends Vue {
    constructor() {
        super(...arguments);
        this.invalidateOptionSelected = false;
        this.invalidateCause = '';
        this.invalidateLoading = false;
        this.operation = {
            add_credit: 'Adição',
            remove_credit: 'Remoção',
        };
        this.status = {
            success: 'Sucesso',
            error: 'Erro',
        };
    }
    get isSuccess() {
        return this.audit.status === 'success';
    }
    get validStatusLabel() {
        if (this.validStatus)
            return 'Válido';
        return 'Inválido';
    }
    get validStatus() {
        return (this.audit.type === 'add_credit' &&
            !this.audit.invalidated &&
            new Date(this.audit.params.expiration).getTime() > Date.now());
    }
    get originLabel() {
        switch (this.audit.origin) {
            case 'superlogica':
                return 'Superlógica';
            case 'stripe':
                return 'Stripe';
            case 'admin':
                return 'Admin';
            case 'referral':
                return 'MemberGM';
            default:
                return 'Sistema';
        }
    }
    get operationLabel() {
        switch (this.audit.type) {
            case 'add_credit':
                return 'Adição';
            case 'remove_credit':
                return 'Remoção';
            case 'invalidate_unlimited_credit':
                return 'Remoção';
            default:
                return 'Adição';
        }
    }
    get authorNameInitials() {
        switch (this.audit.origin) {
            case 'superlogica':
                return 'SL';
            case 'stripe':
                return 'ST';
            case 'system':
                return 'SI';
            default:
                return this.audit.user ? personNameInitials(this.audit.user.name) : '';
        }
    }
    get authorName() {
        switch (this.audit.origin) {
            case 'superlogica':
                return 'Superlogica';
            case 'stripe':
                return 'Stripe';
            case 'system':
                return 'Sistema';
            default:
                return this.audit.user ? this.audit.user.name : '';
        }
    }
    get creditsUpdateLabel() {
        switch (this.audit.type) {
            case 'add_credit':
                return 'Créditos adicionados';
            case 'remove_credit':
            case 'invalidate_unlimited_credit':
                return 'Créditos removidos';
            default:
                break;
        }
        return '';
    }
    async confirmInvalidate() {
        this.invalidateLoading = true;
        try {
            await Vue.axios.post('/credit_xds/invalidate', {
                id: this.audit.params.id,
                cause: this.invalidateCause,
            });
            showNotify('Credito invalidado com sucesso', 'success');
        }
        catch (e) {
            console.log(e);
            showNotify('Ocorreu um erro ao tentar invalidar este credito', 'error');
        }
        this.invalidateLoading = false;
    }
    periodDetailsLabel() {
        if (this.audit.period) {
            return getDateTimeByFormat(new Date(this.audit.period), 'dddd, D [de] MMMM [de] YYYY [às] HH:mm');
        }
        return '';
    }
    periodExpirationDetailsLabel() {
        if (this.audit.type === 'add_credit') {
            if (this.audit.params.expiration) {
                const validationDate = new Date(this.audit.params.expiration);
                let originalValidationDate = validationDate.setDate(validationDate.getDate() + 1);
                return getDateTimeByFormat(new Date(originalValidationDate), 'dddd, D [de] MMMM [de] YYYY [às] HH:mm');
            }
        }
        return '-- --';
    }
    get isAddition() {
        return this.audit.type === 'add_credit';
    }
    get isRemoval() {
        return this.audit.type === 'remove_credit';
    }
    get creditLabel() {
        switch (this.audit.params.type) {
            case 'click':
                return `Cliques: ${this.isRemoval ? this.audit.params.amount : this.audit.params.value}`;
            case 'export':
                return `Exportações: ${this.isRemoval ? this.audit.params.amount : this.audit.params.value}`;
            case 'unlimited_click':
                return 'Cliques Ilimitados';
            default:
                break;
        }
        return '';
    }
    get handleShowDescriptionTooltip() {
        return ((this.audit.params.description &&
            this.audit.params.description.length > 140) ||
            (this.audit.params.error && this.audit.params.error.description));
    }
    get descriptionLabel() {
        if (this.audit.status === 'success') {
            return this.audit.params.description;
        }
        if (this.audit.status === 'error' && this.audit.params.error) {
            return this.audit.params.error.description;
        }
        return 'Sem descrição';
    }
};
__decorate([
    Prop({
        default: () => { },
    })
], AuditsDetail.prototype, "audit", void 0);
AuditsDetail = __decorate([
    Component({
        components: {
            Input,
        },
    })
], AuditsDetail);
export default AuditsDetail;
