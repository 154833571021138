var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, PropSync, Emit, Watch, } from 'vue-property-decorator';
import InnerLoading from '@/components/InnerLoading.vue';
import UsersCreditsManagerTabs from './UsersCreditsManagerTabs.vue';
import UsersCreditsManagerTabsContent from './UsersCreditsManagerTabsContent.vue';
import Modal from '@/components/Modal.vue';
import Input from '@/components/Input.vue';
import { json } from 'overmind';
let UserCreditsModal = class UserCreditsModal extends Vue {
    constructor() {
        super(...arguments);
        this.loadingMessage = 'As informações estão sendo carregadas';
        this.tab = 'adicionarCreditos';
        this.loadModal = '';
        this.creditsKey = 0;
        this.height = 675;
        this.width = 775;
        this.noFooter = false;
    }
    mounted() {
        // Transferindo o valor do prompt para uma variavel que possa ser alterada
        this.loadModal = json(this.statusModalCredits);
        /* Alterando o valor para "receiving", pq o comportamento deve ser esse,
        porem via prop não funciona se abrir o modal do mesmo usuario mais de uma vez */
        this.loadModal = 'receiving';
        this.creditsKey = 0;
    }
    updateCredits() {
        //@ts-ignore
        this.$refs.usersCreditsManagerTabsContent.editCredits();
    }
    closeModal() {
        this.showModal = false;
        return true;
    }
    onStatusModalCreditsChange(currVal) {
        this.loadModal = currVal;
    }
    handleModalFooter(activeTab) {
        if (activeTab === 'historicoConsumo' || activeTab === 'atualizarCreditos')
            this.noFooter = true;
        else
            this.noFooter = false;
    }
};
__decorate([
    PropSync('open', { default: false })
], UserCreditsModal.prototype, "showModal", void 0);
__decorate([
    Prop({ default: false })
], UserCreditsModal.prototype, "newCreditsValid", void 0);
__decorate([
    Prop({ default: '' })
], UserCreditsModal.prototype, "statusModalCredits", void 0);
__decorate([
    Prop({ default: () => { } })
], UserCreditsModal.prototype, "user", void 0);
__decorate([
    Emit('closeModal')
], UserCreditsModal.prototype, "closeModal", null);
__decorate([
    Watch('statusModalCredits')
], UserCreditsModal.prototype, "onStatusModalCreditsChange", null);
UserCreditsModal = __decorate([
    Component({
        name: 'UserCreditsModal',
        components: {
            Modal,
            Input,
            InnerLoading,
            UsersCreditsManagerTabs,
            UsersCreditsManagerTabsContent,
        },
    })
], UserCreditsModal);
export default UserCreditsModal;
