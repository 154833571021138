var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import AccountManagementCreditsForm from '@/modules/admin/components/AccountManagementModal/AccountManagementCreditsTab/AccountManagementCreditsForm.vue';
import { CreditOperation } from '@/interfaces/admin.interfaces';
import { AdminStatus } from '@/overmind/admin/state';
let AccountManagementCreditsTab = class AccountManagementCreditsTab extends Vue {
    creditsChange({ credit, }) {
        this.$emit('creditsChange', credit);
    }
};
__decorate([
    PropSync('operation', { default: CreditOperation.ADD })
], AccountManagementCreditsTab.prototype, "creditOperation", void 0);
__decorate([
    Prop({ default: () => { } })
], AccountManagementCreditsTab.prototype, "credits", void 0);
__decorate([
    Prop({ default: AdminStatus.IDLE })
], AccountManagementCreditsTab.prototype, "status", void 0);
AccountManagementCreditsTab = __decorate([
    Component({
        components: {
            AccountManagementCreditsForm,
        },
    })
], AccountManagementCreditsTab);
export default AccountManagementCreditsTab;
