var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AdminStatus } from '@/overmind/admin/state';
import Input from '@/components/Input.vue';
import UsersSkeleton from '@/modules/admin/components/AccountManagementModal/AccountManagementUsersTab/UsersSkeleton.vue';
import UsersListItem from '@/modules/admin/components/AccountManagementModal/AccountManagementUsersTab/UsersListItem.vue';
import UsersEmpty from '@/modules/admin/components/AccountManagementModal/AccountManagementUsersTab/UsersEmpty.vue';
let UsersList = class UsersList extends Vue {
    constructor() {
        super(...arguments);
        this.columns = [
            { type: 'usuário', label: 'Usuário' },
            { type: 'status', label: 'Status' },
            { type: 'level', label: 'Nível' },
        ];
        this.usersTotalCount = this.allowedUsersTotal;
        this.edittingAllowedUsersTotal = false;
    }
    onAllowedUsersTotalChange(newValue, oldValue) {
        this.usersTotalCount = newValue;
    }
    setAllowedUsersTotal() {
        this.edittingAllowedUsersTotal = false;
        this.$emit('updateAllowedUsers', Number(this.usersTotalCount));
    }
    resetAllowedUsersTotal() {
        this.usersTotalCount = this.allowedUsersTotal;
        this.edittingAllowedUsersTotal = false;
    }
    showUser(user) {
        this.$emit('showUser', user);
    }
};
__decorate([
    Prop({ default: AdminStatus.IDLE })
], UsersList.prototype, "status", void 0);
__decorate([
    Prop({ default: 1 })
], UsersList.prototype, "allowedUsersTotal", void 0);
__decorate([
    Prop({ default: () => [] })
], UsersList.prototype, "users", void 0);
__decorate([
    Watch('allowedUsersTotal')
], UsersList.prototype, "onAllowedUsersTotalChange", null);
UsersList = __decorate([
    Component({
        components: {
            Input,
            UsersListItem,
            UsersSkeleton,
            UsersEmpty,
        },
    })
], UsersList);
export default UsersList;
