var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
let InfosetsTableTab = class InfosetsTableTab extends Vue {
    is_validation_done(validation_status) {
        if (validation_status == 'not initialized')
            return 'Não';
        return 'Sim';
    }
    infoset_url(infoset_id) {
        return window.location.origin + '/infosets/' + infoset_id;
    }
    search_url(search_id) {
        return window.location.origin + '/search/' + search_id;
    }
};
__decorate([
    Prop({
        default: () => ({
            id: 0,
            name: '',
            email_validation: '',
            phone_validation: '',
        }),
    })
], InfosetsTableTab.prototype, "infosets", void 0);
InfosetsTableTab = __decorate([
    Component({})
], InfosetsTableTab);
export default InfosetsTableTab;
