var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column q-px-md q-pt-sm",staticStyle:{"height":"200px"}},[_c('div',{staticClass:"col"},[_c('header',{style:(_vm.headerStyle),attrs:{"data-cy":"account-header-summary-owner-info"}},[_c('h2',{staticClass:"ellipsis"},[_vm._v("\n        "+_vm._s(_vm.getOwnerName())+"\n        "),_c('q-tooltip',{attrs:{"content-style":"font-size:14px; background-color:#9EB3DC; min-width:180px; padding:.8rem;"}},[_c('span',[_vm._v(" "+_vm._s(_vm.getOwnerName())+" ")])])],1),_c('div',{staticClass:"account-email font-16 text-weight-regular ellipsis",class:{
          'text-grey-10': _vm.hasEmail,
          'text-red-5': !_vm.hasEmail,
        },staticStyle:{"width":"90%","max-width":"auto"},attrs:{"data-cy":"account-header-summary-owner-info--email"}},[_vm._v("\n        "+_vm._s(_vm.accountPreferenceEmail())+"\n        "),_c('q-tooltip',{attrs:{"content-style":"font-size:14px; background-color:#9EB3DC; min-width:180px; padding:.8rem;"}},[_c('span',[_vm._v(" "+_vm._s(_vm.accountPreferenceEmail())+" ")])])],1),_c('div',{staticClass:"account-plan-name ellipsis",staticStyle:{"width":"90%","max-width":"auto"}},[_vm._v("\n        Plano \""+_vm._s(_vm.account.planName)+"\"\n        "),_c('q-tooltip',{attrs:{"content-style":"font-size:14px; background-color:#9EB3DC; min-width:180px; padding:.8rem;"}},[_c('span',[_vm._v(" Plano \""+_vm._s(_vm.account.planName)+"\" ")])])],1)])]),_c('div',{staticClass:"account-modal-header-summary-totals"},[_c('div',{staticClass:"row q-pb-xs"},[_c('div',{staticClass:"account-users-count"},[_c('q-icon',{staticClass:"q-ml-none q-mr-sm",staticStyle:{"width":"16px","height":"16px"},attrs:{"name":"icon-group"}}),_vm._v(_vm._s(_vm.account.usersCount)+"\n        usuário(s)\n      ")],1)]),_c('div',{staticClass:"row q-pb-md",class:{
        'justify-between': _vm.$q.screen.lg || _vm.$q.screen.gt.lg,
        'justify-around': _vm.$q.screen.md,
        'justify-start': _vm.$q.screen.lt.md || _vm.$q.screen.lt.lg,
      }},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"row items-center justify-center",class:{ 'q-pr-xs': _vm.$q.screen.md }},[_c('div',{staticClass:"col-auto q-pr-xs"},[_c('q-icon',{attrs:{"name":"icon-clicks"}})],1),_c('div',{staticClass:"col click-total",staticStyle:{"display":"grid"}},[_c('div',{staticClass:"text-weight-bold",class:{
                'font-18': _vm.clickCredits() === 'Ilimitado',
                'font-24': _vm.clickCredits() !== 'Ilimitado',
              }},[_vm._v("\n              "+_vm._s(_vm.clickCredits())+"\n            ")]),_c('span',{staticClass:"font-13 text-weight-light"},[_vm._v(" Cliques ")])])])]),_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"row justify-center fit"},[_c('q-separator',{attrs:{"vertical":""}})],1)]),_c('div',{staticClass:"col q-pl-sm"},[_c('div',{staticClass:"row items-center justify-center",class:{
            'q-pl-xs': _vm.$q.screen.md && _vm.$q.screen.width > 1280,
            'q-pl-md': _vm.$q.screen.lg || _vm.$q.screen.gt.lg,
          }},[_c('div',{staticClass:"col-auto q-pr-xs"},[_c('q-icon',{attrs:{"name":"icon-exports","size":"22px"}})],1),_c('div',{staticClass:"col export-total",staticStyle:{"display":"grid"}},[_c('div',{staticClass:"font-24 text-weight-bold"},[_vm._v("\n              "+_vm._s(_vm.credits.export)+"\n            ")]),_c('span',{staticClass:"font-13 text-weight-light"},[_vm._v(" Exportações ")])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }