var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, PropSync, Prop } from 'vue-property-decorator';
import AddCreditsTabContainer from '@/modules/admin/containers/AddCreditsTab.container.vue';
import UsersCreditsHistoryTabContainer from '@/modules/admin/containers/UsersCreditsHistoryTab.container.vue';
import UsersUpdateCreditsTabContainer from '@/modules/admin/containers/UsersUpdateCreditsTab.container.vue';
let UsersCreditsManagerTabsContent = class UsersCreditsManagerTabsContent extends Vue {
    editCredits() {
        //@ts-ignore
        this.$refs.addCreditsContainer.editCredits();
    }
};
__decorate([
    PropSync('currentTab', { default: 'adicionarCreditos' })
], UsersCreditsManagerTabsContent.prototype, "tab", void 0);
__decorate([
    Prop({ default: () => { } })
], UsersCreditsManagerTabsContent.prototype, "user", void 0);
UsersCreditsManagerTabsContent = __decorate([
    Component({
        components: {
            AddCreditsTabContainer,
            UsersCreditsHistoryTabContainer,
            UsersUpdateCreditsTabContainer,
        },
    })
], UsersCreditsManagerTabsContent);
export default UsersCreditsManagerTabsContent;
