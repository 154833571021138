var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { formatDateToBR } from '@/shared/utils/helpers';
import { json } from 'overmind';
let UsersCreditsValidTableRow = class UsersCreditsValidTableRow extends Vue {
    get dateFormated() {
        return this.validCredits.expiresDate
            ? formatDateToBR(json(this.validCredits.expiresDate))
            : '';
    }
    openUpdateCreditsModal() {
        return this.validCredits;
    }
};
__decorate([
    Prop({ default: () => { } })
], UsersCreditsValidTableRow.prototype, "validCredits", void 0);
__decorate([
    Emit('openUpdateCreditsModal')
], UsersCreditsValidTableRow.prototype, "openUpdateCreditsModal", null);
UsersCreditsValidTableRow = __decorate([
    Component({
        name: 'UsersCreditsValidTableRow',
    })
], UsersCreditsValidTableRow);
export default UsersCreditsValidTableRow;
