import { render, staticRenderFns } from "./CreditsHistoryDetails.container.vue?vue&type=template&id=4f648300&scoped=true&"
import script from "./CreditsHistoryDetails.container.vue?vue&type=script&lang=ts&"
export * from "./CreditsHistoryDetails.container.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f648300",
  null
  
)

export default component.exports