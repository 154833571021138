var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator';
import Input from 'components/Input.vue';
import { CreditOperation } from '@/interfaces/admin.interfaces';
import { date } from 'quasar';
import { formatDateToBR } from '@/shared/utils/helpers';
let AddCreditsTab = class AddCreditsTab extends Vue {
    constructor() {
        super(...arguments);
        this.selectedPlanDate = '';
        this.currentPlanDate = '';
        this.basicSearchCredits = null;
        this.internalExportCredits = null;
        this.changeDescription = '';
        this.basicCreditsValid = false;
        this.exportCreditsValid = false;
        this.planExpired = false;
    }
    mounted() {
        this.sumDefaultDate();
        this.checkExpirePlanIsValid();
    }
    get clicksTotalValid() {
        if (!this.basicSearchCredits)
            return true;
        return this.validCredits.simpleData >= this.basicSearchCredits;
    }
    get shouldDisableClickInput() {
        return this.hasUnlimitedClick || this.validCredits.unlimitedClick;
    }
    get disabledUpdateExpireDate() {
        return this.operation === 'remove_credit' ? true : false;
    }
    dateExpiresDisabledBeforeToday(date) {
        const today = new Date();
        return date < today;
    }
    onHasUnlimitedClickChange() {
        this.basicSearchCredits = null;
    }
    onOperationChange() {
        this.triggerInputValidations();
    }
    onValidCreditsChange(newCredits, oldCredits) {
        this.hasUnlimitedClick = newCredits.unlimitedClick ? true : false;
    }
    clearInputs(currVal) {
        if (currVal) {
            this.basicSearchCredits = null;
            this.internalExportCredits = null;
            this.clearInputAddCredits = false;
            this.changeDescription = '';
        }
    }
    checkChangeBasicSearcCredits() {
        this.$emit('setCredits');
    }
    triggerInputValidations() {
        // @ts-ignore
        this.$refs.clickInput.$refs.qinput.validate();
        // @ts-ignore
        this.$refs.exportInput.$refs.qinput.validate();
    }
    buildCreditsData() {
        return {
            basicSearchCredits: !this.basicSearchCredits
                ? 0
                : this.basicSearchCredits,
            internalExportCredits: !this.internalExportCredits
                ? 0
                : this.internalExportCredits,
            expiresDate: this.selectedPlanDate,
            description: this.changeDescription,
        };
    }
    unlimitedClickChange(value) {
        this.$emit('unlimitedClickChange', value);
    }
    basicCredit(val) {
        this.basicSearchCredits = val;
    }
    internalCredit(val) {
        this.internalExportCredits = val;
    }
    validateMinClickCredits(val) {
        this.$emit('setCredits', {
            click: this.basicSearchCredits || 0,
            export: this.internalExportCredits || 0,
            description: this.changeDescription,
        });
        if (val > 0 ||
            (val === '' &&
                this.basicSearchCredits !== null &&
                this.basicSearchCredits > 0)) {
            return true;
        }
        return val === null || val === '' || 'O valor precisa ser maior que zero';
    }
    validatedMaxClickCredits(val) {
        this.$emit('setCredits', {
            click: this.basicSearchCredits || 0,
            export: this.internalExportCredits || 0,
            description: this.changeDescription,
        });
        if (this.operation === 'add_credit')
            return true;
        if (this.validCredits.unlimitedClick)
            return true;
        if (this.validCredits && this.validCredits.simpleData >= val) {
            return true;
        }
        return 'O valor não pode ser maior que o total';
    }
    validateMinExportCredits(val) {
        this.$emit('setCredits', {
            click: this.basicSearchCredits || 0,
            export: this.internalExportCredits || 0,
        });
        if (val >= 0 &&
            this.internalExportCredits !== null &&
            this.internalExportCredits > 0) {
            return true;
        }
        return val === null || val === '' || 'O valor precisa ser maior que zero';
    }
    validatedMaxExportCredits(val) {
        this.$emit('setCredits', {
            click: this.basicSearchCredits || 0,
            export: this.internalExportCredits || 0,
            description: this.changeDescription,
        });
        if (this.operation === 'add_credit')
            return true;
        if (this.validCredits && this.validCredits.internalExport >= val) {
            return true;
        }
        return val === null || 'O valor não pode ser maior que o total';
    }
    sumDefaultDate() {
        let dateDefault = new Date();
        let day = dateDefault.getDate();
        dateDefault.setDate(day + 33);
        this.selectedPlanDate = formatDateToBR(date.formatDate(dateDefault.toString(), 'YYYY-MM-DD'));
    }
    onPlanDateChange(currVal) {
        if (currVal) {
            this.currentPlanDate = this.reverseDateString(currVal.replace(/\//g, '-'));
            this.checkExpirePlanIsValid();
        }
    }
    reverseDateString(dateString) {
        return dateString.split('-').reverse().join('-');
    }
    checkExpirePlanIsValid() {
        this.planExpired =
            Date.now() > new Date(this.currentPlanDate.replace(/-/g, ',')).getTime();
    }
};
__decorate([
    Prop({ default: () => { } })
], AddCreditsTab.prototype, "validCredits", void 0);
__decorate([
    PropSync('clearInput', { default: false })
], AddCreditsTab.prototype, "clearInputAddCredits", void 0);
__decorate([
    PropSync('unlimitedClick', { default: false })
], AddCreditsTab.prototype, "hasUnlimitedClick", void 0);
__decorate([
    PropSync('creditOperation', { default: CreditOperation.ADD })
], AddCreditsTab.prototype, "operation", void 0);
__decorate([
    Watch('hasUnlimitedClick')
], AddCreditsTab.prototype, "onHasUnlimitedClickChange", null);
__decorate([
    Watch('operation')
], AddCreditsTab.prototype, "onOperationChange", null);
__decorate([
    Watch('validCredits')
], AddCreditsTab.prototype, "onValidCreditsChange", null);
__decorate([
    Watch('clearInputAddCredits')
], AddCreditsTab.prototype, "clearInputs", null);
__decorate([
    Watch('basicSearchCredits')
], AddCreditsTab.prototype, "checkChangeBasicSearcCredits", null);
__decorate([
    Watch('selectedPlanDate')
], AddCreditsTab.prototype, "onPlanDateChange", null);
AddCreditsTab = __decorate([
    Component({
        components: { Input },
    })
], AddCreditsTab);
export default AddCreditsTab;
