var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, PropSync, Watch } from 'vue-property-decorator';
import RecentEventsModal from '@/modules/admin/components/RecentEvents/Modal.vue';
import { connect } from '@/overmind';
let RecentEventsModalContainer = class RecentEventsModalContainer extends Vue {
    constructor() {
        super(...arguments);
        this.recentEvents = {
            data: {
                value: {
                    recent_events: [],
                },
            },
        };
    }
    async getRecentEvents() {
        // @ts-ignore
        return await this.actions.recentEvents();
    }
    async onOpenModalChanged(newVal, oldVal) {
        if (newVal) {
            this.recentEvents = await this.getRecentEvents();
            this.intervalId = setInterval(async () => {
                // @ts-ignore
                this.recentEvents = undefined;
                // @ts-ignore
                this.recentEvents = await this.getRecentEvents();
            }, 1000 * 60 * 2);
        }
        else {
            clearInterval(this.intervalId);
        }
    }
};
__decorate([
    PropSync('open', { default: false })
], RecentEventsModalContainer.prototype, "showRecentEventsModal", void 0);
__decorate([
    Watch('open')
], RecentEventsModalContainer.prototype, "onOpenModalChanged", null);
RecentEventsModalContainer = __decorate([
    Component(connect(({ actions: { admin: actions } }) => ({
        actions: actions,
    }), {
        name: 'RecentEventsModalContainer',
        components: { RecentEventsModal },
    }))
], RecentEventsModalContainer);
export default RecentEventsModalContainer;
