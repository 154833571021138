var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Modal from '@/components/Modal.vue';
import { formatDateToBR } from '@/shared/utils/helpers';
import { Component, Emit, Vue, Prop, PropSync } from 'vue-property-decorator';
import DateRangePicker from '@/components/DateRangePicker.vue';
let ChurnData = class ChurnData extends Vue {
    constructor() {
        super(...arguments);
        this.groupByOption = 'month';
        this.emails = '';
        this.dateRange = [
            new Date(new Date().setMonth(new Date().getMonth() - 2)),
            new Date(),
        ];
        this.dateRangeLabel = 'Selecionado os últimos 90 dias';
        this.valuesDate = [];
    }
    getEmails() {
        const emails = this.emails
            .split(' ')
            .filter(e => e != '')
            .map(e => e.toLowerCase());
        const date = this.dateRange.map(dt => dt.toDateString());
        this.emails = '';
        return { emails, rangeDate: date };
    }
    changeDateRange(values, newDateRangeLabel) {
        this.setDateRangeLabel(values, newDateRangeLabel);
        this.closeDatePickerMenu();
        this.dateRange = values;
    }
    setDateRangeLabel(values, newDateRangeLabel) {
        if (!newDateRangeLabel) {
            const beginDateLabel = formatDateToBR(new Date(values[0]).toISOString(), '-');
            const endDateLabel = formatDateToBR(new Date(values[1]).toISOString(), '-');
            this.dateRangeLabel = `${beginDateLabel} - ${endDateLabel}`;
        }
        else {
            this.dateRangeLabel = newDateRangeLabel;
        }
    }
    closeDatePickerMenu() {
        // @ts-ignore
        this.$refs.dropdownMenu.hide();
    }
    initializeRangeLabel() {
        const initDate = new Date(this.dateRange[0]);
        let endDate = new Date(this.dateRange[1]);
        endDate = new Date(endDate.valueOf() - endDate.getTimezoneOffset() * 60000);
        this.setDateRangeLabel([initDate, endDate], '');
    }
};
__decorate([
    PropSync('open', { default: false })
], ChurnData.prototype, "showChurnData", void 0);
__decorate([
    Prop({ default: false })
], ChurnData.prototype, "loading", void 0);
__decorate([
    Emit('getEmails')
], ChurnData.prototype, "getEmails", null);
ChurnData = __decorate([
    Component({
        name: 'ChurnData',
        components: {
            Modal,
            DateRangePicker,
        },
    })
], ChurnData);
export default ChurnData;
