var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import CreditsHistory from '@/modules/admin/components/AccountManagementModal/AccountManagementeCreditsConsumptionTab/CreditsHistory.vue';
import { connect } from '@/overmind';
let CreditsHistoryContainer = class CreditsHistoryContainer extends Vue {
    constructor() {
        super(...arguments);
        this.page = 1;
    }
    created() {
        // @ts-ignore TS2349: property inexistent
        this.actions.setCreditsConsumptionsListPage(this.page);
    }
    destroyed() {
        // @ts-ignore TS2349: property inexistent
        this.actions.setCreditsConsumptionsListPage(1);
        // @ts-ignore TS2349: property inexistent
        this.actions.resetCreditConsumptionsState();
        // @ts-ignore
        this.actions.setAccountModalRightDrawer('');
    }
    async getHistory(withLoading = true, page = 1) {
        // @ts-ignore TS2349: property inexistent
        await this.actions.getCreditsHistory({
            // @ts-ignore
            accountId: this.selectedUserTable.accountId,
            // @ts-ignore
            initDate: new Date(this.filters.period.initDate),
            // @ts-ignore
            endDate: new Date(this.filters.period.endDate),
            page,
            withLoading,
        });
    }
    async handleAuditsListScroll(e) {
        const list = e.target;
        if (list) {
            // @ts-ignore
            let isBottom = list.scrollTop + list.offsetHeight === list.scrollHeight;
            // @ts-ignore
            if (isBottom && this.status !== 'receiving') {
                // @ts-ignore TS2349: property inexistent
                await this.actions.setCreditsConsumptionsListPage(this.pageState + 1);
                // @ts-ignore TS2349: property inexistent
                await this.getHistory(true, this.pageState);
            }
        }
    }
    showDetails(payload) {
        // @ts-ignore
        this.actions.setCreditConsumptionsDetailsInfo({
            creditType: payload.creditType,
            periodLabel: payload.period,
        });
        // @ts-ignore
        this.actions.getCreditConsumptionsDetails({
            // @ts-ignore
            accountId: this.currentAccountId,
            // @ts-ignore
            creditType: this.details.creditType,
            // @ts-ignore
            period: payload.period,
        });
        // @ts-ignore
        this.actions.setAccountModalRightDrawer('ConsumptionsDetail');
    }
};
CreditsHistoryContainer = __decorate([
    Component(connect(({ state: { admin: state }, actions: { admin: actions } }) => ({
        creditsHistory: state.creditConsumptions.data,
        filters: state.creditConsumptions.filters,
        status: state.creditConsumptions.status,
        pageState: state.creditConsumptions.page,
        resetScroll: state.creditConsumptions.resetScroll,
        details: state.creditConsumptions.details,
        currentAccountId: state.currentAccountId,
        selectedUserTable: state.selectedUserTable,
        actions: actions,
    }), {
        components: {
            CreditsHistory,
        },
    }))
], CreditsHistoryContainer);
export default CreditsHistoryContainer;
