var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import AccountManagementHealthscoreTab from '@/modules/admin/components/AccountManagementModal/AccountManagementHealthscoreTab/AccountManagementHealthscoreTab.vue';
import { connect } from '@/overmind';
import { Component, Vue } from 'vue-property-decorator';
let AccountManagementHealthscoreTabContainer = class AccountManagementHealthscoreTabContainer extends Vue {
    constructor() {
        super(...arguments);
        this.credits_and_healthscore = {
            category: 'Sem Score',
            total_healthscore: 0,
            credits: [],
        };
    }
    async mounted() {
        this.credits_and_healthscore = await this.getCreditsUsedOnHealthscore();
        // @ts-ignore
        if (this.credits_and_healthscore)
            // @ts-ignore
            this.credits_and_healthscore = this.credits_and_healthscore.data.result;
    }
    async getCreditsUsedOnHealthscore() {
        // @ts-ignore
        return this.actions.getCreditsUsedOnHealthscore(
        // @ts-ignore
        this.selectedUserTable.accountId);
    }
};
AccountManagementHealthscoreTabContainer = __decorate([
    Component(connect(({ state: { admin: state }, actions: { admin: actions } }) => ({
        selectedUserTable: state.selectedUserTable,
        actions: actions,
    }), {
        components: {
            AccountManagementHealthscoreTab,
        },
    }))
], AccountManagementHealthscoreTabContainer);
export default AccountManagementHealthscoreTabContainer;
