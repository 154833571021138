var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
import Tootltip from '@/components/Tooltip.vue';
let CircularProgress = class CircularProgress extends Vue {
    setDecimal() {
        return !this.isDecimal ? this.percentage : this.percentage * 10;
    }
    trackColors() {
        return this.isDecimal && this.percentage <= 3
            ? 'red'
            : this.isDecimal && this.percentage > 3 && this.percentage <= 5
                ? 'yellowamber'
                : this.isDecimal && this.percentage > 5 && this.percentage <= 8
                    ? 'yellowgreen'
                    : this.isDecimal && this.percentage > 8 && this.percentage < 10
                        ? 'bluegreen'
                        : this.isDecimal && this.percentage == 10
                            ? 'absolutezero'
                            : this.pieColor;
    }
};
__decorate([
    Prop({ default: 0 })
], CircularProgress.prototype, "percentage", void 0);
__decorate([
    Prop({ default: '' })
], CircularProgress.prototype, "tooltip", void 0);
__decorate([
    Prop({ default: 'primary' })
], CircularProgress.prototype, "pieColor", void 0);
__decorate([
    Prop({ default: '11rem' })
], CircularProgress.prototype, "pierSize", void 0);
__decorate([
    Prop({ default: '2.25rem' })
], CircularProgress.prototype, "fontSize", void 0);
__decorate([
    Prop({ default: '#868686' })
], CircularProgress.prototype, "textColor", void 0);
__decorate([
    Prop({ default: 'grey-4' })
], CircularProgress.prototype, "trackColor", void 0);
__decorate([
    Prop({ default: false })
], CircularProgress.prototype, "noIcon", void 0);
__decorate([
    Prop({ default: false })
], CircularProgress.prototype, "noSymbol", void 0);
__decorate([
    Prop({ default: false })
], CircularProgress.prototype, "isDecimal", void 0);
CircularProgress = __decorate([
    Component({
        name: 'CircularProgress',
        components: {
            Tootltip,
        },
    })
], CircularProgress);
export default CircularProgress;
