var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Modal from '@/components/Modal.vue';
import { Prop, PropSync, Vue, Component, Emit, Watch, } from 'vue-property-decorator';
import LoadingDots from '@/components/LoadingDots.vue';
let AddAdditionalModal = class AddAdditionalModal extends Vue {
    constructor() {
        super(...arguments);
        this.additionals = [];
    }
    mixAdditionals() {
        // @ts-ignore
        this.availableAdditions.apps.forEach(app => this.additionals.push(app));
        // @ts-ignore
        this.availableAdditions.filters.forEach(filter => 
        // @ts-ignore
        this.additionals.push(filter));
    }
    addAdditional(additional_id) {
        this.show = false;
    }
    onAvailableAdditionsChanged(oldVal, newVal) {
        this.mixAdditionals();
    }
};
__decorate([
    PropSync('open', { default: false })
], AddAdditionalModal.prototype, "show", void 0);
__decorate([
    Prop({ default: () => [{}] })
], AddAdditionalModal.prototype, "availableAdditions", void 0);
__decorate([
    Emit('addAdditional')
], AddAdditionalModal.prototype, "addAdditional", null);
__decorate([
    Watch('availableAdditions')
], AddAdditionalModal.prototype, "onAvailableAdditionsChanged", null);
AddAdditionalModal = __decorate([
    Component({
        name: 'AddAdditionalModal',
        components: { Modal, LoadingDots },
    })
], AddAdditionalModal);
export default AddAdditionalModal;
