var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import UsersDetail from '@/modules/admin/components/AccountManagementModal/AccountManagementUsersTab/UsersDetail.vue';
import { connect } from '@/overmind';
let UsersDetailContainer = class UsersDetailContainer extends Vue {
    async changeName(name) {
        // @ts-ignore TS2349: property inexistent
        this.actions.setUserEditStatus({ edittingName: true });
        // @ts-ignore TS2349: property inexistent
        await this.actions.updatedSubUser({
            // @ts-ignore
            id: this.user.id,
            // @ts-ignore
            accountId: this.currentAccount.id,
            name: name,
            // @ts-ignore
            email: this.user.email,
            // @ts-ignore
            role: this.user.role,
        });
        // @ts-ignore
        this.actions.setUserEditStatus({ edittingName: false });
    }
    async changeEmail(email) {
        // @ts-ignore
        this.actions.setUserEditStatus({ edittingEmail: true });
        // @ts-ignore TS2349: property inexistent
        await this.actions.updatedSubUser({
            // @ts-ignore
            id: this.user.id,
            // @ts-ignore
            accountId: this.currentAccount.id,
            // @ts-ignore
            name: this.user.name,
            email: email,
            // @ts-ignore
            role: this.user.role,
        });
        // @ts-ignore
        this.actions.setUserEditStatus({ edittingEmail: false });
    }
    async changeRole(role) {
        // @ts-ignore
        this.actions.setUserEditStatus({ edittingRole: true });
        // @ts-ignore TS2349: property inexistent
        await this.actions.updatedSubUser({
            // @ts-ignore
            id: this.user.id,
            // @ts-ignore
            accountId: this.currentAccount.id,
            // @ts-ignore
            name: this.user.name,
            // @ts-ignore
            email: this.user.email,
            // @ts-ignore
            role: role,
        });
        // @ts-ignore
        this.actions.setUserEditStatus({ edittingRole: false });
    }
    async changeStatus(status) {
        // @ts-ignore
        this.actions.setUserEditStatus({ edittingStatus: true });
        // @ts-ignore
        if (status === 'inactive' && !this.user.deletedAt) {
            // @ts-ignore
            await this.actions.revokeUser({ userId: this.user.id });
        }
        else {
            // @ts-ignore
            if (status === 'active' && this.user.deletedAt) {
                // @ts-ignore
                await this.actions.enableUser({ userId: this.user.id });
            }
        }
        // @ts-ignore
        this.actions.setUserEditStatus({ edittingStatus: false });
        // @ts-ignore
        await this.actions.getSubUsers(this.currentAccount.id);
    }
    async updateImg(img) {
        // @ts-ignore
        this.actions.setUserEditStatus({ edittingImg: true });
        // @ts-ignore
        await this.actions.updatedSubUser({
            // @ts-ignore
            id: this.user.id,
            // @ts-ignore
            accountId: this.currentAccount.id,
            // @ts-ignore
            name: this.user.name,
            // @ts-ignore
            email: this.user.email,
            // @ts-ignore
            role: this.user.role,
            img,
        });
        // @ts-ignore
        this.actions.setUserEditStatus({ edittingImg: false });
    }
};
UsersDetailContainer = __decorate([
    Component(connect(({ state: { admin: state }, actions: { admin: actions } }) => ({
        currentAccount: state.currentAccount,
        user: state.selectedUser,
        userEditStatus: state.userEditStatus,
        actions: actions,
    }), {
        components: {
            UsersDetail,
        },
    }))
], UsersDetailContainer);
export default UsersDetailContainer;
