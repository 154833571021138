var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import { connect } from '@/overmind';
import AccountManagementNavbar from '@/modules/admin/components/AccountManagementModal/AccountManagementNavbar.vue';
let AccountManagementNavbarContainer = class AccountManagementNavbarContainer extends Vue {
    changeTab(tab) {
        // @ts-ignore
        this.actions.setAccountModalTab(tab);
    }
};
AccountManagementNavbarContainer = __decorate([
    Component(connect(({ state: { admin: state }, actions }) => ({
        tab: state.accountModal.currentTab,
        actions: actions.admin,
    }), {
        components: {
            AccountManagementNavbar,
        },
    }))
], AccountManagementNavbarContainer);
export default AccountManagementNavbarContainer;
