import { render, staticRenderFns } from "./AddCreditsTab.vue?vue&type=template&id=6ed8e63d&scoped=true&"
import script from "./AddCreditsTab.vue?vue&type=script&lang=ts&"
export * from "./AddCreditsTab.vue?vue&type=script&lang=ts&"
import style0 from "./AddCreditsTab.vue?vue&type=style&index=0&id=6ed8e63d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ed8e63d",
  null
  
)

export default component.exports