var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop } from 'vue-property-decorator';
import UsersCreditsHistoryTab from '@/modules/admin/components/credits/UsersCreditsHistoryTab.vue';
import { connect } from '@/overmind';
import { AdminStatus } from '@/overmind/admin/state';
let UsersCreditsHistoryTabContainer = class UsersCreditsHistoryTabContainer extends Vue {
    async created() {
        // @ts-ignore TS2349: property inexistent
        this.actions.setStatusModalCredits(AdminStatus.RECEIVING);
        // @ts-ignore TS2349: property inexistent
        await this.actions.getCreditsHistory({ accountId: this.user.accountId });
        // @ts-ignore TS2349: property inexistent
        this.actions.setStatusModalCredits(AdminStatus.RECEIVED);
        setTimeout(() => {
            // @ts-ignore TS2349: property inexistent
            this.actions.setStatusModalCredits(AdminStatus.IDLE);
        }, 2000);
    }
};
__decorate([
    Prop({ default: () => { } })
], UsersCreditsHistoryTabContainer.prototype, "user", void 0);
UsersCreditsHistoryTabContainer = __decorate([
    Component(connect(({ state: { admin: state }, actions: { admin: actions } }) => ({
        creditHistory: state.creditHistory,
        actions: actions,
    }), {
        components: { UsersCreditsHistoryTab },
    }))
], UsersCreditsHistoryTabContainer);
export default UsersCreditsHistoryTabContainer;
