var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getDateByFormat } from '@/shared/utils/helpers';
let UsersCreditsHistoryTabRow = class UsersCreditsHistoryTabRow extends Vue {
    constructor() {
        super(...arguments);
        this.showCreditsModal = false;
        this.showUserRevokeModal = false;
        this.type = {
            click: 'Cliques',
            unlimited_click: 'Cliques Ilimitados',
            export: 'Exportações',
        };
        this.dateByFormat = {
            month: getDateByFormat(this.creditHistory.period, 'MMMM/YYYY'),
            day: getDateByFormat(this.creditHistory.period, 'dddd, D [de] MMMM/YYYY'),
        };
    }
    get historyBalance() {
        return this.creditHistory.balance === null ||
            this.creditHistory.balance === undefined
            ? 'Ilimitado'
            : this.creditHistory.balance;
    }
};
__decorate([
    Prop({ default: () => { } })
], UsersCreditsHistoryTabRow.prototype, "creditHistory", void 0);
UsersCreditsHistoryTabRow = __decorate([
    Component({
        name: 'UsersCreditsHistoryTabRow',
    })
], UsersCreditsHistoryTabRow);
export default UsersCreditsHistoryTabRow;
