var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop } from 'vue-property-decorator';
import AddCreditsTab from '@/modules/admin/components/credits/AddCreditsTab.vue';
import { connect } from '@/overmind';
import { AdminStatus } from '@/overmind/admin/state';
import { CreditOperation } from '@/interfaces/admin.interfaces';
let AddCreditsTabContainer = class AddCreditsTabContainer extends Vue {
    constructor() {
        super(...arguments);
        this.clearInputAddCredits = false;
        this.creditOperation = CreditOperation.ADD;
        this.unlimitedClick = false;
    }
    unlimitedClickChange(value) {
        //@ts-ignore
        this.actions.setUnlimitedClickChange(value);
    }
    async editCredits() {
        //@ts-ignore
        const creditsData = this.$refs.addCredits.buildCreditsData();
        //@ts-ignore
        await this.actions.editCreditsToAccount({
            userId: this.user.id,
            accountId: this.user.accountId,
            credits: {
                basicSearchCredits: creditsData.basicSearchCredits,
                internalExportCredits: creditsData.internalExportCredits,
                //@ts-ignore
                expiresDate: creditsData.expiresDate,
                unlimitedClick: this.unlimitedClick,
                description: creditsData.description,
            },
            operation: this.creditOperation,
        });
        this.clearInputAddCredits = true;
        await this.initData();
    }
    setCredits(credits) {
        // @ts-ignore TS2349: property inexistent
        this.actions.setCredits({ ...credits, operation: this.creditOperation });
    }
    async initData() {
        // @ts-ignore TS2349: property inexistent
        this.actions.setStatusModalCredits(AdminStatus.RECEIVING);
        // @ts-ignore TS2349: property inexistent
        await this.actions.getAccountCredits(this.user.accountId);
        // @ts-ignore TS2349: property inexistent
        this.actions.setStatusModalCredits(AdminStatus.RECEIVED);
        setTimeout(() => {
            // @ts-ignore TS2349: property inexistent
            this.actions.setStatusModalCredits(AdminStatus.IDLE);
        }, 2000);
    }
    async created() {
        // @ts-ignore
        await this.initData();
    }
    newCreditsValid(isValid) {
        // @ts-ignore
        this.actions.setNewCreditsValid(isValid);
    }
};
__decorate([
    Prop({ default: () => { } })
], AddCreditsTabContainer.prototype, "user", void 0);
AddCreditsTabContainer = __decorate([
    Component(connect(({ state: { admin: state }, actions: { admin: actions } }) => ({
        validCredits: state.validCredits,
        planValidity: state.planValidity,
        actions: actions,
    }), {
        name: 'AddCreditsTabContainer',
        components: { AddCreditsTab },
    }))
], AddCreditsTabContainer);
export default AddCreditsTabContainer;
