var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { personNameInitials } from '@/shared/utils/helpers';
import OnboardingUserStatus from '@/modules/admin/components/Onboarding/OnboardingUserStatus.vue';
import OnboardingAdminStatus from '@/modules/admin/components/Onboarding/OnboardingAdminStatus.vue';
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { copyToClipboard } from 'quasar';
import Tooltip from '@/components/Tooltip.vue';
import IconEmail from '@/components/Icons/IconEmail.vue';
import IconBlueSuccess from '@/components/Icons/icon-blue-success.vue';
let OnboardingUserListComponent = class OnboardingUserListComponent extends Vue {
    constructor() {
        super(...arguments);
        this.isEmailCapied = false;
    }
    userNameInitials(name) {
        return personNameInitials(name);
    }
    openUserDetails() {
        return true;
    }
    handleDate() {
        if (!this.createdDate) {
            return '';
        }
        const formatDate = this.createdDate.slice(0, 10).split('-');
        return `${formatDate[2]}/${formatDate[1]}/${formatDate[0]}`;
    }
    copyTextItem(value) {
        this.isEmailCapied = true;
        copyToClipboard(value).then(() => {
            setTimeout(() => {
                this.isEmailCapied = false;
            }, 1000);
        });
    }
    confirmationStatusText(confirmationStatus) {
        switch (confirmationStatus) {
            case 'new':
                return 'Sem Confirmação';
            case 'scheduled':
                return 'Agendado';
            default:
                return 'Confirmado';
        }
    }
};
__decorate([
    Prop({ default: '' })
], OnboardingUserListComponent.prototype, "userName", void 0);
__decorate([
    Prop({ default: '' })
], OnboardingUserListComponent.prototype, "userMail", void 0);
__decorate([
    Prop({ default: '' })
], OnboardingUserListComponent.prototype, "onboardStatus", void 0);
__decorate([
    Prop({ default: null })
], OnboardingUserListComponent.prototype, "createdDate", void 0);
__decorate([
    Prop({ default: 'new' })
], OnboardingUserListComponent.prototype, "onboardCsValidation", void 0);
__decorate([
    Prop({ default: '' })
], OnboardingUserListComponent.prototype, "activated", void 0);
__decorate([
    Prop({ default: false })
], OnboardingUserListComponent.prototype, "filterUsersWithoutOnboadingsActive", void 0);
__decorate([
    Emit('openUserDetails')
], OnboardingUserListComponent.prototype, "openUserDetails", null);
OnboardingUserListComponent = __decorate([
    Component({
        name: 'OnboardingUserListComponent',
        components: {
            OnboardingUserStatus,
            OnboardingAdminStatus,
            IconEmail,
            IconBlueSuccess,
            Tooltip,
        },
    })
], OnboardingUserListComponent);
export default OnboardingUserListComponent;
