var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { getDateTimeByFormat, personNameInitials } from '@/shared/utils/helpers';
import { format } from 'date-fns';
import { Component, Prop, Vue } from 'vue-property-decorator';
let AuditsListItem = class AuditsListItem extends Vue {
    constructor() {
        super(...arguments);
        this.operation = {
            add_credit: 'Adição',
            remove_credit: 'Remoção',
            invalidate_unlimited_credit: 'Remoção',
        };
        this.status = {
            success: 'Sucesso',
            error: 'Erro',
        };
    }
    get authorNameInitials() {
        switch (this.audit.origin) {
            case 'superlogica':
                return 'SL';
            case 'stripe':
                return 'ST';
            case 'system':
                return 'SI';
            default:
                return this.audit.user
                    ? personNameInitials(this.audit.user.name || '')
                    : '';
        }
    }
    handleExpireDateIfExists() {
        return this.audit.params.expiration
            ? this.periodExpirationLabel
            : this.periodDetailsLabel;
    }
    get auditOrigin() {
        switch (this.audit.origin) {
            case 'superlogica':
                return 'Superlógica';
            case 'system':
                return 'Sistema';
            case 'admin':
                return 'Admin';
            case 'referral':
                return 'MGM';
            default:
                return this.audit.origin;
        }
    }
    get authorName() {
        switch (this.audit.origin) {
            case 'superlogica':
                return 'Superlogica';
            case 'stripe':
                return 'Stripe';
            case 'system':
                return 'Sistema';
            default:
                return this.audit.user ? this.audit.user.name : '';
        }
    }
    get authorEmail() {
        return this.audit.user && this.audit.user.email;
    }
    get operationLabel() {
        return (this.operation[this.audit.type] ||
            (this.audit.origin === 'superlogica' ? 'Adição' : ''));
    }
    openDetails() {
        this.$emit('click', this.audit);
    }
    get isSuccess() {
        return this.audit.status === 'success';
    }
    get originIsNotAdmin() {
        return this.columns.find(c => c.type === 'origin');
    }
    get periodDetailsLabel() {
        return getDateTimeByFormat(new Date(this.audit.period), 'DD/MM/YYYY');
    }
    parsedDate() {
        const parsedDate = new Date(this.audit.params.expiration);
        const expirationDate = new Date(parsedDate.valueOf() + parsedDate.getTimezoneOffset() * 60 * 1000);
        return format(expirationDate, 'dd/MM/yyyy');
    }
    get periodExpirationLabel() {
        return this.parsedDate();
    }
    get shouldShowMinusIcon() {
        return (this.audit.type === 'remove_credit' ||
            this.audit.type === 'invalidate_unlimited_credit');
    }
    get shouldShowAddIcon() {
        return this.audit.type === 'add_credit';
    }
};
__decorate([
    Prop({
        default: () => { },
    })
], AuditsListItem.prototype, "audit", void 0);
__decorate([
    Prop({ default: () => [] })
], AuditsListItem.prototype, "columns", void 0);
__decorate([
    Prop({ default: false })
], AuditsListItem.prototype, "isActive", void 0);
AuditsListItem = __decorate([
    Component
], AuditsListItem);
export default AuditsListItem;
