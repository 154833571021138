var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { CreditOperation } from '@/interfaces/admin.interfaces';
import AccountManagementCreditsTab from '@/modules/admin/components/AccountManagementModal/AccountManagementCreditsTab/AccountManagementCreditsTab.vue';
import { connect } from '@/overmind';
import { Component, Vue } from 'vue-property-decorator';
let AccountManagementCreditsTabContainer = class AccountManagementCreditsTabContainer extends Vue {
    constructor() {
        super(...arguments);
        this.creditsOperation = CreditOperation.ADD;
    }
    creditsChange(credits) {
        // @ts-ignore
        this.actions.setCreditsByType({
            type: credits.type,
            amount: credits.amount,
            description: credits.description,
            expiresDate: credits.expiresDate,
        });
        if (this.creditsOperation === CreditOperation.ADD) {
            // @ts-ignore
            this.actions.addCreditsToAccount({
                // @ts-ignore
                userId: this.selectedUserTable.id,
                // @ts-ignore
                accountId: this.selectedUserTable.accountId,
                credits,
            });
        }
        else {
            if (this.creditsOperation === CreditOperation.REMOVE) {
                // @ts-ignore
                this.actions.removeCreditsFromAccount({
                    // @ts-ignore
                    userId: this.selectedUserTable.id,
                    // @ts-ignore
                    accountId: this.selectedUserTable.accountId,
                    credits,
                });
            }
        }
    }
};
AccountManagementCreditsTabContainer = __decorate([
    Component(connect(({ state: { admin: state }, actions: { admin: actions } }) => ({
        actions: actions,
        accountModalTab: state.accountModalTab,
        selectedUserTable: state.selectedUserTable,
        accountCredits: state.accountCredits,
        status: state.credits.status,
    }), {
        components: {
            AccountManagementCreditsTab,
        },
    }))
], AccountManagementCreditsTabContainer);
export default AccountManagementCreditsTabContainer;
