var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import AccountManagementNotificationTab from '@/modules/admin/components/AccountManagementModal/AccountManagementNotificationTab/AccountManagementNotificationTab.vue';
import { connect } from '@/overmind';
import { Component, Vue } from 'vue-property-decorator';
let AccountManagementHealthscoreTabContainer = class AccountManagementHealthscoreTabContainer extends Vue {
    constructor() {
        super(...arguments);
        this.user_notifications = {
            id: '',
            log: {
                status: '',
                user: {
                    message_sent: '',
                    whatsapp_log: '',
                    whatsapp_processed: '',
                    atlas_processed: '',
                    log_datetime: '',
                },
            },
            rule: '',
            notificated: false,
        };
    }
    async mounted() {
        this.user_notifications = await this.getUserNotifications();
        // @ts-ignore
        if (this.user_notifications)
            // @ts-ignore
            this.user_notifications = this.user_notifications.data.notifications;
    }
    async getUserNotifications() {
        // @ts-ignore
        return this.actions.userNotifications(
        // @ts-ignore
        this.selectedUserTable.accountId);
    }
};
AccountManagementHealthscoreTabContainer = __decorate([
    Component(connect(({ state: { admin: state }, actions: { admin: actions } }) => ({
        selectedUserTable: state.selectedUserTable,
        actions: actions,
    }), {
        components: {
            AccountManagementNotificationTab,
        },
    }))
], AccountManagementHealthscoreTabContainer);
export default AccountManagementHealthscoreTabContainer;
