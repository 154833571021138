var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { formatDateToBR } from '@/shared/utils/helpers';
import UsersCreditsValidTableRow from '../credits/UsersCreditsValidTableRow.vue';
import UsersUpdateCreditsModal from '../credits/UsersUpdateCreditsModal.vue';
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { json } from 'overmind';
let UsersUpdateCreditsTab = class UsersUpdateCreditsTab extends Vue {
    constructor() {
        super(...arguments);
        this.showUpdateCreditsModal = false;
    }
    handleDataForUpdated(creditsId, credits) {
        return {
            id: creditsId,
            internalExport: credits.internal_export_credit,
            simpleData: credits.search_credit,
            expiresDate: formatDateToBR(credits.expires_date || ''),
        };
    }
    openUpdateCreditsModal(validCredits) {
        this.validCredits = json(validCredits);
        this.showUpdateCreditsModal = true;
    }
};
__decorate([
    Prop({ default: () => [] })
], UsersUpdateCreditsTab.prototype, "listCredits", void 0);
__decorate([
    Prop({ default: false })
], UsersUpdateCreditsTab.prototype, "updateExpireDate", void 0);
__decorate([
    Emit('handleDataForUpdated')
], UsersUpdateCreditsTab.prototype, "handleDataForUpdated", null);
UsersUpdateCreditsTab = __decorate([
    Component({
        components: {
            UsersCreditsValidTableRow,
            UsersUpdateCreditsModal,
        },
    })
], UsersUpdateCreditsTab);
export default UsersUpdateCreditsTab;
