var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import AccountManagementCreditsConsumptionTab from '@/modules/admin/components/AccountManagementModal/AccountManagementeCreditsConsumptionTab/AccountManagementCreditsConsumptionTab.vue';
import { connect } from '@/overmind';
let AccountManagementCreditsConsumptionTabContainer = class AccountManagementCreditsConsumptionTabContainer extends Vue {
    async getHistory(initDate, endDate) {
        // @ts-ignore TS2349: property inexistent
        await this.actions.getCreditsHistory({
            // @ts-ignore
            accountId: this.selectedUserTable.accountId,
            initDate,
            endDate,
        });
    }
    async created() {
        await this.getHistory();
    }
};
AccountManagementCreditsConsumptionTabContainer = __decorate([
    Component(connect(({ state: { admin: state }, actions: { admin: actions } }) => ({
        creditsConsumption: state.creditConsumptions,
        selectedUserTable: state.selectedUserTable,
        creditHistoryFilters: state.creditHistoryFilters,
        actions: actions,
    }), {
        components: {
            AccountManagementCreditsConsumptionTab,
        },
    }))
], AccountManagementCreditsConsumptionTabContainer);
export default AccountManagementCreditsConsumptionTabContainer;
