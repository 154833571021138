var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import CreditsHistoryDetails from '@/modules/admin/components/AccountManagementModal/AccountManagementeCreditsConsumptionTab/CreditsHistoryDetails.vue';
import { connect } from '@/overmind';
let CreditsHistoryDetailsContainer = class CreditsHistoryDetailsContainer extends Vue {
    changePage(page) {
        // @ts-ignore
        this.actions.getCreditConsumptionsDetails({
            // @ts-ignore
            accountId: this.currentAccountId,
            // @ts-ignore
            creditType: this.details.creditType,
            // @ts-ignore
            period: this.details.periodDate,
            page,
        });
    }
};
CreditsHistoryDetailsContainer = __decorate([
    Component(connect(({ state: { admin: state }, actions: { admin: actions } }) => ({
        details: state.creditConsumptions.details,
        filters: state.creditConsumptions.filters,
        currentAccountId: state.currentAccountId,
        actions: actions,
    }), {
        components: {
            CreditsHistoryDetails,
        },
    }))
], CreditsHistoryDetailsContainer);
export default CreditsHistoryDetailsContainer;
