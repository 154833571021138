var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Emit, Prop } from 'vue-property-decorator';
let AdminFiltersHeaderMenu = class AdminFiltersHeaderMenu extends Vue {
    constructor() {
        super(...arguments);
        this.filterGroup = [
            {
                label: 'Status do Usuário',
                items: [
                    {
                        key: 'user_status',
                        label: 'Habilitado',
                        value: 'active',
                        isSelected: false,
                    },
                    {
                        key: 'user_status',
                        label: 'Desabilitado',
                        value: 'deactivate',
                        isSelected: false,
                    },
                ],
            },
            {
                label: 'Tipo da Conta',
                items: [
                    {
                        key: 'account_status',
                        label: 'Cliente com contrato ativo',
                        value: 'active',
                        isSelected: false,
                    },
                    {
                        key: 'account_status',
                        label: 'Cliente suspenso',
                        value: 'suspended',
                        isSelected: false,
                    },
                    {
                        key: 'account_status',
                        label: 'Cliente em migração',
                        value: 'migrated',
                        isSelected: false,
                    },
                    {
                        key: 'account_status',
                        label: 'Teste',
                        value: 'free',
                        isSelected: false,
                    },
                    {
                        key: 'account_status',
                        label: 'Indefinido',
                        value: 'undefined',
                        isSelected: false,
                    },
                ],
            },
            {
                label: 'Tipo do Usuário',
                items: [
                    { key: 'type_user', label: 'Admin', value: 'admin', isSelected: false },
                    { key: 'type_user', label: 'Owner', value: 'owner', isSelected: false },
                    {
                        key: 'type_user',
                        label: 'Prospector',
                        value: 'prospector',
                        isSelected: false,
                    },
                    {
                        key: 'type_user',
                        label: 'Coordenador',
                        value: 'coordinator',
                        isSelected: false,
                    },
                    {
                        key: 'type_user',
                        label: 'Manager',
                        value: 'manager',
                        isSelected: false,
                    },
                ],
            },
            {
                label: 'Categoria do Usuário',
                items: [
                    {
                        key: 'category_user',
                        label: 'Sem Score',
                        value: 'sem_score',
                        isSelected: false,
                    },
                    {
                        key: 'category_user',
                        label: 'Academia',
                        value: 'academia',
                        isSelected: false,
                    },
                    {
                        key: 'category_user',
                        label: 'Crossfit',
                        value: 'crossfit',
                        isSelected: false,
                    },
                    {
                        key: 'category_user',
                        label: 'Crossfit Premium',
                        value: 'crossfit_premium',
                        isSelected: false,
                    },
                ],
            },
            {
                label: 'CS do Usuário',
                items: this.setCsFilter(),
            },
            {
                label: 'Tempo de assinatura',
                items: [
                    {
                        key: 'first_credit_age',
                        label: '+60 dias',
                        value: '60_days',
                        isSelected: false,
                    },
                    {
                        key: 'first_credit_age',
                        label: '+30 dias',
                        value: '30_days',
                        isSelected: false,
                    },
                    {
                        key: 'first_credit_age',
                        label: 'Todos',
                        value: '0_days',
                        isSelected: false,
                    },
                ],
            },
        ];
    }
    searchUsersCheckboxFilters() {
        return this.filterGroup;
    }
    resetFilters() {
        this.filterGroup.forEach(element => {
            element.items.forEach(item => {
                item.isSelected = false;
            });
        });
        return this.filterGroup;
    }
    setCsFilter() {
        let items = [];
        this.csAndAdmins.forEach(element => {
            items.push({
                key: 'cs_user',
                label: element.label,
                value: element.value,
                isSelected: false,
            });
        });
        return items;
    }
};
__decorate([
    Prop({ default: () => [] })
], AdminFiltersHeaderMenu.prototype, "csAndAdmins", void 0);
__decorate([
    Emit('searchUsersCheckboxFilters')
], AdminFiltersHeaderMenu.prototype, "searchUsersCheckboxFilters", null);
__decorate([
    Emit('resetFilters')
], AdminFiltersHeaderMenu.prototype, "resetFilters", null);
AdminFiltersHeaderMenu = __decorate([
    Component({
        name: 'AdminFiltersHeaderMenu',
    })
], AdminFiltersHeaderMenu);
export default AdminFiltersHeaderMenu;
