var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';
import UserCreditsModal from '@/modules/admin/components/credits/UserCreditsModal.vue';
import { connect } from '@/overmind';
import { json } from 'overmind';
let UserCreditsModalContainer = class UserCreditsModalContainer extends Vue {
    getNewCreditsValid() {
        // @ts-ignore
        return json(this.newCreditsValid);
    }
    closeModal() {
        this.showModal = false;
        // @ts-ignore
        //this.actions.setCreditsHistory([])
    }
};
__decorate([
    PropSync('open', { default: false })
], UserCreditsModalContainer.prototype, "showModal", void 0);
__decorate([
    Prop({ default: () => { } })
], UserCreditsModalContainer.prototype, "user", void 0);
UserCreditsModalContainer = __decorate([
    Component(connect(({ state: { admin: state }, actions: { admin: actions } }) => ({
        actions: actions,
        statusModalCredits: state.statusModalCredits,
        newCreditsValid: state.newCreditsValid,
        validCredits: state.validCredits,
    }), {
        name: 'UserCreditsModalContainer',
        components: {
            UserCreditsModal,
        },
    }))
], UserCreditsModalContainer);
export default UserCreditsModalContainer;
