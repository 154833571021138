var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import AccountManagementCreditsTabContainer from '@/modules/admin/containers/AccountManagementModal/AccountManagementTabs/AccountManagementCreditsTab/AccountManagementCreditsTab.container.vue';
import AccountManagementCreditsConsumptionContainer from '@/modules/admin/containers/AccountManagementModal/AccountManagementTabs/AccountManagementCreditsConsumptionTab/AccountManagementCreditsConsumptionTab.container.vue';
import AccountManagementUsersTabContainer from '@/modules/admin/containers/AccountManagementModal/AccountManagementTabs/AccountManagementUsersTab/AccountManagementUsersTab.container.vue';
import AccountManagementAuditsTabContainer from '@/modules/admin/containers/AccountManagementModal/AccountManagementTabs/AccountManagementAuditsTab/AccountManagementAuditsTab.container.vue';
import AccountManagementAccountInfoTabContainer from '@/modules/admin/containers/AccountManagementModal/AccountManagementTabs/AccountManagementAccountInfoTab/AccountManagementAccountInfoTab.container.vue';
import AccountManagementHealthscoreTabContainer from '@/modules/admin/containers/AccountManagementModal/AccountManagementTabs/AccountManagementHealthscoreTab/AccountManagementHealthscoreTab.container.vue';
import AccountManagementUserBehaviorTabContainer from '@/modules/admin/containers/AccountManagementModal/AccountManagementTabs/AccountManagementUserBehaviorTab/AccountManagementUserBehaviorTab.container.vue';
import AccountManagementNotificationTabContainer from '@/modules/admin/containers/AccountManagementModal/AccountManagementTabs/AccountManagementNotificationTab/AccountManagementNotificationTab.container.vue';
import AccountManagementFiltersTabContainer from '@/modules/admin/containers/AccountManagementModal/AccountManagementTabs/AccountManagementFiltersTab/AccountManagementFiltersTab.container.vue';
import AccountManagementSubscriptionsTabContainer from '@/modules/admin/containers/AccountManagementModal/AccountManagementTabs/AccountManagementSubscriptionsTab/AccountManagementSubscriptionsTab.container.vue';
let AccountManagementMainContent = class AccountManagementMainContent extends Vue {
    constructor() {
        super(...arguments);
        this.creditOperation = 'add';
    }
};
__decorate([
    Prop({ default: 'credits' })
], AccountManagementMainContent.prototype, "tab", void 0);
AccountManagementMainContent = __decorate([
    Component({
        components: {
            AccountManagementCreditsTabContainer,
            AccountManagementCreditsConsumptionContainer,
            AccountManagementAuditsTabContainer,
            AccountManagementUsersTabContainer,
            AccountManagementAccountInfoTabContainer,
            AccountManagementHealthscoreTabContainer,
            AccountManagementUserBehaviorTabContainer,
            AccountManagementNotificationTabContainer,
            AccountManagementFiltersTabContainer,
            AccountManagementSubscriptionsTabContainer,
        },
    })
], AccountManagementMainContent);
export default AccountManagementMainContent;
