var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import AccountManagementUsersTab from '@/modules/admin/components/AccountManagementModal/AccountManagementUsersTab/AccountManagementUsersTab.vue';
import { connect } from '@/overmind';
let AccountManagementUsersTabContainer = class AccountManagementUsersTabContainer extends Vue {
    created() {
        // @ts-ignore
        this.actions.getAccountInformation(this.selectedUserTable.accountId);
    }
};
AccountManagementUsersTabContainer = __decorate([
    Component(connect(({ state: { admin: state }, actions: { admin: actions } }) => ({
        selectedUserTable: state.selectedUserTable,
        actions: actions,
    }), {
        components: {
            AccountManagementUsersTab,
        },
    }))
], AccountManagementUsersTabContainer);
export default AccountManagementUsersTabContainer;
