var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { personNameInitials, abbreviateName, removeHashRevokeUserEmail, } from '@/shared/utils/helpers';
import IconBlueSuccess from '@/components/Icons/icon-blue-success.vue';
import iconResearch from '@/components/Icons/icon-research.vue';
import OnboardingUserStatus from '@/modules/admin/components/Onboarding/OnboardingUserStatus.vue';
import Input from '@/components/Input.vue';
import Tootltip from '@/components/Tooltip.vue';
import { json } from 'overmind';
let OnboardingUserDetailsComponent = class OnboardingUserDetailsComponent extends Vue {
    constructor() {
        super(...arguments);
        this.optionSelected = '';
        this.updateOnboardingSelected = false;
        // showMessage = false
        this.responsibleSelectedCsMember = {
            email: '',
            name: '',
            role: '',
            id: '',
        };
        this.initialState = false;
        this.responsibleUserName = 'Selecionar';
        this.responsibleName = '';
        this.responsiblesListsIsOpen = false;
        this.filterReponsible = '';
        this.filteredResponsiblesUsers = [];
    }
    onCsUserList(newV, oldV) {
        if (newV)
            this.filteredResponsiblesUsers = json(newV);
    }
    handleOpenResponsiblesList() {
        return (this.responsiblesListsIsOpen = true);
    }
    handleCloseResponsiblesList() {
        return (this.responsiblesListsIsOpen = false);
    }
    handleRemoveEmailWithHash(email) {
        return removeHashRevokeUserEmail(email);
    }
    closeUserDetails() {
        this.resetOptionSelected();
        return true;
    }
    resetOptionSelected() {
        this.optionSelected = '';
    }
    userNameInitials(name) {
        return personNameInitials(name);
    }
    isAbbreviateName(name) {
        return abbreviateName(name);
    }
    generateInformText(value) {
        switch (value) {
            case 'scheduled':
                return 'Este usuário agendou o onboarding. Esta informação está correta?';
            case 'already_done':
                return 'Este usuário confirmou já ter feito o onboarding. Esta informação está correta?';
            case 'refused':
                return 'Este usuário recusou o onboarding. Esta informação está correta?';
            default:
                return;
        }
    }
    setOptionSelected(value) {
        this.initialState = false;
        if (this.optionSelected === value) {
            this.optionSelected = '';
        }
        else {
            this.optionSelected = value;
        }
    }
    openOptionSelected(value) {
        return this.optionSelected === value ? true : false;
    }
    compareValue(confirmation) {
        return this.onboardingInformations.user_selected_option === confirmation
            ? true
            : false;
    }
    handleResponsible() {
        const msgDefault = 'Responsável não encontrado';
        if (!this.filterUsersWithoutOnboadingsActive &&
            this.onboardingInformations.confirmation &&
            this.onboardingInformations.confirmation.responsible_id) {
            const responsible_id = this.onboardingInformations.confirmation;
            const responsible = this.csUserList.filter(cs => {
                if (responsible_id.responsible_id &&
                    cs.id === responsible_id.responsible_id.$oid)
                    return cs;
            });
            if (responsible.length > 0)
                return responsible[0].email;
            else
                msgDefault;
        }
        else
            return msgDefault;
    }
    createOnboardingToUserWithoutOnboarding(optionSelected) {
        this.setOptionSelected('success');
        this.closeUserDetails();
        return {
            emailUser: this.userWithoutOnboardingInformations.email,
            selectedOption: optionSelected,
            responsibleSelectedCsMember: this.responsibleSelectedCsMember
                ? this.responsibleSelectedCsMember.id
                : '',
            creationOfOnboardingByAdmin: true,
        };
    }
    saveConfirmation(optionSelected) {
        this.setOptionSelected('success');
        return {
            emailUser: this.onboardingInformations.user.email,
            optionSelected: optionSelected,
            responsibleSelectedCsMember: this.responsibleSelectedCsMember
                ? this.responsibleSelectedCsMember.id
                : '',
            onboardingId: this.onboardingInformations._id
                ? this.onboardingInformations._id
                : '',
        };
    }
    filterResponsiblesUser(v) {
        this.filteredResponsiblesUsers = json(this.csUserList).filter(r => r.name.toLowerCase().match(v.toLowerCase()));
    }
    findCsSelectedUser(val) {
        const csEmail = val.split('Email:')[1];
        return this.csUserList.find(v => v.email === csEmail);
    }
    handleUserSelected(valSelected) {
        this.responsibleSelectedCsMember = this.findCsSelectedUser(valSelected);
        this.responsibleUserName = this.responsibleUserName.split(' ')[1];
    }
    isUserSelected(value) {
        this.responsibleSelectedCsMember = value;
        this.responsibleName = value.name;
        this.responsibleUserName = value.name;
    }
    handleConfirmationMessage(val) {
        switch (val) {
            case 'already_done':
                return 'Yeah! Esse usuário já passou pelo onboarding.';
            case 'scheduled':
                return 'Ops! Já temos um onboarding agendado para este usuário.';
            case 'refused':
                return 'Ops! Esse usuário não quis onboarding.';
            default:
                break;
        }
    }
    hasStatusConfirmation() {
        return this.onboardingInformations &&
            this.onboardingInformations.confirmation &&
            this.onboardingInformations.confirmation.status_confirmation
            ? true
            : false;
    }
    handleUpdateOnboarding() {
        this.updateOnboardingSelected = true;
        this.openOptionSelected('initial');
    }
    onOnboardingInformations(newVal, oldVal) {
        this.initialState = false;
        if (newVal != oldVal) {
            this.responsibleSelectedCsMember = {
                email: '',
                id: '',
                name: '',
                role: '',
            };
            this.responsibleUserName = '';
            this.optionSelected = '';
            this.initialState = true;
            this.updateOnboardingSelected = false;
        }
        else if (newVal !== '' && newVal !== oldVal) {
            this.closeUserDetails();
        }
    }
    onUserWithoutOnboardingInformations(newVal, oldVal) {
        this.initialState = false;
        if (newVal !== '' && newVal !== oldVal) {
            this.responsibleSelectedCsMember = {
                email: '',
                id: '',
                name: '',
                role: '',
            };
            this.responsibleUserName = '';
            this.optionSelected = '';
            this.initialState = true;
            this.updateOnboardingSelected = false;
        }
    }
    mounted() {
        this.updateOnboardingSelected = false;
        this.initialState = false;
    }
};
__decorate([
    Prop({ default: () => [] })
], OnboardingUserDetailsComponent.prototype, "csUserList", void 0);
__decorate([
    Prop({ default: false })
], OnboardingUserDetailsComponent.prototype, "showUserDetails", void 0);
__decorate([
    Prop({ default: false })
], OnboardingUserDetailsComponent.prototype, "userIsSelected", void 0);
__decorate([
    Prop({ default: false })
], OnboardingUserDetailsComponent.prototype, "filterUsersWithoutOnboadingsActive", void 0);
__decorate([
    Prop({ default: () => { } })
], OnboardingUserDetailsComponent.prototype, "onboardingInformations", void 0);
__decorate([
    Prop({ default: () => { } })
], OnboardingUserDetailsComponent.prototype, "userWithoutOnboardingInformations", void 0);
__decorate([
    Watch('csUserList', { immediate: true, deep: true })
], OnboardingUserDetailsComponent.prototype, "onCsUserList", null);
__decorate([
    Emit('closeUserDetails')
], OnboardingUserDetailsComponent.prototype, "closeUserDetails", null);
__decorate([
    Watch('onboardingInformations')
], OnboardingUserDetailsComponent.prototype, "resetOptionSelected", null);
__decorate([
    Emit('createOnboardingToUserWithoutOnboarding')
], OnboardingUserDetailsComponent.prototype, "createOnboardingToUserWithoutOnboarding", null);
__decorate([
    Emit('saveConfirmation')
], OnboardingUserDetailsComponent.prototype, "saveConfirmation", null);
__decorate([
    Watch('onboardingInformations', { immediate: true, deep: true })
], OnboardingUserDetailsComponent.prototype, "onOnboardingInformations", null);
__decorate([
    Watch('userWithoutOnboardingInformations', { immediate: true, deep: true })
], OnboardingUserDetailsComponent.prototype, "onUserWithoutOnboardingInformations", null);
OnboardingUserDetailsComponent = __decorate([
    Component({
        name: 'OnboardingUserDetailsComponent',
        components: {
            OnboardingUserStatus,
            IconBlueSuccess,
            Input,
            iconResearch,
            Tootltip,
        },
    })
], OnboardingUserDetailsComponent);
export default OnboardingUserDetailsComponent;
