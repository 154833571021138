var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import Tooltip from '@/components/Tooltip.vue';
import DatePicker from 'vue2-datepicker';
import DeleteModal from './DeleteModal.vue';
import AddAdditionalModal from './AddAdditionalModal.vue';
let AccountManagementSubscriptionTab = class AccountManagementSubscriptionTab extends Vue {
    constructor() {
        super(...arguments);
        this.possible_status = [
            'active',
            'demo',
            'waiting',
            'cancelled',
            'expired',
            'selected',
            'suspended',
        ];
        this.additional_status = '';
        this.showDeleteModal = false;
        this.showAddModal = false;
        this.selectedAdditional = null;
        // @Watch('allowedUsersTota')
        // onAllowedUsersTotalChange(newValue: number, oldValue: number) {
        // }
    }
    statusFormatter(status) {
        const possible_status = {
            active: 'Ativo',
            demo: 'Demonstração',
            waiting: 'Aguardando Pagamento',
            cancelled: 'Cancelado',
            expired: 'Expirado',
            selected: 'Selecionado',
            suspended: 'Suspenso',
        };
        return possible_status[status];
    }
    categoryFormatter(category) {
        const possible_categories = {
            plan: 'Plano',
            app: 'App',
        };
        return possible_categories[category];
    }
    dateFormatter(date) {
        if (!date)
            return date;
        date = new Date(date);
        date.setUTCHours(date.getUTCHours() + 3);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }
    getStartDate(additional) {
        if (additional.expiration_date || additional.status != 'demo')
            return additional.expiration_date;
        return new Date().toLocaleString();
    }
    openConfirmationDeleteModal(additional) {
        this.selectedAdditional = additional;
        this.showDeleteModal = true;
    }
    async openAddAdditionalModal() {
        await this.listAdditionals();
        this.showAddModal = true;
    }
    onDeleteAdditional() {
        // @ts-ignore
        this.deleteAdditional(this.selectedAdditional.id);
    }
    editAdditional(additional) { }
    extendDemo(date) { }
    deleteAdditional(additional_id) { }
    listAdditionals() { }
    addAdditional(additional_id) { }
};
__decorate([
    Prop({ default: () => [{}] })
], AccountManagementSubscriptionTab.prototype, "additionals", void 0);
__decorate([
    Prop({ default: () => [{}] })
], AccountManagementSubscriptionTab.prototype, "availableAdditionals", void 0);
__decorate([
    Emit('editAdditional')
], AccountManagementSubscriptionTab.prototype, "editAdditional", null);
__decorate([
    Emit('extendDemo')
], AccountManagementSubscriptionTab.prototype, "extendDemo", null);
__decorate([
    Emit('deleteAdditional')
], AccountManagementSubscriptionTab.prototype, "deleteAdditional", null);
__decorate([
    Emit('listAdditionals')
], AccountManagementSubscriptionTab.prototype, "listAdditionals", null);
__decorate([
    Emit('addAdditional')
], AccountManagementSubscriptionTab.prototype, "addAdditional", null);
AccountManagementSubscriptionTab = __decorate([
    Component({
        name: 'AccountManagementSubscriptionTab',
        components: { Tooltip, DatePicker, DeleteModal, AddAdditionalModal },
    })
], AccountManagementSubscriptionTab);
export default AccountManagementSubscriptionTab;
