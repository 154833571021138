import { render, staticRenderFns } from "./AccountManagementUsersTab.vue?vue&type=template&id=523de885&scoped=true&"
import script from "./AccountManagementUsersTab.vue?vue&type=script&lang=ts&"
export * from "./AccountManagementUsersTab.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "523de885",
  null
  
)

export default component.exports