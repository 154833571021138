var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Modal from '@/components/Modal.vue';
import LoadingDots from '@/components/LoadingDots.vue';
import { Component, Prop, Vue, PropSync } from 'vue-property-decorator';
import Tooltip from 'src/components/Tooltip.vue';
let SentNotificationModal = class SentNotificationModal extends Vue {
    notificationProcessed(notification, type, source) {
        return this.has_log(notification, type) && notification.log[type][source];
    }
    formatDate(date) {
        const new_date = new Date(date);
        const day = new_date.getUTCDate();
        const month = new_date.getUTCMonth() + 1;
        const year = new_date.getUTCFullYear();
        return `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
    }
    get_message_sent(notification, type) {
        if (this.has_log(notification, type)) {
            return notification.log[type].message_sent;
        }
        else {
            return 'Não enviada';
        }
    }
    has_log(notification, type) {
        return notification.log && notification.log[type];
    }
    has_error(notification, type) {
        return (notification.log &&
            notification.log[type] &&
            notification.log[type].whatsapp_log.includes('Error'));
    }
    has_exceed_quota(notification, type) {
        return (notification.log &&
            notification.log[type] &&
            notification.log[type].whatsapp_log.includes('exceed monthly quota'));
    }
};
__decorate([
    Prop({ default: () => [] })
], SentNotificationModal.prototype, "notifications", void 0);
__decorate([
    PropSync('open', { default: false })
], SentNotificationModal.prototype, "showRecentEventsModal", void 0);
SentNotificationModal = __decorate([
    Component({
        components: { Tooltip, Modal, LoadingDots },
    })
], SentNotificationModal);
export default SentNotificationModal;
