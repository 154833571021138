var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import UsersList from '@/modules/admin/components/AccountManagementModal/AccountManagementUsersTab/UsersList.vue';
import { connect } from '@/overmind';
let UsersListContainer = class UsersListContainer extends Vue {
    created() {
        // @ts-ignore
        this.actions.getSubUsers(this.selectedUserTable.accountId);
    }
    destroyed() {
        // @ts-ignore
        this.actions.setUsersSearchTerm('');
    }
    async updateAllowedUsers(maxUsersCount) {
        // @ts-ignore
        await this.actions.updateAllowedUsers({
            // @ts-ignore
            accountId: this.selectedUserTable.accountId,
            maxUsersCount,
        });
    }
    async showUser(user) {
        // @ts-ignore
        await this.actions.selectUser(user);
        // @ts-ignore
        this.actions.getUserDetailConsumptions({
            // @ts-ignore
            accountId: this.currentAccount.id,
            // @ts-ignore
            userId: user.id,
        });
        // this.actions.selectUser(user)
        // @ts-ignore
        this.actions.setAccountModalRightDrawer('UsersDetail');
    }
};
UsersListContainer = __decorate([
    Component(connect(({ state: { admin: state }, actions: { admin: actions } }) => ({
        selectedUserTable: state.selectedUserTable,
        allowedUsers: state.allowedUsers,
        filteredUsers: state.filteredUsers,
        users: state.subUsers,
        currentAccount: state.currentAccount,
        actions: actions,
    }), {
        components: {
            UsersList,
        },
    }))
], UsersListContainer);
export default UsersListContainer;
