var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import AccountManagementMainContent from '@/modules/admin/components/AccountManagementModal/AccountManagementMainContent.vue';
import AccountManagementMainHeader from '@/modules/admin/components/AccountManagementModal/AccountManagementMainHeader.vue';
import { connect } from '@/overmind';
let AccountManagementMainContainer = class AccountManagementMainContainer extends Vue {
    closeModal() {
        this.$emit('closeModal');
        // @ts-ignore
        this.actions.selectUserDataTable(undefined);
    }
    headerSectionTitle() {
        // @ts-ignore
        switch (this.currentTab) {
            case 'credits':
                return 'Gerenciar Crédito';
            case 'credits-consumption':
                return 'Consumo da Conta';
            case 'users':
                return 'Usuários';
            case 'account-info':
                return 'Informações da Conta';
            case 'audits':
                return 'Auditoria';
            case 'healthscore':
                return 'Healthscore';
            case 'user-behavior':
                return 'Comportamento do Usuário';
            case 'user-notifications':
                return 'Notificações para o Usuário';
            case 'user-filters':
                return 'Filtros mais usados do Usuário';
            case 'user-subscriptions':
                return 'Assinaturas do usuário';
            default:
                return 'Gerenciar Crédito';
        }
    }
    closeRightDrawer() {
        // @ts-ignore
        this.actions.setAccountModalRightDrawer('');
    }
};
AccountManagementMainContainer = __decorate([
    Component(connect(({ state: { admin: state }, actions: { admin: actions } }) => ({
        currentTab: state.accountModal.currentTab,
        rightDrawer: state.accountModal.rightDrawer,
        actions: actions,
    }), {
        components: {
            AccountManagementMainHeader,
            AccountManagementMainContent,
        },
    }))
], AccountManagementMainContainer);
export default AccountManagementMainContainer;
