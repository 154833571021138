var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { json } from 'overmind';
import { date } from 'quasar';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
var TypeOption;
(function (TypeOption) {
    TypeOption["DAY"] = "day";
    TypeOption["MONTH"] = "month";
    TypeOption["YEAR"] = "year";
})(TypeOption || (TypeOption = {}));
let DateRangePicker = class DateRangePicker extends Vue {
    constructor() {
        super(...arguments);
        this.dateRangeLabel = this.rangeLabel || 'Hoje';
        this.dateRange = json(this.dateValues);
    }
    onChangeDateValues(newRange, oldRange) {
        this.dateRange = newRange;
    }
    changeDateRange(values) {
        const beginDateLabel = date.formatDate(values[0], 'DD-MM-YYYY');
        const endDateLabel = date.formatDate(values[1], 'DD-MM-YYYY');
        this.dateRangeLabel = `${beginDateLabel} - ${endDateLabel}`;
    }
    selectToday() {
        this.dateRangeLabel = 'Hoje';
        const begin = new Date();
        const end = new Date();
        end.setHours(23, 59, 59, 999);
        this.dateRange = [begin, end];
    }
    selectYesterday() {
        this.dateRangeLabel = 'Ontem';
        const begin = date.subtractFromDate(new Date(), { days: 1 });
        const end = date.subtractFromDate(new Date(), { days: 1 });
        begin.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 999);
        // yesterday.setTime(yesterday.getTime() - 3600 * 1000 * 24)
        this.dateRange = [begin, end];
    }
    selectLastDays(days) {
        this.dateRangeLabel = `Últimos ${days} dias`;
        let today = new Date();
        const begin = date.subtractFromDate(today, { days });
        begin.setHours(0, 0, 0, 0);
        const end = date.subtractFromDate(today, { days: 1 });
        end.setHours(23, 59, 59, 999);
        this.dateRange = [begin, end];
    }
    close() {
        this.$emit('cancel', true);
    }
    // TODO: fix the last day of the month and update the data range label
    // when group by has changed
    updateDate() {
        if (this.dateRangeLabel !== '') {
            this.parseEndDate();
            this.$emit('changeRange', this.dateRange, this.dateRangeLabel);
        }
        else {
            this.$emit('changeRange', this.dateRange, this.dateRangeLabel);
        }
    }
    parseEndDate() {
        const parsedEndDate = date.endOfDate(new Date(this.dateRange[1]), this.type);
        this.dateRange[1] = parsedEndDate;
        this.changeDateRange(this.dateRange);
    }
};
__decorate([
    Prop({ default: TypeOption.MONTH })
], DateRangePicker.prototype, "type", void 0);
__decorate([
    Prop({ default: 'Hoje' })
], DateRangePicker.prototype, "rangeLabel", void 0);
__decorate([
    Prop({ default: [new Date(), new Date()] })
], DateRangePicker.prototype, "dateValues", void 0);
__decorate([
    Watch('dateValues')
], DateRangePicker.prototype, "onChangeDateValues", null);
DateRangePicker = __decorate([
    Component
], DateRangePicker);
export default DateRangePicker;
