var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';
import AccountManagementModal from '@/modules/admin/components/AccountManagementModal/AccountManagementModal.vue';
import { AdminStatus } from '../../../../overmind/admin/state';
import { connect } from '@/overmind';
import { json } from 'overmind';
let AccountManagementModalContainer = class AccountManagementModalContainer extends Vue {
    async created() {
        this.initData();
    }
    getNewCreditsValid() {
        // @ts-ignore
        return json(this.newCreditsValid);
    }
    closeModal() {
        // @ts-ignore
        this.actions.setAccountModalRightDrawer('');
        // @ts-ignore
        this.actions.resetAccountInfo();
        this.$emit('closeModal');
    }
    closeRightDrawer() {
        // @ts-ignore
        this.actions.setAccountModalRightDrawer('');
    }
    async initData() {
        // @ts-ignore TS2349: property inexistent
        this.actions.setStatusModalCredits(AdminStatus.RECEIVING);
        // @ts-ignore TS2349: property inexistent
        await this.actions.getAccountCredits(this.selectedUserTable.accountId);
        // @ts-ignore TS2349: property inexistent
        await this.actions.getAccountInformation(this.selectedUserTable.accountId);
        // @ts-ignore TS2349: property inexistent
        this.actions.setStatusModalCredits(AdminStatus.RECEIVED);
        setTimeout(() => {
            // @ts-ignore TS2349: property inexistent
            this.actions.setStatusModalCredits(AdminStatus.IDLE);
        }, 2000);
    }
};
__decorate([
    PropSync('open', { default: false })
], AccountManagementModalContainer.prototype, "showModal", void 0);
__decorate([
    Prop({ default: () => { } })
], AccountManagementModalContainer.prototype, "user", void 0);
AccountManagementModalContainer = __decorate([
    Component(connect(({ state: { admin: state }, actions: { admin: actions } }) => ({
        actions: actions,
        statusModalCredits: state.statusModalCredits,
        newCreditsValid: state.newCreditsValid,
        validCredits: state.validCredits,
        selectedUserTable: state.selectedUserTable,
        rightDrawer: state.accountModal.rightDrawer,
    }), {
        name: 'AccountManagementModalContainer',
        components: {
            AccountManagementModal,
        },
    }))
], AccountManagementModalContainer);
export default AccountManagementModalContainer;
