var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import AccountManagementHeader from '@/modules/admin/components/AccountManagementModal/AccountManagementHeader.vue';
import { connect } from '@/overmind';
let AccountManagementNavbarHeaderContainer = class AccountManagementNavbarHeaderContainer extends Vue {
    getCredits() {
        // @ts-ignore
        return this.currentAccount ? this.currentAccount.credits : {};
    }
};
AccountManagementNavbarHeaderContainer = __decorate([
    Component(connect(({ state: { admin: state }, actions: { admin: actions } }) => ({
        actions: actions,
        statusModalCredits: state.statusModalCredits,
        newCreditsValid: state.newCreditsValid,
        currentAccount: state.currentAccount,
        credits: state.accountCredits,
    }), {
        name: 'AccountManagementNavbarHeaderContainer',
        components: {
            AccountManagementHeader,
        },
    }))
], AccountManagementNavbarHeaderContainer);
export default AccountManagementNavbarHeaderContainer;
