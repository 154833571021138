var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import AuditsListItem from '@/modules/admin/components/AccountManagementModal/AccountManagementAuditsTab/CreditsTab/AuditsListItem.vue';
import AuditsEmpty from '@/modules/admin/components/AccountManagementModal/AccountManagementAuditsTab/CreditsTab/AuditsEmpty.vue';
import AuditsSkeleton from '@/modules/admin/components/AccountManagementModal/AccountManagementAuditsTab/CreditsTab/AuditsSkeleton.vue';
import { AdminStatus, } from '@/overmind/admin/state';
let AuditsList = class AuditsList extends Vue {
    constructor() {
        super(...arguments);
        this.selectedAuditId = '';
        this.columns = [
            { type: 'author', label: 'Autor' },
            { type: 'period', label: 'Ocorrido em' },
            { type: 'expiration', label: 'Expira em' },
            { type: 'origin', label: 'Origem' },
            { type: 'type', label: 'Operação' },
            { type: 'status', label: 'Status' },
        ];
    }
    getColumns() {
        if (this.filters.origin === 'admin') {
            return this.columns.filter(c => c.type !== 'origin' && c.type !== 'status');
        }
        return this.columns;
    }
    showAudit(audit) {
        this.selectedAuditId = audit.id;
        this.$emit('showAudit', audit);
    }
};
__decorate([
    Prop({ default: AdminStatus.IDLE })
], AuditsList.prototype, "status", void 0);
__decorate([
    Prop({ default: () => { } })
], AuditsList.prototype, "audits", void 0);
__decorate([
    Prop({ default: () => { } })
], AuditsList.prototype, "filters", void 0);
AuditsList = __decorate([
    Component({
        components: {
            AuditsListItem,
            AuditsEmpty,
            AuditsSkeleton,
        },
    })
], AuditsList);
export default AuditsList;
