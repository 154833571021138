var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import { connect } from '@/overmind';
import InfosetsTableTab from './InfosetsTableTab.vue';
import OnlineDaysTableTab from './OnlineDaysTableTab.vue';
import EventHistoryTableTab from './EventHistoryTableTab.vue';
import FiltersUsedOnSearchTableTab from './FiltersUsedOnSearchTableTab.vue';
import NpsScoreTableTab from './NpsScoreTableTab.vue';
import AppsOwnedTableTab from './AppsOwnedTableTab.vue';
let AccountManagementHealthscoreTab = class AccountManagementHealthscoreTab extends Vue {
    constructor() {
        super(...arguments);
        this.filterShowBy = 'infosets';
        this.toggleOptions = [
            { value: 'infosets', slot: 'infosets' },
            { value: 'events-history', slot: 'events-history' },
            { value: 'online-days', slot: 'online-days' },
            {
                value: 'filters-used-in-each-search',
                slot: 'filters-used-in-each-search',
            },
            { value: 'nps-scores', slot: 'nps-scores' },
            { value: 'apps-owned', slot: 'apps-owned' },
        ];
    }
    formatOnboardingStatus(onboarding_status) {
        if (!onboarding_status)
            return 'Não fez';
        const status = {
            already_done: 'Já fez',
            scheduled: 'Marcado',
            refused: 'Recusado',
        };
        return status[onboarding_status.user_selected_option];
    }
    get healthscore() {
        if (this.basicMetricInfos && this.basicMetricInfos.healthscore) {
            const hs = this.basicMetricInfos.healthscore;
            return `${hs['category']} (${hs['score']})`;
        }
        else {
            return '';
        }
    }
};
__decorate([
    Prop({
        default: () => ({
            id: 0,
            name: '',
            email_validation: '',
            phone_validation: '',
        }),
    })
], AccountManagementHealthscoreTab.prototype, "infosets", void 0);
__decorate([
    Prop({
        default: () => ({
            infosets_created_last_thirty_days: 0,
            used_exportation_credits: 0,
            used_click_credits: 0,
            healthscore: { score: 0, category: '' },
            cs: '',
            onboarding: { user_selected_option: '' },
        }),
    })
], AccountManagementHealthscoreTab.prototype, "basicMetricInfos", void 0);
__decorate([
    Prop({ default: () => ({ metrics: [{}], code: 200 }) })
], AccountManagementHealthscoreTab.prototype, "mixpanelEvents", void 0);
__decorate([
    Prop({ default: () => ({ online_days: [''], code: 200 }) })
], AccountManagementHealthscoreTab.prototype, "onlineDays", void 0);
__decorate([
    Prop({ default: () => ({ searches: [{}], code: 200 }) })
], AccountManagementHealthscoreTab.prototype, "clientSearches", void 0);
__decorate([
    Prop({ default: () => ({ nps_scores: [{}], code: 200 }) })
], AccountManagementHealthscoreTab.prototype, "clientNpsScores", void 0);
__decorate([
    Prop({ default: () => ({ apps: [{}], code: 200 }) })
], AccountManagementHealthscoreTab.prototype, "appsOwned", void 0);
AccountManagementHealthscoreTab = __decorate([
    Component(connect(({ state: { admin: state } }) => ({
        state: state,
    }), {
        name: 'AccountManagementHealthscoreTab',
        components: {
            InfosetsTableTab,
            OnlineDaysTableTab,
            EventHistoryTableTab,
            FiltersUsedOnSearchTableTab,
            NpsScoreTableTab,
            AppsOwnedTableTab,
        },
    }))
], AccountManagementHealthscoreTab);
export default AccountManagementHealthscoreTab;
