var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';
import Input from '@/components/Input.vue';
import { json } from 'overmind';
import { formatDate, formatDateToBR } from '@/shared/utils/helpers';
let UsersUpdateCreditsTabRow = class UsersUpdateCreditsTabRow extends Vue {
    constructor() {
        super(...arguments);
        this.expireDate = json(formatDateToBR(this.currentCredits.expiresDate));
        this.modalBasicCredits = json(this.currentCredits.simpleData);
        this.modalInternalCredits = json(this.currentCredits.internalExport);
    }
    updateCredits() {
        if (this.modalBasicCredits || this.modalInternalCredits) {
            this.$emit('updateCredits', this.currentCredits.id, this.buildCreditsData());
            this.showUpdateCreditsModal = false;
        }
    }
    buildCreditsData() {
        return {
            search_credit: !this.modalBasicCredits ? 0 : this.modalBasicCredits,
            internal_export_credit: !this.modalInternalCredits
                ? 0
                : this.modalInternalCredits,
            expires_date: formatDate(this.expireDate),
        };
    }
};
__decorate([
    Prop({ default: () => { } })
], UsersUpdateCreditsTabRow.prototype, "currentCredits", void 0);
__decorate([
    Prop({ default: false })
], UsersUpdateCreditsTabRow.prototype, "noFooter", void 0);
__decorate([
    PropSync('open', { default: false })
], UsersUpdateCreditsTabRow.prototype, "showUpdateCreditsModal", void 0);
UsersUpdateCreditsTabRow = __decorate([
    Component({
        components: {
            Modal,
            Input,
        },
    })
], UsersUpdateCreditsTabRow);
export default UsersUpdateCreditsTabRow;
